//import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import axios from 'axios'

import VueLazyload from 'vue-lazyload'
import VueContentPlaceholders from 'vue-content-placeholders'
import VueMeta from 'vue-meta'
import VueInfiniteScroll from 'vue-infinite-scroll'
import Toasted from 'vue-toasted'

Vue.use(VueLazyload)
Vue.use(VueContentPlaceholders)
Vue.use(VueMeta)
Vue.use(VueInfiniteScroll)
Vue.use(Toasted)

import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.config.productionTip = false

// Add interceptor before any Vue components are created
// to allow router to have access to sessionToken
axios.interceptors.request.use(config => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const sessionToken = store.getters['auth/sessionToken']
  if (config.requiresAuth && isAuthenticated) {
    config.headers.Authorization = `Bearer ${sessionToken}`
  }
  return config
})

const app = new Vue({
  el: '#app',
  router,
  store,
  directives: { VueInfiniteScroll },
  render: h => h(App)
})