import axios from 'axios'

const actions = {
  queryMaerkte: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/maerkte`,
        method: 'GET'
      }).then(resp => { resolve(resp.data.maerkte) }).catch(reject)
    })
  },
  queryMaerkteRecent: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/maerkte/recent`,
        params: {v: rootState.packageVersion},
        method: 'GET'
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  },
  updateNeuigkeiten: ({ commit, dispatch, rootState }, text) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/maerkte/neuigkeiten`,
        data: { neuigkeitenText: text },
        method: 'POST',
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  },
  subscribeNewsletter: ({ commit, dispatch, rootState }, email) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/newsletter/subscribe`,
        data: { email },
        method: 'POST',
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  actions
}
