export default {
  methods: {
    getBestellungStatus (bestellung) {

    },
    getBestellungGesamtwert (bestellung) {
      return bestellung.positionen.reduce((gesamtwert, position) => {
        return gesamtwert + position.quantityRequested * position.sku.preis.preis
      }, 0) + bestellung.versandkosten
    }
  }
}
