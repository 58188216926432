/* eslint-disable no-console */

import { register } from 'register-service-worker'

import Vue from 'vue'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
      Vue.toasted.show('Die Internetseite wurde seit Ihrem letzten Besuch aktualisiert.', {
        position: 'bottom-center',
        fullWidth: true,
        className: 'body-2',
        action : {
            text : 'Jetzt neu laden',
            onClick : (e, toastObject) => {
                toastObject.goAway(0);
                window.location.reload(true)
            }
        },        
        closeOnSwipe: false,
      });      
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
