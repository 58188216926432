import axios from 'axios'

const state = {
  profile: {
    name: null,
    address: {
      invoice: null,
      shipping: null
    }
  },
  instagramUser: null,
  // JWT token generated by API to identify user after external redirect
  // Only valid for connecting Instagram account to ML user account
  instagramConnectToken: null,
  bestellungen: [],
  loaded: false,
  addressCountries: [
    'Deutschland',
    'Österreich'
  ]
}

const getters = {
  profile: (state, getters, rootState, rootGetters) => ({
    ...state.profile,
    email: rootGetters['auth/accountEmail']
  }),
  instagramUser: state => state.instagramUser,
  instagramConnectUrl: (state, getters, rootState) => {
    if (state.instagramConnectToken) {
      const url = rootState.instagramConnectUrl
      const queryParam = `instagram_connect_token=${state.instagramConnectToken}`
      return `${url}?${queryParam}`
    } else {
      return null
    }
  },
  addressCountries: state => state.addressCountries
}

const mutations = {
  updateProfile (state, profile) {
    state.profile = profile
  },
  updateInstagramUser (state, instagramUser) {
    state.instagramUser = instagramUser
  },
  updateInstagramConnectToken (state, token) {
    state.instagramConnectToken = token
  },
  setLoaded (state, bool) {
    state.loaded = bool
  }
}

const actions = {
  loadUser: ({ state, commit, dispatch, rootState, rootGetters }, { forceReload = false } = {}) => {
    if (state.loaded && !forceReload) {
      return Promise.resolve(true)
    } else if (!rootGetters['auth/isAuthenticated']) {
      // Reject only on API errors
      return Promise.resolve(false)
    }

    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        commit('updateProfile', resp.data.user.profile)
        commit('updateInstagramUser', resp.data.user.instagramUser)
        commit('updateInstagramConnectToken', resp.data.user.instagramConnectToken)
        commit('setLoaded', true)
        resolve(true)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  },
  // Reset user (will stay logged in)
  // Use auth/resetAccount for logout and user reset
  unloadUser: ({ state, commit, dispatch, rootState }) => {
    commit('updateProfile', {
      name: null,
      address: {
        invoice: null,
        shipping: null
      }
    })
    commit('updateInstagramUser', null)
    commit('updateInstagramConnectToken', null)
    commit('setLoaded', false)
    dispatch('contact/resetForm', null, { root: true })
    dispatch('cart/resetOrderAddress', null, { root: true })
  },
  // Konto updates
  updateName: ({ state, commit, dispatch, rootState }, { name }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/name`,
        method: 'PUT',
        data: { name },
        requiresAuth: true
      }).then(() => dispatch('loadUser', { forceReload: true })).then(resolve).catch(reject)
    })
  },
  updateEmail: ({ state, commit, dispatch, rootState }, { email }) => {
    return new Promise((resolve, reject) => {
      // LoadUser after email change to test new sessionToken
      axios({
        url: `${rootState.apiUrl}/user/email`,
        method: 'PUT',
        data: { email },
        requiresAuth: true
      }).then(resp => {
        dispatch('auth/updateCredentials', {
          sessionToken: resp.data.sessionToken,
          accountEmail: resp.data.email
        }, { root: true })
      }).then(() => dispatch('loadUser', { forceReload: true })).then(resolve).catch(reject)
    })
  },
  updatePassword: ({ state, commit, dispatch, rootState }, { oldPassword, newPassword }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/password`,
        method: 'PUT',
        data: { oldPassword, newPassword },
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  },
  deleteAccount: ({ state, commit, dispatch, rootState }, { reason }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user`,
        method: 'DELETE',
        data: { reason },
        requiresAuth: true
      }).then(() => {
        // Synchronous, return undefined
        dispatch('auth/resetAccount', { accountDeleted: true }, { root: true })
      }).then(resolve).catch(reject)
    })
  },
  updateAddressInvoice: ({ state, commit, dispatch, rootState }, address) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/address/invoice`,
        method: 'PUT',
        data: address,
        requiresAuth: true
      }).then(() => {
        // Return promise
        return dispatch('loadUser', { forceReload: true })
      }).then(() => {
        dispatch('cart/resetOrderAddress', null, { root: true })
      }).then(resolve).catch(reject)
    })
  },
  updateAddressShipping: ({ state, commit, dispatch, rootState }, address) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/address/shipping`,
        method: 'PUT',
        data: address,
        requiresAuth: true
      }).then(() => {
        // Return promise
        return dispatch('loadUser', { forceReload: true })
      }).then(() => {
        dispatch('cart/resetOrderAddress', null, { root: true })
      }).then(resolve).catch(reject)
    })
  },
  deleteAddressInvoice: ({ state, commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/address/invoice`,
        method: 'DELETE',
        requiresAuth: true
      }).then(() => {
        // Return promise
        return dispatch('loadUser', { forceReload: true })
      }).then(() => {
        dispatch('cart/resetOrderAddress', null, { root: true })
      }).then(resolve).catch(reject)
    })
  },
  deleteAddressShipping: ({ state, commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/user/address/shipping`,
        method: 'DELETE',
        requiresAuth: true
      }).then(() => {
        // Return promise
        return dispatch('loadUser', { forceReload: true })
      }).then(() => {
        dispatch('cart/resetOrderAddress', null, { root: true })
      }).then(resolve).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
