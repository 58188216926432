import Vue from 'vue'
import axios from 'axios'

const state = {
  produkte: {},
  produkteKatalog: null,
  // ProductsKatalog component
  katalogProduktgruppenAll: true,
  katalogProdukteSearch: null,
  katalogProduktgruppenSelectedUser: [],
  katalogMaxResults: null
}

const getters = {
  produkteKatalog: state => state.produkteKatalog,
  katalogProduktgruppenAll: state => state.katalogProduktgruppenAll,
  katalogProdukteSearch: state => state.katalogProdukteSearch,
  katalogProduktgruppenSelectedUser: state => state.katalogProduktgruppenSelectedUser,
  katalogMaxResults: state => state.katalogMaxResults,
  produkttypen: (state, getters, rootState, rootGetters) => {
    return rootGetters['admin/products/produkttypen']
  },
  produkttypFriendly: (state, getters) => value => {
    if (value) {
      const produkttyp = getters['produkttypen'].find(produkttyp => produkttyp.value === value)
      return produkttyp
        ? produkttyp.text
        : value.charAt(0).toUpperCase() + value.slice(1)
    }
    return 'Allgemein'
  }
}

const mutations = {
  setProdukt (state, produkt) {
    // Use slug as key as it is most commonly used to lookup produkt
    // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
    Vue.set(state.produkte, produkt.slug, {
      item: produkt,
      retrievedDate: new Date()
    })
  },
  updateProdukteKatalog (state, produkteKatalog) {
    state.produkteKatalog = produkteKatalog
  },
  updateKatalogProduktgruppenAll (state, katalogProduktgruppenAll) {
    state.katalogProduktgruppenAll = katalogProduktgruppenAll
  },
  updateKatalogProdukteSearch (state, katalogProdukteSearch) {
    state.katalogProdukteSearch = katalogProdukteSearch
  },
  updateKatalogProduktgruppenSelectedUser (state, katalogProduktgruppenSelectedUser) {
    state.katalogProduktgruppenSelectedUser = katalogProduktgruppenSelectedUser
  },
  updateKatalogMaxResults (state, katalogMaxResults) {
    state.katalogMaxResults = katalogMaxResults
  }
}

const actions = {
  // Fully populated
  findProdukt: ({ state, commit, dispatch, rootState }, { _id, slug, lfdNr, faid, oldwebSlug, forceReload = false }) => {
    // Only use cache when findProdukt by slug
    // lfdNr used with QRCode on first load, faid and _id by staff only
    if (!forceReload && slug && (slug in state.produkte)) {
      // console.log(`produkte has slug ${slug}`)
      var produkt = state.produkte[slug]
      // FIXME -> side effects?
      if (produkt.retrievedDate >= new Date(Date.now() - rootState.apiMaxAge * 1000)) {
        //console.log(`cache hit ${slug}`)
        return Promise.resolve(produkt.item)
      }
    }
    return new Promise((resolve, reject) => {
      if (!_id && !slug && !faid && !lfdNr && !oldwebSlug) {
        reject(new Error('No id provided'))
      }
      const apiCall = _id
        ? axios({
          url: `${rootState.apiUrl}/produkte/${_id}`,
          method: 'GET'
        })
        : axios({
          url: `${rootState.apiUrl}/produkte`,
          params: JSON.parse(JSON.stringify({ slug, lfdNr, faid, oldweb_slug: oldwebSlug })),
          method: 'GET'
        })
      apiCall.then(resp => {
        // Always update cache
        commit('setProdukt', resp.data.produkt)
        resolve(resp.data.produkt)
      }).catch(reject)
    })
  },
  // Fully populated
  queryProdukteEmpfehlung: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/empfehlung`,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data.produkte)
      }).catch(reject)
    })
  },
  // Only partial projection
  loadProdukte: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/v2`,
        method: 'GET'
      }).then(resp => {
        commit('updateProdukteKatalog', resp.data.produkte)
        resolve()
      }).catch(reject)
    })
  },
  queryProduktSlots: ({ commit, dispatch, rootState }, produktId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/${produktId}/slots`,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data.slots)
      }).catch(reject)
    })
  },
  // Only partial projection
  searchProdukte: ({ state, commit, dispatch, rootState }, term) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte`,
        params: { search: term },
        method: 'GET'
      }).then(resp => { resolve(resp.data.produkte) }).catch(reject)
    })
  },
  resetKatalogInputs: ({ commit }) => {
    commit('updateKatalogProduktgruppenAll', true)
    commit('updateKatalogProdukteSearch', null)
    commit('updateKatalogProduktgruppenSelectedUser', [])
    commit('updateKatalogMaxResults', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
