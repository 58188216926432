<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    fixed
    app
    clipped
    color="grey lighten-5"
    touchless>
    <v-layout column>
      <v-list dense expand>
        <template v-if="isAuthenticated">
          <v-list-group v-model="navMeinLaedchenModel">
            <v-list-tile slot="activator" role="button">
              <v-list-tile-content>
                <v-subheader class="font-weight-bold px-0">Mein Lädchen</v-subheader>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile
              v-for="item in navMenu.itemsAccount"
              :key="item.to || item.href"
              :to="item.to" :href="item.href">
              <v-list-tile-action>
                <v-avatar v-if="item.actionAvatar" size="28" tile>
                  <img :src="require(`@/assets/${item.actionAvatar}`)">
                </v-avatar>
                <v-icon v-else-if="item.actionIcon"
                  v-bind="item.actionIconAttrs || {}">{{ item.actionIcon }}</v-icon>
                <span v-else-if="item.actionText" :class="item.actionTextClass">
                  {{ item.actionText }}
                </span>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile-content>            
            </v-list-tile>
          </v-list-group>
        </template>
        <template v-else>
          <v-subheader class="font-weight-bold">Mein Lädchen</v-subheader>          
          <v-list-tile to="/anmelden">
            <v-btn small color="transparent" disabled flat><span class="primary--text">Anmelden</span></v-btn>
          </v-list-tile>
        </template>

        <v-list-group v-model="navSeitenModel">
          <v-list-tile slot="activator" role="button">
            <v-list-tile-content>
              <v-subheader class="font-weight-bold px-0">Seiten</v-subheader>
            </v-list-tile-content>
          </v-list-tile>         
          <v-list-tile
            v-for="item in navMenu.itemsSeiten"
            :key="item.to || item.href"
            :to="item.to" :href="item.href">
            <v-list-tile-action>
              <v-avatar v-if="item.actionAvatar" size="28" tile>
                <img :src="require(`@/assets/${item.actionAvatar}`)">
              </v-avatar>
              <v-icon v-else-if="item.actionIcon" 
                v-bind="item.actionIconAttrs || {}">{{ item.actionIcon }}</v-icon>              
              <span v-else-if="item.actionText" :class="item.actionTextClass">
                {{ item.actionText }}
              </span>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              <v-list-tile-sub-title v-if="item.textSubtitle">{{ item.subTitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile
            v-for="item in navMenu.itemsSeitenMore"
            :key="item.to || item.href"
            :to="item.to" :href="item.href">
            <v-list-tile-action>
              <v-avatar v-if="item.actionAvatar" size="28" tile>
                <img :src="require(`@/assets/${item.actionAvatar}`)">
              </v-avatar>
              <v-icon v-else-if="item.actionIcon" 
                v-bind="item.actionIconAttrs || {}">{{ item.actionIcon }}</v-icon>              
              <span v-else-if="item.actionText" :class="item.actionTextClass">
                {{ item.actionText }}
              </span>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              <v-list-tile-sub-title v-if="item.textSubtitle">{{ item.textSubtitle }}</v-list-tile-sub-title>              
            </v-list-tile-content>
          </v-list-tile>          
        </v-list-group>

        <template v-if="isAdmin">
          <v-list-tile>
            <v-list-tile-content>
              <v-subheader class="font-weight-bold px-0">Etiketten</v-subheader>
            </v-list-tile-content>
            <v-chip color="info" text-color="white" small class="v-chip--x-small pa-0">admin</v-chip>
          </v-list-tile>         
          <v-list-tile to="/etiketten" exact>
            <v-list-tile-action>
              <v-icon color="grey darken-3">print</v-icon>
            </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title>Druckaufträge</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile to="/naehrwerte/rezeptur">
            <v-list-tile-action>
              <v-icon color="grey darken-3">menu_book</v-icon>
            </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title>Rezepturen</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile>
            <v-list-tile-content>
              <v-subheader class="font-weight-bold px-0">Produkte</v-subheader>
            </v-list-tile-content>
            <v-chip color="info" text-color="white" small class="v-chip--x-small pa-0">admin</v-chip>
          </v-list-tile>         
          <v-list-tile to="/katalog/verfuegbarkeit">
            <v-list-tile-action>
              <v-icon color="grey darken-3">fa-cubes</v-icon>
            </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title>Verfügbarkeit</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile to="/katalog/anlegen">
            <v-list-tile-action>
              <v-icon color="grey darken-3">add_box</v-icon>
            </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title>Produkt anlegen</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile to="/kategorien">
            <v-list-tile-action>
              <v-icon color="grey darken-3">category</v-icon>
            </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title>Kategorien</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('auth', ['isAuthenticated', 'isAdmin']),
      ...mapGetters('navigation', {
        navMenu: 'menu'
      }),
      ...mapGetters('background', {
        backgroundSelection: 'currentSelection',
        backgroundOptions: 'allOptions'
      }),
      navMeinLaedchenModel: {
        get () {
          return this.navMeinLaedchen
        },
        set (bool) {
          this.setNavMeinLaedchen(bool)
        }
      },
      navSeitenModel: {
        get () {
          return this.navSeiten
        },
        set (bool) {
          this.setNavSeiten(bool)
        }
      },
      ...mapGetters('ui', ['navMeinLaedchen', 'navSeiten'])
    },
    methods: {
      ...mapActions('auth', ['resetAccount']),
      ...mapMutations('background', ['changeBackground']),
      ...mapMutations('ui', ['setNavMeinLaedchen', 'setNavSeiten'])
    }
  }
</script>
