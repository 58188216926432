export default {
  methods: {
    formatAddress (address) {
      if (address) {
        var addressLines = []
        addressLines.push(address.fullname)
        if (address.company) {
          addressLines.push(address.company)
        }
        addressLines.push(address.addressLine1)
        if (address.addressLine2) {
          addressLines.push(address.addressLine2)
        }
        addressLines.push(`${address.zipcode} ${address.city}`)
        addressLines.push(address.country)
        return addressLines.join('\n')
      }
    }
  }
}
