<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>      
      <v-card>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
        <v-card-title primary-title>
          <h3 class="title">Telefon</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="subheading">Wir sind telefonisch zu erreichen:</div>
          <div class="text-xs-center my-3">
            <v-divider></v-divider>
            <div class="py-3">
              <div class="subheading font-weight-bold">Olga Mesterheide und Ralf Schulte</div>
              <div class="my-2"><a class="display-1" href="tel:+495742700687">05742 700687</a></div>
              <div class="grey--text">Preußisch Oldendorf / Getmold</div>
            </div>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        </v-container>        
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Telefon'
    }
  }
</script>