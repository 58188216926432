import * as moment from 'moment'
moment.locale('de')

export default {
  methods: {
    formatDays (daysSignedInt) {
      // Cannot use moment.fromNow because days_start and days_end is not accurate enough
      switch (daysSignedInt) {
        case -1: return 'gestern'
        case 0: return 'heute'
        case 1: return 'morgen'
        default: return `${daysSignedInt < 0 ? 'vor' : 'in'} ${Math.abs(daysSignedInt)} Tagen`
      }
    },
    formatDatespan ($t0, $t1) {
      var m0 = moment($t0)
      var m1 = moment($t1)
      if (m0.isSame(m1, 'day')) {
        return m0.format('D. MMM YYYY')
      } else if (m0.isSame(m1, 'month')) {
        return `${m0.format('D.')} - ${m1.format('D. MMM YYYY')}`
      } else if (m0.isSame(m1, 'year')) {
        return `${m0.format('D. MMM')} - ${m1.format('D. MMM YYYY')}`
      } else {
        return `${m0.format('D. MMM YYYY')} - ${m1.format('D. MMM YYYY')}`
      }
    },
    formatDayspan ($t0, $t1) {
      var m0 = moment($t0)
      var m1 = moment($t1)
      if (m0.isSame(m1, 'day')) {
        return m0.format('dddd')
      } else {
        return `${m0.format('dd')} bis ${m1.format('dd')}`
      }
    }
  }
}
