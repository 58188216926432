<template>
  <v-layout wrap style="margin: -8px">
    <v-flex class="pa-2" xs6>
      <v-card :flat="flat" class="text-xs-center fill-height">
        <v-layout wrap align-center>
          <v-flex xs12 sm4>
            <v-card-text>
              <v-avatar size="100" :class="{'mb-3': $vuetify.breakpoint.smAndUp}">
                <v-img src="/img/marktbude_200x200.jpg" contain></v-img>
                <v-btn v-if="$vuetify.breakpoint.mdAndUp"
                  href="https://www.facebook.com/marmelaedchen"
                  small fab absolute bottom
                  class="ml-fab-social-media">
                  <v-icon color="indigo">fab fa-facebook</v-icon>
                </v-btn>
                <v-menu offset-y v-else>
                  <v-btn
                    slot="activator"
                    small fab absolute bottom
                    class="ml-fab-social-media">
                    <v-icon color="indigo">fab fa-facebook</v-icon>
                  </v-btn>
                  <v-list dense>
                    <v-list-tile href="fb://page/164060563608644?referrer=app_link">
                      <v-list-tile-title>Android App</v-list-tile-title>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile href="fb://page/?id=164060563608644">
                      <v-list-tile-title>iOS App</v-list-tile-title>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile href="https://m.facebook.com/marmelaedchen">
                      <v-list-tile-content>
                        <v-list-tile-title>Mobile Webseite</v-list-tile-title>
                        <v-list-tile-sub-title>Browser</v-list-tile-sub-title>
                      </v-list-tile-content>                        
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-avatar>
            </v-card-text>
          </v-flex>
          <v-flex xs12 sm8>
            <v-card-text>
              <div class="font-weight-bold">Facebook</div>
              <div class="pt-1">Direkter Draht zur Chefin <v-icon small>far fa-smile-wink</v-icon></div>                
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex class="pa-2" xs6>
      <v-card :flat="flat" class="text-xs-center fill-height">
        <v-layout wrap align-center>
          <v-flex xs12 sm4>
            <v-card-text>
              <v-avatar size="100" :class="{'mb-3': $vuetify.breakpoint.smAndUp}">
                <v-img src="/img/rosenbusch_200x200.jpg" contain></v-img>
                <v-btn
                  href="https://www.instagram.com/marmelaedchen_getmold"
                  small fab absolute bottom
                  class="ml-fab-social-media">
                  <v-icon size="20" color="purple">fab fa-instagram</v-icon>
                </v-btn>                  
              </v-avatar>
            </v-card-text>
          </v-flex>
          <v-flex xs12 sm8>
            <v-card-text>
              <div class="font-weight-bold">Instagram</div>
              <div class="pt-1">Familien-Account mit Bildern aus dem Marmelädchen</div>                
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>    
  </v-layout>
</template>

<script>
  export default {
    props: {
      flat: { type: Boolean, default: false }
    }
  }
</script>

<style>
  .ml-fab-social-media {
    left: 50%;
    transform: translateX(-50%);
  }
</style>