<template>
  <v-container fill-height>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-toolbar dense flat>
            <v-toolbar-title>{{ register ? 'Registrierung' : 'Anmeldung' }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <template v-if="isAuthenticated">
            <v-card-text class="headline text-xs-center">
              <p><v-avatar tile size="150px"><img src="/img/schild_300x300.gif"></v-avatar></p>              
              <p v-if="register" v-once>Herzlich willkommen!</p>
              <p v-else>Willkommen zurück!</p>
              <v-layout justify-center>
                <v-btn v-if="$route.query.redirect"
                  :to="$route.query.redirect"
                  color="primary">Weiter</v-btn>
                <v-btn v-else
                  to="/"
                  color="primary">Zur Startseite</v-btn>
              </v-layout>
            </v-card-text>
          </template>
          <template v-else-if="waitingForLoginLink && isLoginLinkPending">
            <v-card-text class="subheading text-xs-center">
              <p v-once>Wir haben Ihnen einen Anmeldelink an <strong>{{ accountEmail }}</strong> geschickt.</p>
              <p>Bitte überprüfen Sie Ihr E-Mail-Postfach!</p>
            </v-card-text>
            <v-divider></v-divider>            
            <v-card-actions>
              <v-btn flat small @click.native="switchToLogin({useLoginLink : false})">Anmeldung mit Passwort</v-btn>
            </v-card-actions>            
          </template>
          <template v-else-if="register">
            <v-card-text>
              <span class="subheading">Neues Konto erstellen:</span>
              <v-form @submit.prevent="registerAccount" ref="formRegister" class="pt-2">
                <v-text-field
                  v-model.trim="form.email"
                  :rules="form.emailRules"
                  :error-messages="registerAttemptFailed ? 'Konto zu dieser E-Mail existiert bereits' : []"                  
                  required
                  validate-on-blur                  
                  autofocus
                  clearable
                  type="email"                  
                  label="E-Mail"
                  name="email"
                  autocomplete="email"
                ></v-text-field>
                <v-text-field
                  v-model.trim="form.name"
                  label="Name"
                  name="name"
                  autocomplete="name"
                ></v-text-field>
                <v-layout justify-center>
                  <v-btn 
                    type="submit"
                    :loading="loading" :disabled="loading" 
                    color="primary">Registrieren</v-btn>
                </v-layout>
              </v-form>              
            </v-card-text>   
            <v-divider></v-divider>
            <v-card-text class="caption">
              <v-layout justify-center>
                <v-icon class="mr-2">info</v-icon>
                <div style="align-self: center">
                  Wir geben Ihre Daten nicht weiter und senden Ihnen keine unaufgeforderten Werbemails.
                  Weitere Informationen in unserer
                  <a href="#" @click.prevent="showPrivacyPolicy = true">Datenschutzerklärung</a>.
                </div>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>            
            <v-card-actions>
              <v-btn flat small @click.native="switchToLogin">Zurück zur Anmeldung</v-btn>
            </v-card-actions>            
          </template>
          <template v-else-if="!register && !hasAccount">
            <v-card-text>
              <span class="subheading">Zur Kontoanmeldung bitte E-Mail eingeben:</span>
              <v-form @submit.prevent="probeEmail" ref="formProbe" lazy-validation class="pt-2">
                <v-text-field
                  v-model.trim="form.email"
                  :rules="form.emailRules"
                  :error-messages="probeAttemptFailed ? 'Kein Konto zu dieser E-Mail' : []"
                  required
                  validate-on-blur
                  autofocus
                  clearable
                  type="email"  
                  label="E-Mail"
                  name="email"
                  autocomplete="email"
                ></v-text-field>
                <v-layout justify-center>
                  <v-btn v-if="!probeAttemptFailed"
                    type="submit" key="probeEmail"
                    :loading="loading" :disabled="loading"                   
                    color="primary">Weiter</v-btn>
                  <v-btn v-else key="switchToRegister"
                    @click.native="switchToRegister">Zur Registrierung</v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>         
            <v-card-actions>
              <v-btn flat small @click.native="switchToRegister">Direkt zur Registrierung</v-btn>
            </v-card-actions>            
          </template>
          <template v-else-if="!register && hasAccount">
            <v-card-text>
              <v-form 
                @submit.prevent="form.useLoginLink ? requestLoginLink() : loginWithPassword()"
                ref="formLogin">
                <v-switch
                  v-model="form.useLoginLink"
                  label="Passwortlose Anmeldung"
                ></v-switch>                
                <v-text-field
                  :value="accountEmail"
                  :rules="form.emailRules"
                  @focus.stop="$event.target.blur()"
                  readonly
                  required
                  append-icon="clear"
                  @click:append="resetAccount"
                  type="email"                  
                  label="E-Mail"
                ></v-text-field>
                <v-text-field v-if="!form.useLoginLink"
                  v-model.trim="form.password"
                  :rules="form.passwordRules"
                  :error-messages="loginAttemptFailed ? 'Fehler bei der Anmeldung' : []"
                  required
                  autofocus
                  type="password"
                  label="Passwort"
                  name="password"
                  autocomplete="current-password"
                ></v-text-field>
                <v-layout justify-center>
                  <v-btn 
                    type="submit"
                    :loading="loading" :disabled="loading"
                    color="primary">
                      <span v-if="form.useLoginLink"><v-icon left>email</v-icon>Anmeldelink schicken</span>
                      <span v-else>Anmelden</span>
                  </v-btn>
                </v-layout>
              </v-form>              
            </v-card-text>
            <template v-if="!form.useLoginLink">
              <v-divider></v-divider>
              <v-card-text class="caption">
                <v-layout justify-center>
                  <v-icon class="mr-2">info</v-icon>
                  <div style="align-self: center">
                    Wir haben Ihnen ein initiales Passwort bei der Registrierung zugeschickt.
                  </div>
                </v-layout>
              </v-card-text>
            </template>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn flat small @click.native="resetAccount">E-Mail zurücksetzten</v-btn>
            </v-card-actions>            
          </template>            
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showPrivacyPolicy" lazy scrollable>
      <v-card>
        <v-card-title primary-title>
          <h3>Datenschutzerklärung</h3>
        </v-card-title>        
        <v-card-text
          class="pt-0" style="word-wrap: break-word"
          v-html="privacyPolicy"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showPrivacyPolicy = false">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <ml-snackbar v-model="snackbar">{{ snackbarMessage }}</ml-snackbar>    
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import cookies from 'browser-cookies'

  import ErrorSnackbar from '@/components/main/ErrorSnackbar'

  import store from '@/store'

  export default {
    data: () => ({
      register: false,
      loading: false,
      waitingForLoginLink: false,
      snackbar: false,
      snackbarMessage: '',
      showPrivacyPolicy: false,
      probeAttemptFailed: false,
      registerAttemptFailed: false,
      loginAttemptFailed: false,
      form: {
        email: '',
        emailRules: [
          v => !!v || 'Keine E-Mail angegeben',          
          v => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-Mail ungültig',
          // eslint-disable-next-line
          v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-Mail ungültig'
        ],
        // Register
        name: '',
        // Login
        useLoginLink: true,
        password: '',
        passwordRules: [
          v => !!v || 'Kein Passwort angegeben'
        ]
      }
    }),
    metaInfo () {
      const metaInfo = {
        title: 'Anmeldung u. Registrierung',
      }
      if (this.$route.query.redirect) {
        metaInfo['meta'] = [
          { vmid: "robots", name: "robots", content: "noindex" }
        ]
      }
      return metaInfo;
    },    
    beforeRouteEnter (to, from, next) {
      const { authToken } = to.params
      if (!authToken) {
        // Proceed normally
        return next()
      }
      // Remove any old session token
      store.dispatch('auth/resetSessionToken')
      // resolve: isAuthenticated changed to true, sessionToken set
      store.dispatch('auth/loginWithAuthToken', { authToken }).then(() => {
        // Redirect to hide authToken from URL
        // Get redirect from session cookie (set on requestLoginLink)
        const authRedirect = cookies.get('authRedirect')
        if (authRedirect) {
          cookies.erase('authRedirect')
          next({ path: '/anmelden', query: { redirect: authRedirect } })
        } else {
          next('/anmelden')
        }
      }).catch(err => {
        next(vm => {
          if (err.response && err.response.status === 401) {
            vm.snackbarMessage = 'Anmeldelink abgelaufen oder ungültig'
            vm.snackbar = true
          }
        })
      })
    },
    created () {
      if (this.$route.query.registrieren) {
        this.switchToRegister()
      }
    },
    computed: {
      ...mapGetters('auth', ['isAuthenticated', 'hasAccount', 'accountEmail', 'isLoginLinkPending']),
      ...mapGetters('legal', ['privacyPolicy'])
    },
    watch: {
      // Clear attempts
      'form.email': function () {
        this.probeAttemptFailed = false
        this.registerAttemptFailed = false
      },
      'form.password': function () {
        this.loginAttemptFailed = false
      }
    },
    methods: {
      ...mapActions('auth', {
        authProbeEmail: 'probeEmail',
        authRegister: 'register',
        authRequestLoginLink: 'requestLoginLink',
        authloginWithAuthToken: 'loginWithAuthToken',
        authLoginWithPassword: 'loginWithPassword',
        authResetSessionToken: 'resetSessionToken',
        authResetAccount: 'resetAccount'
      }),
      probeEmail () {
        if (!this.loading && this.$refs.formProbe.validate()) {
          const { email } = this.form
          this.loading = true
          // resolve: hasAccount changed to true, accountEmail set
          this.authProbeEmail({ email }).catch(err => {
            // Show snackbar via interceptor for server and network errors
            if (err.response && err.response.status === 400) {
              this.probeAttemptFailed = true
            }
          }).finally(() => { this.loading = false })
        }
      },
      registerAccount () {
        if (!this.loading && this.$refs.formRegister.validate()) {
          const { email, name } = this.form
          this.loading = true
          // resolve: isAuthenticated changed to true, sessionToken set
          this.authRegister({ email, name }).catch(err => {
            // Show snackbar otherwise
            if (err.response && err.response.status === 400) {
              this.registerAttemptFailed = true
            }
          }).finally(() => { this.loading = false })
        }
      },
      loginWithPassword () {
        if (!this.loading && this.$refs.formLogin.validate() &&
            !this.form.useLoginLink &&
            this.hasAccount) {
          const { password } = this.form
          this.loading = true
          // resolve: isAuthenticated changed to true, sessionToken set
          this.authLoginWithPassword({ email: this.accountEmail, password }).catch(err => {
            // Show snackbar otherwise
            if (err.response && err.response.status === 401) {
              this.loginAttemptFailed = true
            }
          }).finally(() => { this.loading = false })
        }
      },
      requestLoginLink () {
        if (!this.loading && this.form.useLoginLink && this.hasAccount) {
          this.loading = true
          this.authRequestLoginLink({ email: this.accountEmail }).then(resp => {
            this.waitingForLoginLink = true
            const { redirect } = this.$route.query
            if (redirect) {
              // Use session cookie to store redirect link
              // sessionStorage: Opening a page in a new tab or window
              // will cause a new session to be initiated,
              // which differs from how session cookies work.
              cookies.set('authRedirect', redirect, { expires: 0 })
            }
          }).catch(err => {
            if (err.response && err.response.status === 400) {
              this.snackbarMessage = err.response.data.message || 'Unbekannter Fehler aufgetreten'
              this.snackbar = true
            }
          }).finally(() => { this.loading = false })
        }
      },
      switchToRegister () {
        this.registerAttemptFailed = false
        this.register = true
        // Prevent validation
        this.$nextTick(() => {
          if (!this.form.email && this.$refs.formRegister) {
            this.$refs.formRegister.reset()
          }
        })
      },
      switchToLogin ({ useLoginLink = true } = {}) {
        this.probeAttemptFailed = false
        this.register = false
        // Prevent validation
        this.$nextTick(() => {
          if (!this.form.email && this.$refs.formProbe) {
            this.$refs.formProbe.reset()
          }
        })
        // Back to login from requestLoginLink
        this.waitingForLoginLink = false
        this.form.useLoginLink = useLoginLink
      },
      resetAccount () {
        this.authResetAccount()
        this.form.email = ''
        this.form.password = ''
        this.switchToLogin()
      }
    },
    components: {
      'ml-snackbar': ErrorSnackbar
    }
  }
</script>