<template>
  <div @click="clickSlot" id="ml-cart-slot">
    <slot :count="cartItemsCount" :disabled="routeIsBestellung"></slot>
    <v-menu
      v-model="showMenu"
      offset-y
      :close-on-content-click="false"
      attach="#ml-toolbar"
      content-class="ml-toolbar-menu"
      max-height="calc(100vh - 150px)"
      activator="#ml-cart-slot"
      @click.stop>
      <!-- Designate default slot and use as activator for menu -->
      <v-card>
        <template v-if="!!cartItems.length">
          <v-list dense>
            <v-list-tile
              v-if="cartItemsDirty"
              v-for="item in cartItemsDirty"
              :key="item.posId"
              avatar>
              <v-list-tile-avatar @click="goToProdukt(item.produkt.slug)" style="cursor: pointer;">
                <v-img contain v-if="!!item.produkt.images.length" :src="item.produkt.images[0].urls.thumb"></v-img>
                <v-img contain v-else :src="fallbackImageSrc"></v-img>
              </v-list-tile-avatar>
              <v-list-tile-content @click="goToProdukt(item.produkt.slug)" style="cursor: pointer;">
                <v-list-tile-title>
                  {{ item.produkt.title }}
                </v-list-tile-title>                                
                <v-list-tile-sub-title>{{ item.quantity ? `${item.quantity} x ${formatFuellmenge(item.sku.preis)}` : 'entfernt' }}</v-list-tile-sub-title>
                <v-list-tile-sub-title v-if="formatSkuVariants(item.sku)" class="caption">{{ formatSkuVariants(item.sku) }}</v-list-tile-sub-title>                
              </v-list-tile-content>
              <v-list-tile-action class="pl-3">
                <v-layout fill-height align-center justify-end>
                  <!--
                    Prevent touch to search on Android Chrome
                    https://developers.google.com/web/updates/2015/10/tap-to-search
                  -->
                  <v-icon 
                    @click.prevent="item.quantity = Math.max(0, item.quantity - 1)"
                    medium :color="item.quantity ? 'secondary' : 'grey lighten-3'">indeterminate_check_box</v-icon>
                  <v-icon 
                    @click.prevent="item.quantity = item.quantity + 1"
                    medium color="primary">add_box</v-icon>                
                </v-layout>                
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </template>
        <v-card-text v-else class="subheading text-xs-center">
          <p>Keine Produkte im Warenkorb</p>
          <v-icon>fa-sad-cry</v-icon>          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end py-2">
          <v-btn 
            v-if="!cartItems.length"
            @click.native="showMenu = false"
            to="/katalog"
            small flat color="primary">Produkte</v-btn>
          <v-btn 
            v-if="cartItemsRemoved"
            @click.native="updateCart"
            icon small>
            <v-icon>sync</v-icon></v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            :disabled="!cartItemsCount"
            @click="goToBestellung"
            small flat color="primary">Zur Bestellung</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>    
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import produktHelperMixin from '@/components/mixins/produkt-helper'

  export default {
    mixins: [produktHelperMixin],    
    data: () => ({
      showMenu: false,
      cartItemsDirty: null,
      fallbackImageSrc: require('@/assets/jar.png'),
      routeIsBestellung: false
    }),
    methods: {
      clickSlot () {
        if (this.showMenu) {
          // Always close
          this.showMenu = false
        } else if (!this.routeIsBestellung) {
          // Show except in Bestellung component
          this.showMenu = true
        }
      },
      reloadCart () {
        this.cartItemsDirty = this.cartItems.map(item => ({ ...item }))
      },
      updateCart () {
        return Promise.all(this.cartItemsDirty.map(item => {
          this.cartChangeItemQuantity(item)
        })).then(() => {
          this.reloadCart()
        })
      },
      goToBestellung () {
        // Make sure cart is updated for navigation guards
        this.updateCart().then(() => {
          this.$router.push('/bestellung')
        }).then(() => {
          this.showMenu = false
        })
      },
      goToProdukt (slug) {
        this.$router.push(`/katalog/${slug}`)
      },
      updateRouteData () {
        // currentRoute.name not reactive
        this.routeIsBestellung = this.$router.currentRoute.name === 'bestellung'
      },
      ...mapActions('cart', ['cartChangeItemQuantity'])
    },
    created () {
      // Initialize
      this.updateRouteData()
    },
    watch: {
      showMenu (val) {
        if (val) {
          this.reloadCart()
        } else {
          this.updateCart().then(() => {
            this.cartItemsDirty = null
          })
        }
      },
      // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
      '$route': 'updateRouteData'
    },
    computed: {
      cartItemsCount () {
        var cartItems = this.cartItemsDirty ? this.cartItemsDirty : this.cartItems
        return cartItems.reduce((acc, currentItem) => {
          return acc + currentItem.quantity
        }, 0)
      },
      cartItemsRemoved () {
        if (this.cartItemsDirty) {
          return !!this.cartItemsDirty.find(item => item.quantity === 0)
        }
        return false
      },
      ...mapGetters('cart', ['cartItems', 'cartIsLocked'])
    }
  }
</script>

<style>
  .ml-toolbar-menu {
    left: auto !important;
    /* .v-toolbar__content padding */
    right: 16px;
  }
</style>