import Vue from 'vue'
import axios from 'axios'
import hash from 'object-hash'

// Server-side validated
import { SHIPPING_LOW, SHIPPING_HIGH } from '@/store/constants'

export default {
  namespaced: true,
  // Persisted via vuex-persistedstate
  state: {
    items: [],
    orderAddressInvoice: null,
    orderAddressShipping: null,
    orderPaymentMethod: null,
    orderMessage: null,
    orderStep: null
  },
  getters: {
    cartItems: state => state.items,
    orderAddressInvoice: state => state.orderAddressInvoice,
    orderAddressShipping: state => state.orderAddressShipping,
    orderPaymentMethod: state => state.orderPaymentMethod,
    orderMessage: state => state.orderMessage,
    orderStep: state => state.orderStep,
    cartTotal: state => state.items.reduce((agg, item) => {
      agg.count += item.quantity
      const itemFreightUnits = item.sku.preis.freightUnits || 1;
      agg.freightUnits += (item.quantity * itemFreightUnits)
      agg.sum += item.quantity * item.sku.preis.preis
      if (item.sku.fuellMengeUnit === 'g') {
        agg.weight += item.sku.preis.fuellMenge
      } else if (item.sku.fuellMengeUnit === 'ml') {
        agg.volume += item.sku.preis.fuellMenge
      }
      return agg
    }, {count: 0, sum: 0, weight: 0, volume: 0, freightUnits: 0}),
    cartShippingCost: (state, getters) => {
      if (getters['cartTotal'].freightUnits) {
        return getters['cartTotal'].freightUnits <= 10
          ? SHIPPING_LOW
          : SHIPPING_HIGH
      }
      return 0
    }
  },
  mutations: {
    cartIncItem (state, { produkt, sku, notes = null }) {      
      if (!sku._id || !produkt.skus.find(produktSku => produktSku._id === sku._id)) {
        return
      }
      const posId = `${produkt._id}_${sku._id}_${hash(notes)}`
      // Use produkt._id as proxy for sku - FIXED
      var item = state.items.find(item => item.posId === posId)
      if (item) {
        Vue.set(item, 'quantity', item.quantity + 1)
      } else {
        state.items.push({
          produkt,
          sku,
          notes,
          posId,
          quantity: 1
        })
      }
    },
    cartDecItem (state, { produkt, sku, notes = null }) {
      const posId = `${produkt._id}_${sku._id}_${hash(notes)}`
      var item = state.items.find(item => item.posId === posId)
      if (item) {
        if (item.quantity > 1) {
          Vue.set(item, 'quantity', item.quantity - 1)
        } else {
          state.items.splice(state.items.indexOf(item), 1)
        }
      }
    },
    emptyCart (state) {
      state.items = []
    },
    setOrderAddressInvoice (state, address) {
      state.orderAddressInvoice = address
    },
    setOrderAddressShipping (state, address) {
      state.orderAddressShipping = address
    },
    setOrderPaymentMethod (state, method) {
      state.orderPaymentMethod = method
    },
    setOrderMessage (state, message) {
      state.orderMessage = message
    },
    setOrderStep (state, step) {
      state.orderStep = step
    }
  },
  actions: {
    sendOrder: ({ state, getters, commit, dispatch, rootState }) => {
      return new Promise((resolve, reject) => {
        axios({
          url: `${rootState.apiUrl}/bestellungen`,
          data: {
            adresseRechnung: getters['orderAddressInvoice'],
            adresseLieferung: getters['orderAddressShipping'],
            zahlweise: getters['orderPaymentMethod'],
            // Getting transformed on server
            positionen: getters['cartItems'],
            versandkosten: getters['cartShippingCost'],
            // Getting transformed on server
            nachrichten: getters['orderMessage']
              ? [{ message: getters['orderMessage'] }]
              : []
          },
          requiresAuth: true,
          method: 'POST'
        }).then(resp => {
          dispatch('resetOrder')
          resolve(resp.data)
        }).catch(reject)
      })
    },
    cartChangeItemQuantity: ({ state, commit, dispatch, rootState }, { produkt, sku, notes = null, posId, quantity }) => {
      var item = state.items.find(item => item.posId === posId)
      const currentQuantity = item ? item.quantity : 0
      const quantityDiff = quantity - currentQuantity
      for (let i = 0; i < Math.abs(quantityDiff); i++) {
        commit(quantityDiff > 0 ? 'cartIncItem' : 'cartDecItem', { produkt, sku, notes })
      }
    },
    // When user profile changes
    resetOrderAddress: ({ state, commit, dispatch, rootState }) => {
      commit('setOrderAddressInvoice', null)
      commit('setOrderAddressShipping', null)
    },
    resetOrder: ({ state, commit, dispatch, rootState }) => {
      commit('emptyCart')
      commit('setOrderPaymentMethod', null)
      commit('setOrderMessage', null)
      commit('setOrderStep', null)
      dispatch('resetOrderAddress')
    }
  }
}
