import axios from 'axios'

const state = {
  categories: null
}

const getters = {
  categoriesList: state => {
    return state.categories ? state.categories : []
  },
  treeView: state => {
    // TODO: Recursion
    var categoriesRoot = null
    if (state.categories) {
      const categoriesMap = {}
      state.categories.forEach(element => {
        categoriesMap[element._id] = { ...element, children: [] }
      })
      Object.values(categoriesMap).forEach(element => {
        if (element.parent) {
          categoriesMap[element.parent].children.push(element)
        } else {
          categoriesRoot = element
        }
      })
      Object.values(categoriesMap).forEach(element => {
        element.children.sort((a, b) => a.order - b.order)
      })
    }
    return categoriesRoot
  },
  category: state => id => state.categories
    ? state.categories.find(cat => cat._id === id)
    : null
}

const mutations = {
  updateCategories (state, categories) {
    state.categories = categories
  }
}

const actions = {
  // Public API call
  loadCategories: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/categories`,
        method: 'GET'
      }).then(resp => {
        commit('updateCategories', resp.data.categories)
        resolve()
      }).catch(reject)
    })
  },
  createCategory: ({ commit, dispatch, rootState }, { title, ancestors, alias, parent, produkttypMain }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/categories`,
        data: { title, ancestors, alias, parent, produkttypMain },
        method: 'POST',
        requiresAuth: true
      }).then(() => dispatch('loadCategories')).then(resolve).catch(reject)
    })
  },
  updateOrder: ({ commit, dispatch, rootState }, { categories }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/categories/order`,
        // Uses _id and order server-side
        data: { categories },
        method: 'POST',
        requiresAuth: true
      }).then(() => dispatch('loadCategories')).then(resolve).catch(reject)
    })
  },
  // Vuex only allows one parameter as payload
  updateCategory: ({ commit, dispatch, rootState }, { _id: categoryId, title, alias, produkttypMain }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/categories/${categoryId}`,
        data: { title, alias, produkttypMain },
        method: 'PATCH',
        requiresAuth: true
      }).then(() => dispatch('loadCategories')).then(resolve).catch(reject)
    })
  },
  deleteCategory: ({ commit, dispatch, rootState }, categoryId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/categories/${categoryId}`,
        method: 'DELETE',
        requiresAuth: true
      }).then(() => dispatch('loadCategories')).then(resolve).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
