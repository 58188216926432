<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm8 md6>
        <v-card>
          <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">          
          <v-card-title primary-title>
            <h3 class="title">Ihr Konto</h3>
          </v-card-title>
          <v-divider :class="{'mx-3': $vuetify.breakpoint.xsOnly}"></v-divider>
          <v-card-text>
            <div class="grey--text">Klicken Sie in die Felder, um Ihre Daten zu bearbeiten</div>
            <v-text-field
              @click.prevent="dialogNameShow"
              :value="userProfile.name"
              label="Name"
              hide-details
              readonly
              outline
              class="mt-3">
            </v-text-field>
            <v-text-field
              @click.prevent="dialogEmailShow"
              :value="userProfile.email"
              label="E-Mail"
              hide-details
              readonly
              outline
              class="mt-3">
            </v-text-field>
            <v-layout class="mt-1">
              <v-spacer></v-spacer>
              <v-btn flat small @click.native="dialogPasswordShow" class="mx-0">Passwort ändern</v-btn>
            </v-layout>            
          </v-card-text>
          <v-card-title class="pt-0">
            <h3 class="title">Adresse</h3>
          </v-card-title>
          <v-divider :class="{'mx-3': $vuetify.breakpoint.xsOnly}"></v-divider>
          <v-card-text>
            <div class="subheading">Ihre Adresse für die nächste Bestellung:</div>
            <v-container class="pa-0 mt-3">
              <v-layout wrap style="margin: -8px">
                <v-flex xs12 lg6 class="pa-2">
                  <v-textarea
                    @click.prevent="dialogAddressInvoiceShow"
                    :value="formatAddress(userProfile.address.invoice)"
                    label="Rechnungsadresse"
                    hide-details
                    outline
                    auto-grow :rows="3"
                    class="ml-address"></v-textarea>
                </v-flex>
                <v-flex xs12 lg6 class="pa-2">
                  <v-textarea
                    @click.prevent="dialogAddressShippingShow"                  
                    :value="formatAddress(userProfile.address.shipping || userProfile.address.invoice)"
                    label="Lieferadresse"
                    hint="Falls abweichend von Rechnungsadresse"
                    persistent-hint
                    outline
                    auto-grow :rows="3"
                    class="ml-address"></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
            <template v-if="isAdmin">
              <div v-if="instagramUser" class="mt-3">
                Verbunden mit Instagram:
                <span class="font-weight-bold">@{{ instagramUser.username }}</span>
              </div>
              <div v-else class="mt-3">
                <v-btn :href="instagramConnectUrl" :disabled="!instagramConnectUrl">
                  <v-icon left>fab fa-instagram</v-icon>
                  Verbinden mit Instagram
                </v-btn>
              </div>
            </template>
          </v-card-text>
          <v-divider :class="{'mx-3': $vuetify.breakpoint.xsOnly}"></v-divider>
          <v-card-text>
            <v-btn small color="error" class="mx-0" @click.native="dialogDeleteAccount = true">Konto löschen</v-btn>
          </v-card-text>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogName" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Name ändern</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="subheading">Wie sollen wir Sie anreden?</div>
          <!-- Confirm: Required for autocomplete to work in Google Chrome -->
          <v-form @submit.prevent>
            <v-text-field
              v-model.trim="formName"
              :disabled="loading"
              label="Name"
              hint="Ihr Name ist nicht öffentlich sichtbar für andere Nutzer"
              persistent-hint
              single-line></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn small flat @click.native="dialogName = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn small flat color="primary" @click.native="formNameSubmit" :disabled="loading">Ändern</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEmail" max-width="500">
      <v-card>
        <v-card-title>
          <h3>E-Mail ändern</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form @submit.prevent>          
            <v-text-field
              :value="userProfile.email"
              label="Aktuelle E-Mail"
              disabled></v-text-field>
            <v-text-field
              ref="formEmailRef"
              v-model.trim="formEmail"
              :disabled="loading"
              :rules="[
                v => !!v || 'Keine E-Mail angegeben',
                v => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-Mail ungültig'
              ]"
              validate-on-blur
              label="Neue E-Mail"
              type="email"
              name="email"
              autocomplete="email"></v-text-field>          
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn small flat @click.native="dialogEmail = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn small flat color="primary" @click.native="formEmailSubmit" :disabled="loading">Ändern</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPassword" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Passwort ändern</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-form ref="formPasswordRef" @submit.prevent>
            <v-text-field
              v-model="formOldPassword"
              :append-icon="formOldPasswordVisible ? 'visibility_off' : 'visibility'"
              @click:append="formOldPasswordVisible = !formOldPasswordVisible"
              :type="formOldPasswordVisible ? 'text' : 'password'"
              label="Aktuelles Passwort"
              hint="Wir haben Ihnen ein initiales Passwort bei der Registrierung zugeschickt"
              persistent-hint></v-text-field>
            <v-text-field
              v-model="formNewPassword"
              :append-icon="formNewPasswordVisible ? 'visibility_off' : 'visibility'"
              @click:append="formNewPasswordVisible = !formNewPasswordVisible"
              :type="formNewPasswordVisible ? 'text' : 'password'"
              :rules="[v => !!v && v.length >= 8 || 'Mindestens 8 Zeichen']"
              validate-on-blur
              label="Neues Passwort"
              hint="Mindestens 8 Zeichen"
              class="mt-3"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn small flat @click.native="dialogPassword = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn small flat color="primary" @click.native="formPasswordSubmit" :disabled="loading">Ändern</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ml-dialog-address
      v-model="dialogAddressInvoice"
      :address="formAddressInvoice"
      :return-object.sync="formAddressInvoice"
      @save="formAddressInvoiceSubmit(formAddressInvoice)"
      @delete="formAddressInvoiceSubmit(null)"
      :loading="loading"
      :deletable="!!userProfile.address.invoice"      
      label="Rechnungsadresse"></ml-dialog-address>
    <ml-dialog-address
      ref="dialogAddressShippingRef"
      v-model="dialogAddressShipping"
      :address="formAddressShipping"
      :return-object.sync="formAddressShipping"
      @save="formAddressShippingSubmit(formAddressShippingDifferent ? formAddressShipping : null)"
      @delete="formAddressShippingSubmit(null)"
      :loading="loading"
      :disabled="!formAddressShippingDifferent"
      :deletable="!!userProfile.address.shipping"
      label="Lieferadresse">
      <template slot="prepend-form">
        <v-checkbox
          v-model="formAddressShippingDifferent"
          label="Abweichende Lieferadresse"
          hide-details class="mt-0"></v-checkbox>
      </template>
      </ml-dialog-address>
    <v-dialog v-model="dialogDeleteAccount" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Konto löschen</h3>
        </v-card-title>
        <v-card-text class="pt-0">
           <div>Schade, dass Sie uns verlassen möchten!</div>
          <!-- Confirm: Required for autocomplete to work in Google Chrome -->
          <v-form @submit.prevent>
            <v-textarea
              v-model.trim="formDeleteReason"
              :disabled="loading"
              label="Grund (optional)"
              hint="Sie können uns noch eine Nachricht hinterlassen"
              persistent-hint
              box auto-grow
              class="mt-3"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="wrap">
          <v-btn small flat @click.native="dialogDeleteAccount = false">Abbrechen</v-btn>
          <v-spacer></v-spacer>
          <v-btn small flat color="error" @click.native="formDeleteAccount" :disabled="loading">Endgültig löschen</v-btn>              
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import DialogAddress from '@/components/special/DialogAddress'
  
  import userHelperMixin from '@/components/mixins/user-helper'

  import store from '@/store'

  export default {
    mixins: [userHelperMixin],
    data: () => ({
      loading: false,
      dialogName: false,
      dialogEmail: false,
      dialogPassword: false,
      dialogAddressInvoice: false,
      dialogAddressShipping: false,
      dialogDeleteAccount: false,
      // Forms
      formName: null,
      formEmail: null,
      formOldPassword: null,
      formOldPasswordVisible: false,
      formNewPassword: null,
      formNewPasswordVisible: false,
      formDeleteReason: null,
      formAddressInvoice: null,
      formAddressShipping: null,
      formAddressShippingDirty: null,
      formAddressShippingDifferent: false
    }),
    metaInfo: {
      title: 'Konto'
    },
    computed: {
      ...mapGetters('user', {
        userProfile: 'profile',
        instagramUser: 'instagramUser',
        instagramConnectUrl: 'instagramConnectUrl',
        addressCountries: 'addressCountries'
      }),
      ...mapGetters('auth', ['isAdmin'])
    },
    watch: {
      // Save form data state when toggling between Rechnungsadresse and Lieferadresse
      formAddressShippingDifferent (val) {
        if (this.dialogAddressShipping) {
          if (val) { // Lieferadresse abweichend
            this.formAddressShipping = this.formAddressShippingDirty
              ? this.formAddressShippingDirty
              : null
          } else { // Disabled sate, show Rechnungsadresse read-only
            this.formAddressShippingDirty = this.$refs.dialogAddressShippingRef.getData()
            this.formAddressShipping = {
              ...this.userProfile.address.invoice
            }
          }
        }
      }
    },
    methods: {
      formNameSubmit () {
        this.loading = true
        this.updateName({ name: this.formName }).then(() => {
          this.dialogName = false
        }).finally(() => {
          this.loading = false
        })
      },
      formEmailSubmit () {
        if (this.$refs.formEmailRef.validate() && !this.loading) {
          this.loading = true
          this.updateEmail({ email: this.formEmail }).then(() => {
            this.dialogEmail = false
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formPasswordSubmit () {
        if (this.$refs.formPasswordRef.validate() && !this.loading) {
          this.loading = true
          this.updatePassword({ oldPassword: this.formOldPassword, newPassword: this.formNewPassword }).then(() => {
            this.dialogPassword = false
          }).finally(() => {
            this.loading = false
          })
        }
      },
      formAddressInvoiceSubmit (address) {
        this.loading = true
        const actionPromise = address
          ? this.updateAddressInvoice(address)
          : this.deleteAddressInvoice()
        actionPromise.then(() => {
          this.dialogAddressInvoice = false
        }).finally(() => {
          this.loading = false
        })
      },
      formAddressShippingSubmit (address) {
        this.loading = true
        const actionPromise = address
          ? this.updateAddressShipping(address)
          : this.deleteAddressShipping()
        actionPromise.then(() => {
          this.dialogAddressShipping = false
        }).finally(() => {
          this.loading = false
        })
      },
      formDeleteAccount () {
        this.loading = true
        this.deleteAccount({ reason: this.formDeleteReason }).then(() => {
          this.dialogDeleteAccount = false
          this.$router.push('/abmelden')
        }).finally(() => {
          this.loading = false
        })
      },
      dialogNameShow () {
        this.formName = this.userProfile.name
        this.dialogName = true
      },
      dialogEmailShow () {
        // Clear any validation errors
        this.$refs.formEmailRef.reset()
        // this.formEmail = null
        this.dialogEmail = true
      },
      dialogPasswordShow () {
        this.$refs.formPasswordRef.reset()
        this.formOldPasswordVisible = false
        this.formNewPasswordVisible = false
        this.dialogPassword = true
      },
      dialogAddressInvoiceShow () {
        if (this.userProfile.address.invoice) {
          this.formAddressInvoice = {
            ...this.userProfile.address.invoice
          }
        } else {
          this.formAddressInvoice = {
            fullname: this.userProfile.name,
            country: this.addressCountries[0] || 'Deutschland'
          }
        }
        this.dialogAddressInvoice = true
      },
      dialogAddressShippingShow () {
        if (this.userProfile.address.shipping) {
          this.formAddressShipping = {
            ...this.userProfile.address.shipping
          }
          this.formAddressShippingDifferent = true
        } else if (this.userProfile.address.invoice) {
          this.formAddressShipping = {
            ...this.userProfile.address.invoice
          }
          this.formAddressShippingDifferent = false
        } else {
          this.formAddressShipping = null
          this.formAddressShippingDifferent = false
        }
        this.formAddressShippingDirty = null
        this.dialogAddressShipping = true
      },
      ...mapActions('user', ['loadUser', 'updateName', 'updateEmail', 'updatePassword', 'deleteAccount']),
      ...mapActions('user', ['updateAddressInvoice', 'updateAddressShipping', 'deleteAddressInvoice', 'deleteAddressShipping'])
    },
    beforeRouteEnter (to, from, next) {
      // Force reload to renew instagramConnectToken
      store.dispatch('user/loadUser', { forceReload: true }).then((success) => {
        if (success) {
          return next()
        }
        next('/anmelden')
      }).catch(() => {
        next(false)
      })
    },
    beforeRouteLeave (to, from, next) {
      // Close dialogs instead of navigating to other page
      if (this.dialogName || this.dialogEmail || this.dialogPassword || this.dialogDeleteAccount) {
        this.dialogName = false
        this.dialogEmail = false
        this.dialogPassword = false
        this.dialogDeleteAccount = false
        return next(false)
      } else if (this.dialogAddressInvoice || this.dialogAddressShipping) {
        this.dialogAddressInvoice = false
        this.dialogAddressShipping = false
        return next(false)
      }
      next()
    },
    components: {
      'ml-dialog-address': DialogAddress
    }
  }
</script>

<style>
</style>