import Vue from 'vue'
import axios from 'axios'

const state = {
  jobs: [],
  printerStatus: null,
  printerStatusMessage: null,
  printerStatusDate: null,
  cleanJobsDaysBefore: 10,
  vorlageOpts: {
    FLAG_LONG_TITLE: 0b0001,
  },
  vorlageOptsBits: 4,
  vorlageOptsLongTitleChars: 40,
  vorlagen: [
    {
      text: 'Fruchtaufstrich',
      _version: 1,
      produkttypen: ['konfituere', 'gelee', 'marmelade', 'fruchtaufstrich', 'pikantes'],
      specialFields: ['fruchtanteil', 'qrCode'],
      autoTitle: true,
      haltbarkeit: 12,
      defaultValues: {
        title: 'Fruchtaufstrich',
        fruchtanteil: 60,
        hergestelltAus: 'Früchten',
        fuellMengeUnit: 'g',
        zutaten: [{
          zutat: {
            title: 'Gelierzucker',
            details: 'Zucker, Säuerungsmittel Citronensäure, Geliermittel Apfel-Pektin, Sonnenblumenöl',
            frucht: false,
            common: true
          },
          anteil: null
        }]
      },
      sizes: [
        {
          label: 'fruchtaufstrich_62x50',
          printer: 'Brother_QL-570',
          text: 'groß',
          description: '50 mm (Nwa.)',
          rules: { minFuellMenge: 340 },
          lmiv: true,
          defaultValues: { fuellMenge: 340, count: 7 },
          supportedOptionsMask: 0b1111
        },
        {
          label: 'fruchtaufstrich_62x38',
          printer: 'Brother_QL-570',
          text: 'mittel',
          description: '38 mm',
          defaultValues: { fuellMenge: 340, count: 7 },
          supportedOptionsMask: 0b1111
        },
        {
          label: 'fruchtaufstrich_62x32',
          printer: 'Brother_QL-570',
          text: 'klein',
          description: '32 mm',
          rules: { maxFuellMenge: 140 },
          defaultValues: { fuellMenge: 140, count: 2 },
          supportedOptionsMask: 0b1111
        }
      ]
    },
    {
      text: 'Blüte',
      _version: 1,
      produkttypen: ['konfituere', 'gelee', 'marmelade'],
      categories: ['holunderbluete', 'loewenzahn', 'rosenbluete', 'blueten-gelees-aus-holunder-rosen-und-loewenzahnblueten'],
      specialFields: ['fruchtanteil', 'qrCode'],
      autoTitle: true,
      haltbarkeit: 12,
      defaultValues: {
        title: 'Fruchtaufstrich',
        fruchtanteil: 60,
        hergestelltAus: 'Früchten',
        fuellMengeUnit: 'g',
        zutaten: [{
          zutat: {
            title: 'Gelierzucker',
            details: 'Zucker, Säuerungsmittel Citronensäure, Geliermittel Apfel-Pektin, Sonnenblumenöl',
            frucht: false,
            common: true
          },
          anteil: null
        }]
      },
      sizes: [
        {
          label: 'fruchtaufstrich_62x50',
          printer: 'Brother_QL-570',
          text: 'groß',
          description: '50 mm (Nwa.)',
          rules: { minFuellMenge: 340 },
          lmiv: true,
          defaultValues: { fuellMenge: 340, count: 7 },
          supportedOptionsMask: 0b1111
        },
        {
          label: 'bluete_62x38',
          printer: 'Brother_QL-570',
          text: 'normal',
          description: '38 mm (Nwa.)',
          lmiv: true,
          defaultValues: { fuellMenge: 225, count: 7 },
          supportedOptionsMask: 0b0000
        },
        {
          label: 'fruchtaufstrich_62x32',
          printer: 'Brother_QL-570',
          text: 'klein',
          description: '32 mm',
          rules: { maxFuellMenge: 140 },
          defaultValues: { fuellMenge: 140, count: 2 },
          supportedOptionsMask: 0b1111
        }
      ]
    },
    {
      text: 'Aufstrich',
      _version: 1,
      produkttypen: ['pikantes', 'eingemachtes', 'konfituere', 'gelee', 'marmelade', 'fruchtaufstrich'],
      specialFields: ['fruchtanteil', 'hergestelltAus', 'qrCode'],
      autoTitle: false,
      haltbarkeit: 12,
      defaultValues: {
        title: 'Aufstrich',
        fruchtanteil: 60,
        hergestelltAus: 'Früchten',
        fuellMengeUnit: 'g',
        zutaten: [{
          zutat: {
            title: 'Gelierzucker',
            details: 'Zucker, Säuerungsmittel Citronensäure, Geliermittel Apfel-Pektin, Sonnenblumenöl',
            frucht: false,
            common: true
          },
          anteil: null
        }]
      },
      sizes: [
        {
          label: 'fruchtaufstrich_62x50',
          printer: 'Brother_QL-570',
          text: 'groß',
          description: '50 mm (Nwa.)',
          rules: { minFuellMenge: 340 },
          lmiv: true,
          defaultValues: { fuellMenge: 340, count: 7 },
          supportedOptionsMask: 0b1111
        },
        {
          label: 'fruchtaufstrich_62x38',
          printer: 'Brother_QL-570',
          text: 'mittel',
          description: '38 mm',
          rules: { minFuellMenge: 340 },
          defaultValues: { fuellMenge: 340, count: 7 },
          supportedOptionsMask: 0b1111
        },
        {
          label: 'fruchtaufstrich_62x32',
          printer: 'Brother_QL-570',
          text: 'klein',
          description: '32 mm',
          rules: { maxFuellMenge: 225 },
          defaultValues: { fuellMenge: 225, count: 2 },
          supportedOptionsMask: 0b1111
        }
      ]
    },
    {
      text: 'Sirup',
      _version: 1,
      produkttypen: ['sirup'],
      specialFields: ['qrCode'],
      autoTitle: true,
      haltbarkeit: 12,
      defaultValues: {
        title: 'Sirup',
        //fruchtanteil: 60,
        //hergestelltAus: 'Früchten',
        fuellMengeUnit: 'ml',
        zutaten: [
          { zutat: { title: 'stilles Wasser', frucht: false, common: true }, anteil: null },
          { zutat: { title: 'Zucker', frucht: false, common: true }, anteil: null }          
        ]
      },
      sizes: [
        {
          label: 'sirup_62x50',
          printer: 'Brother_QL-570',
          text: 'groß',
          description: '50 mm (Nwa.)',
          lmiv: true,
          defaultValues: { fuellMenge: 500, count: 4 },
          supportedOptionsMask: 0b0000
        },
      ]
    }
  ]
}

const getters = {
  vorlagenList: state => state.vorlagen,
  printJobs: state => {
    return state.jobs
  },
  findJob: state => jobId => {
    return state.jobs.find(job => job.jobId === jobId)
  },
  printerStatus: state => ({
    printing: state.jobs.map(job => job.printing).includes(true),
    status: state.printerStatus,
    message: state.printerStatusMessage,
    date: state.printerStatusDate
  })
}

const mutations = {
  addJob (state, job) {
    state.jobs.unshift(Object.assign({
      // Defaults
      jobId: Date.now(),
      data: null,
      printing: false,
      completed: false,
      error: null,
      highlighted: false
    }, job))
  },
  updateJob (state, jobUpdate) {
    const jobIndex = state.jobs.findIndex(job => job.jobId === jobUpdate.jobId)
    if (jobIndex !== -1) {
      // https://vuejs.org/v2/guide/list.html#Caveats
      Vue.set(state.jobs, jobIndex, {
        ...state.jobs[jobIndex],
        ...jobUpdate
      })
    }
  },
  deleteJob (state, jobId) {
    const jobIndex = state.jobs.findIndex(job => job.jobId === jobId)
    if (jobIndex !== -1) {
      state.jobs.splice(jobIndex, 1)
    }
  },
  // Reduce localStorage write ops
  deleteJobsBatch (state, jobIds = []) {
    state.jobs = state.jobs.filter(job => !jobIds.includes(job.jobId))
  },  
  updatePrinterStatusSuccess (state, message) {
    state.printerStatus = true
    state.printerStatusMessage = message
    state.printerStatusDate = new Date()
  },
  updatePrinterStatusError (state, errMessage) {
    state.printerStatus = false
    state.printerStatusMessage = errMessage
    state.printerStatusDate = new Date()
  }
}

const actions = {
  storeEtikett: ({ commit, getters, dispatch, rootState }, data) => {
    const job = {
      jobId: Date.now(),
      data,
      // Mark as highlighted
      highlighted: true
    }
    commit('addJob', job)

    // Prevent making copies of stored (highlighted) jobs
    const parentJob = getters['findJob']((data.parentJob || {}).jobId)
    if (parentJob && parentJob.highlighted && !parentJob.completed && !parentJob.printing) {
      commit('deleteJob', data.parentJob.jobId)      
    }

    return job.jobId
  },
  printEtikett: ({ commit, getters, dispatch, rootState }, data) => {
    const job = {
      jobId: Date.now(),
      data
    }
    commit('addJob', job)

    // Clear highlighted status from parent job and keep reference
    const parentJob = getters['findJob']((data.parentJob || {}).jobId)
    const parentJobHighlighted = parentJob && parentJob.highlighted
    if (parentJobHighlighted) {
      commit('updateJob', { jobId: data.parentJob.jobId, highlighted: false })      
    }

    // Do not wait for action to complete
    dispatch('printJob', job.jobId).then(() => {
      if (parentJobHighlighted) {        
        commit('updateJob', { jobId: data.parentJob.jobId, completed: 2 })
      }
    })

    return job.jobId
  },
  printJob: ({ getters, commit, dispatch, rootState }, jobId) => {
    const job = getters['findJob'](jobId)
    if (!job || job.printing) {
      return
    }
    // Set printing
    commit('updateJob', {
      jobId,
      printing: true,
      // Reset any failed attempt
      completed: false,
      error: null
    })
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     //reject(new Error('dummy'))
    //     resolve({ data: "Test"})
    //   }, 5000)
    // }).then(resp => {    
    return axios({
      url: `${rootState.printerUrl}/api/print`,
      data: job.data.etikettCSV,
      params: job.data.printOptions,
      method: 'POST'
    }).then(resp => {
      commit('updatePrinterStatusSuccess', resp.data)
      // Might ovverride clearJobs printing timeout (intended)
      commit('updateJob', {
        jobId,
        printing: false,
        // Completed print
        completed: 1,
        error: null
      })
    }).catch(err => {
      commit('updatePrinterStatusError', err.message)
      commit('updateJob', {
        jobId,
        printing: false,
        completed: false,
        error: err.message
      })
    })
  },
  cleanJobs ({ state, getters, commit, dispatch }, { 
    beforeDate = Date.now() - state.cleanJobsDaysBefore * 24 * 3600 * 1000,
    includeHighlighted = false,    
    clearPrintingStatus = true,
    printTimeoutSeconds = 90
  } = {}) {
    const deleteJobIds = state.jobs.filter(job => {
      if (job.jobId < beforeDate) {
        return includeHighlighted ? true : !job.highlighted
      }
    });
    if (deleteJobIds.length) {
      commit('deleteJobsBatch', deleteJobIds);
    }
    state.jobs.forEach(job => {
      if (clearPrintingStatus && job.printing) {
        if (Date.now() - job.jobId > printTimeoutSeconds * 1000) {
          commit('updateJob', {
            jobId: job.jobId,
            printing: false,
            completed: false,
            error: 'Timeout'
          })
        }
      }
    })
  },
  queryPrinterStatus: ({ getters, commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.printerUrl}/api/status`,
        method: 'GET'
      }).then(resp => {
        commit('updatePrinterStatusSuccess', resp.data)
      }).catch(err => {
        commit('updatePrinterStatusError', err.message)
      }).finally(resolve)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
