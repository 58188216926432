import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import {
  API_URL,
  API_MAX_AGE,
  SITE_URL,
  PRINTER_URL,
  INSTAGRAM_CONNECT_URL
} from './constants'

import auth from './modules/auth'
import user from './modules/user'
import navigation from './modules/navigation'
import legal from './modules/legal'
import background from './modules/background'
import products from './modules/products'
import events from './modules/events'
import cart from './modules/cart'
import ui from './modules/ui'
import faq from './modules/faq'
import contact from './modules/contact'
import orders from './modules/orders'
import adminCategories from './modules/admin/categories'
import adminProducts from './modules/admin/products'
import adminLabels from './modules/admin/labels'
import adminRezeptur from './modules/admin/rezeptur'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['cart', 'ui', 'admin.labels.jobs']
  })],
  state: {
    apiUrl: API_URL,
    apiMaxAge: API_MAX_AGE,
    // Used for canonical links
    siteUrl: SITE_URL,
    printerUrl: PRINTER_URL,
    instagramConnectUrl: INSTAGRAM_CONNECT_URL,
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  modules: {
    auth,
    user,
    navigation,
    legal,
    background,
    products,
    events,
    cart,
    ui,
    faq,
    contact,
    orders,
    admin: {
      namespaced: true,
      modules: {
        categories: adminCategories,
        products: adminProducts,
        labels: adminLabels,
        rezeptur: adminRezeptur
      }
    }
  }
})
