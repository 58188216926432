import axios from 'axios'

const actions = {
  sendMeldung: ({ commit, dispatch, rootState }, meldung) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/meldung`,
        data: meldung,
        method: 'POST',
        requiresAuth: true,
        showSuccessMessage: true        
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  },  
  findRezeptur: ({ state, commit, dispatch, rootState }, rezepturId) => {
    return new Promise((resolve, reject) => {
      if (!rezepturId) {
        reject(new Error('No id provided'))
      }
      axios({
        url: `${rootState.apiUrl}/rezeptur/${rezepturId}`,
        requiresAuth: true,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  },
  getRezepturen: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        resolve(resp.data.rezepturen)
      }).catch(reject)
    })
  },
  getNaehrwertangaben: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/naehrwertangaben`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        resolve(resp.data.naehrwertangaben)
      }).catch(reject)
    })
  },  
  createRezeptur: ({ commit, dispatch, rootState }, { title }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur`,
        data: { title },
        method: 'POST',
        requiresAuth: true
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  },
  updateRezeptur: ({ commit, dispatch, rootState }, rezeptur) => {
    if (!rezeptur._id) {
      return Promise.reject(new Error('Rezeptur Id missing'))
    }
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/${rezeptur._id}`,
        data: rezeptur,
        method: 'PATCH',
        requiresAuth: true,
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  },
  deleteRezeptur: ({ commit, dispatch, rootState }, rezepturId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/${rezepturId}`,
        method: 'DELETE',
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  },  
  createLebensmittel: ({ commit, dispatch, rootState }, lebensmittel) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/lebensmittel`,
        data: lebensmittel,
        method: 'POST',
        requiresAuth: true,
        showSuccessMessage: true
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  },  
  updateLebensmittel: ({ commit, dispatch, rootState }, lebensmittel) => {
    if (!lebensmittel._id) {
      return Promise.reject(new Error('Lebensmittel Id missing'))
    }
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/lebensmittel/${lebensmittel._id}`,
        data: lebensmittel,
        method: 'PATCH',
        requiresAuth: true,
        showSuccessMessage: true
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  },
  deleteLebensmittel: ({ commit, dispatch, rootState }, lebensmittelId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/lebensmittel/${lebensmittelId}`,
        method: 'DELETE',
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  },  
  getLebensmittel: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/rezeptur/lebensmittel`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        resolve(resp.data.lebensmittel)
      }).catch(reject)
    })
  },
  findLebensmittel: ({ state, commit, dispatch, rootState }, lebensmittelId) => {
    return new Promise((resolve, reject) => {
      if (!lebensmittelId) {
        reject(new Error('No id provided'))
      }
      axios({
        url: `${rootState.apiUrl}/rezeptur/lebensmittel/${lebensmittelId}`,
        requiresAuth: true,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  actions
}
