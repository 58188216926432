<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>           
      <v-card>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title">Neue Gläser</h3>
          </v-card-title>
          <v-card-text class="pt-0">
            <v-container grid-list-lg class="pa-0">
            <v-layout align-center wrap>
              <v-flex xs12 md6>
                <v-img src="/img/neue-glaeser-2019-sm.jpg"></v-img>
              </v-flex>                      
              <v-flex xs12 md6>
                <p class="subheading">
                Liebe Kunden, seit Sep. 2019 bieten wir Ihnen unsere Fruchtaufstriche im <strong>neuen Sturzglas</strong> an.
                </p>
                <p>Das bisher verwendete Sechseckglas ist auf dem Glasmarkt nicht mehr erhältlich, daher haben wir uns für diese Alternative entschieden.</p>
                <v-layout wrap>
                  <v-flex xs6 class="text-xs-center">
                    <v-img src="/img/sechseckglas.png"></v-img>
                    <div class="caption">Sechseckglas 280 ml</div>
                  </v-flex>
                  <v-flex xs6 class="text-xs-center">
                    <v-img src="/img/sturzglas.png"></v-img>
                    <div class="caption">Sturzglas 277 ml</div></v-flex>
                </v-layout>                
              </v-flex>              
            </v-layout>            
            </v-container>
          </v-card-text>
        </v-container>
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Neue Gläser seit Sep. 2019',
    }
  }
</script>