import EventBus from './event-bus'
import store from '@/store'

export function routeAuthorize (to, from, next) {
  const authRequired = to.matched
    .filter(record => record.meta.requiresAuth)
    .map(record => record.meta.requiresAuth)
  if (authRequired.length) {
    const isAuthenticated = store.getters['auth/isAuthenticated']
    const isAdmin = store.getters['auth/isAdmin']
    if (!isAuthenticated) {
      return next({
        path: '/anmelden',
        query: { redirect: to.fullPath }
      })
    }
    if (authRequired.includes('admin') && !isAdmin) {
      return next('/403')
    }
  }
  next()
}

export async function routeQRCodeRedirect (to, from, next) {
  try {
    var { type, lfdNr } = to.params
    lfdNr = parseInt(lfdNr)
    if (type === 'p' && !isNaN(lfdNr)) {
      // Rejected promise will be caught
      const produkt = await store.dispatch('products/findProdukt', { lfdNr })
      next(`/katalog/${produkt.slug}`)
    } else {
      throw new Error('QRCode invalid')
    }
  } catch (err) {
    next('/404')
  }
}

export async function routeKatalogFaidRedirect (to, from, next) {
  try {
    const faid = parseInt(to.params.faid)
    if (!isNaN(faid)) {
      // Rejected promise will be caught
      const produkt = await store.dispatch('products/findProdukt', { faid })
      next(`/katalog/${produkt.slug}`)
    } else {
      throw new Error('Faid invalid')
    }
  } catch (err) {
    next('/404')
  }
}

export async function routeKatalogOldwebSlugRedirect (to, from, next) {
  try {
    const oldwebSlug = to.params.oldweb_slug
    if (oldwebSlug) {
      // Rejected promise will be caught
      const produkt = await store.dispatch('products/findProdukt', { oldwebSlug })
      next(`/katalog/${produkt.slug}`)
    } else {
      throw new Error('Missing oldweb_slug')
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return routeNotFoundRedirect(to, from, next);
    } else {
      return routeUnavailableRedirect(to, from, next);
    }
  }
}

export async function routeNotFoundRedirect (to, from, next) {
  next(false)
  window.location = `/404?path=${encodeURIComponent(to.fullPath)}`;
}

export async function routeUnavailableRedirect (to, from, next) {
  next(false)
  window.location = `/503`;
}

export function routeResetUI (to, from, next) {
  store.commit('background/resetBackground')
  store.commit('navigation/resetTabs')
  if (store.getters['navigation/searchBarMenuActive']) {
    EventBus.$emit('routeResetUI')
    return next(false)
  }
  next()
}
