<template>
  <v-container fill-height>
    <v-layout fill-height>
      <v-card class="flex">
        <v-container :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="headline">Datenschutzerklärung</h3>
          </v-card-title>
          <v-card-text
            class="pt-0" style="word-wrap: break-word"
            v-html="privacyPolicy"></v-card-text>
        </v-container>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    metaInfo: {
      title: 'Datenschutzerklärung von marmelaedchen.net',
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    },
    computed: {
      ...mapGetters('legal', ['privacyPolicy'])
    }
  }
</script>
