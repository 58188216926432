import jwtDecode from 'jwt-decode'

export function getLocalSessionToken () {
  return localStorage.getItem('sessionToken')
}

export function setLocalSessionToken (sessionToken) {
  localStorage.setItem('sessionToken', sessionToken)
}

export function removeLocalSessionToken () {
  localStorage.removeItem('sessionToken')
}

export function isSessionTokenExpired (sessionToken) {
  return new Date().getTime() / 1000 > jwtDecode(sessionToken).exp
}

export function getSessionTokenEmail (sessionToken) {
  return jwtDecode(sessionToken).sub || ''
}

export function getSessionTokenRole (sessionToken) {
  return jwtDecode(sessionToken).role || 'user'
}

export default function getLocalAuthState () {
  const localSessionToken = getLocalSessionToken()
  var sessionToken = ''
  var accountEmail = ''
  if (localSessionToken) {
    accountEmail = getSessionTokenEmail(localSessionToken)
    if (!isSessionTokenExpired(localSessionToken)) {
      sessionToken = localSessionToken
    }
  }
  return { sessionToken, accountEmail }
}
