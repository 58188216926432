// https://stackoverflow.com/a/57828517
function translate (char)
{
    let diff;
    if (/[A-Z]/.test (char))
    {
        diff = "𝗔".codePointAt (0) - "A".codePointAt (0);
    }
    else
    {
        diff = "𝗮".codePointAt (0) - "a".codePointAt (0);
    }
    return String.fromCodePoint (char.codePointAt (0) + diff);
}

export default {
  methods: {
    formatPreisInt (preis) {
      return Number(preis / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
    },
    formatFuellmenge (preis) {
      if (preis.fuellMengeSet) {
        return preis.fuellMengeContainer
      } else {
        return `${preis.fuellMenge} ${preis.fuellMengeUnit}-${preis.fuellMengeContainer}`
      }
    },
    formatPreisVariant (preis) {
      return preis.fuellMengeVariant || this.formatFuellmenge(preis);
    },
    // AdminProdukt, ProductsItem, Bestellung
    formatFuellmengePreis (preis) {
      return `${this.formatFuellmenge(preis)} zu ${this.formatPreisInt(preis.preis)}`
    },
    // ProductsItem, Bestellung
    formatGrundpreis (preis, grundmenge) {
      if (this.isSetpreis(preis)) {
        return `k.A. / Setpreis`
      } else if (preis.fuellMenge) {
        const fuellMengeReal = preis.fuellMengeAbtropf || preis.fuellMenge;
        var grundpreis = (preis.preis / fuellMengeReal) * grundmenge
        return `${this.formatPreisInt(grundpreis)} / ${grundmenge} ${preis.fuellMengeUnit}`
      }
    },
    isSetpreis(preis) {
      return preis.fuellMengeSet && !preis.fuellMenge;
    },
    formatSkuPartial(partial) {
      switch (partial.kind) {
        case 'Variant': return `${partial.variantName}=${partial.variantValue}`
        case 'Slot': return partial.slotName
        case 'Option': return partial.optionName
        default: return partial.title
      }
    },
    formatSkuVariants(sku) {
      return (sku.partials || [])
        .filter(partial => partial.kind === 'Variant')
        .map(partial => partial.variantValue)
        .join(', ')
    },
    formatLieferumfang(sku) {
      return (sku.preis.lieferumfang || []).join(', ')
    },
    formatZutaten (zutaten, { anteil = true, details = true, common = true, unicodeAllergen = false, htmlAllergen = false } = {}) {
      return zutaten.filter(item => {
        if (!item.zutat.title) return false
        if (!common && item.zutat.common) return false
        return true
      }).map(item => {
        var zutatText = item.zutat.title
        if (anteil && item.anteil) {
          zutatText = `${zutatText} ${item.anteil} %`
        }
        if (details && item.zutat.details) {
          zutatText = `${zutatText} (${item.zutat.details})`
        }
        if (unicodeAllergen && item.zutat.allergen) {
          zutatText = zutatText.replace (/[A-Za-z]/g, translate);
        }
        if (htmlAllergen && item.zutat.allergen) {
          zutatText = `<strong>${zutatText}</strong>`
        }
        return zutatText
      }).join(', ')
    }
  }
}
