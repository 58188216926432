<template>
  <v-container fill-height>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-card-text class="text-xs-center">
            <p class="display-1">Eh eh eh</p>
            <p><img style="width: 250px" src="/img/kangaroo-chewing.gif"></p>
            <p class="subheading">Diese Seite gehört zum Admin-Bereich!</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    metaInfo: {
      title: '403 - Kangaroo slaps your fingers'
    }
  }
</script>