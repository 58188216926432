const state = {
  links: {
    // Main
    'home': {
      text: 'Startseite',
      to: '/',
      actionText: 'ML',
      actionTextClass: 'title ml-font font-weight-bold light--text',
      tabIcon: 'home'
    },
    'maerkte': {
      text: 'Märkte',
      to: '/maerkte',
      actionAvatar: 'calendar.png'
    },
    'produkte': {
      text: 'Produkte',
      to: '/katalog',
      actionAvatar: 'jar.png'
    },
    'hofleben': {
      text: 'Hofleben',
      to: '/hofleben',
      actionAvatar: 'bastet.png'
    },
    'hofladen': {
      text: 'Hofladen',
      // textLong: 'Öffnungszeiten & Anfahrt',
      to: '/hofladen',
      footerIcon: 'storefront',
      actionAvatar: 'shop.png',
      actionIcon: 'storefront',
      actionIconAttrs: {
        color: 'grey darken-3'
      }      
    },        
    'geschenke': {
      text: 'Geschenke',
      to: '/geschenke',
      actionAvatar: 'gift.png'
    },
    // Account
    'konto': {
      text: 'Konto',
      to: '/konto',
      actionIcon: 'account_circle',
      actionIconAttrs: {
        color: 'blue-grey',
        size: '28'
      }
    },
    'bestellungen': {
      text: 'Bestellungen',
      to: '/bestellungen',
      actionAvatar: 'icons8-cardboard-box-128.png'
    },
    'abmelden': {
      text: 'Abmelden',
      to: '/abmelden',
      actionIcon: 'power_settings_new',
      actionIconAttrs: {
        color: 'grey darken-3',
        size: '28'
      }
    },
    // Footer
    'fragen': {
      text: 'Fragen',
      textLong: 'Häufige Fragen',
      to: '/fragen',
      footerIcon: 'fa-question',
      footerIconSize: '18px',
      actionIcon: 'fa-question',
      actionIconAttrs: {
        color: 'grey darken-3',
        style: 'margin-left: 2px'
      }
    },
    'kontakt': {
      text: 'Kontakt',
      textLong: 'Kontaktformular',
      to: '/kontakt/email',
      footerIcon: 'fa-at',
      footerIconSize: '18px',
      actionIcon: 'fa-at',
      actionIconAttrs: {
        color: 'grey darken-3'
      }
    },
    'telefon_xs': {
      text: 'Anruf',
      href: 'tel://+495742700687',
      footerIcon: 'fa-mobile-alt',
      footerIconSize: '18px',
      footerContainerClass: ['hidden-sm-and-up']
    },
    'telefon_sm': {
      text: 'Telefon',
      to: '/kontakt/telefon',
      footerIcon: 'fa-phone',
      footerIconSize: '18px'
    },
    'anfahrt': {
      text: 'Anfahrt',
      href: 'https://www.google.com/maps/dir/?api=1&destination=Marmelädchen,+Inh.+Olga+Mesterheide&destination_place_id=ChIJx0OFI9AGukcRP6dSQCBs0Aw',
      footerIcon: 'directions',
      footerAriaLabel: 'Marmelädchen Hofladen'
    },
    'anfahrt_neu': {
      text: 'Anfahrt',
      to: '/hofladen#anfahrt',
      footerIcon: 'storefront'
    },    
    'impressum': {
      text: 'Impressum',
      to: '/impressum',
      footerIcon: 'fa-info',
      footerIconSize: '18px',
      actionIcon: 'fa-info',
      actionIconAttrs: {
        color: 'grey darken-3',
        style: 'margin-left: 6px'
      }
    }
  },
  customTabs: null,
  toolbarGradient: false,
  toolbarTabsColorsInverted: false,
  searchBarMenuActive: false
}

const getters = {
  menu: (state, getters, rootState, rootGetters) => ({
    itemsAccount: [
      state.links['konto'],
      state.links['bestellungen'],
      state.links['abmelden']
    ],
    itemsSeiten: [
      state.links['home'],
      state.links['maerkte'],
      state.links['produkte'],
      state.links['hofladen'],
    ].map(menuItem => ({
      ...menuItem,
      text: menuItem.textLong || menuItem.text
    })),
    itemsSeitenMore: [
      state.links['fragen'],
      state.links['kontakt'],
      state.links['impressum']
    ].map(menuItem => ({
      ...menuItem,
      text: menuItem.textLong || menuItem.text
    }))
  }),
  tabs: (state, getters) => {
    return state.customTabs
      ? state.customTabs
      : {
        items: [
          state.links['home'],
          state.links['maerkte'],
          state.links['produkte'],
          state.links['hofladen']
        ].map(menuItem => ({
          text: menuItem.text,
          to: menuItem.to,
          icon: menuItem.tabIcon
        }))
      }
  },
  footer: (state, getters) => ({
    items: [
      state.links['fragen'],
      state.links['kontakt'],
      state.links['telefon_sm'],
      state.links['anfahrt_neu'],
      state.links['impressum']
    ].map(footerItem => ({
      text: footerItem.text,
      to: footerItem.to,
      href: footerItem.href,
      icon: footerItem.footerIcon,
      iconSize: footerItem.footerIconSize,
      containerClass: footerItem.footerContainerClass,
      ariaLabel: footerItem.footerAriaLabel
    }))
  }),
  toolbarGradient: state => state.toolbarGradient,
  toolbarTabsColorsInverted: state => state.toolbarTabsColorsInverted,
  searchBarMenuActive: state => state.searchBarMenuActive
}

const mutations = {
  updateTabs (state, tabs) {
    state.customTabs = tabs
  },
  resetTabs (state) {
    state.customTabs = null
  },
  enableToolbarGradient (state, bool) {
    state.toolbarGradient = bool
  },
  invertToolbarTabsColors (state, bool) {
    state.toolbarTabsColorsInverted = bool
  },
  updateSearchBarMenuActive (state, bool) {
    state.searchBarMenuActive = bool
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
