<template>
  <v-card>
    <v-container class="py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly, 'px-2': $vuetify.breakpoint.smAndUp}"
      @click="$emit('news-click')">
      <v-subheader>Ankündigungen:</v-subheader>
      <v-card-text v-if="loading" class="pt-0">
        <content-placeholders>
          <content-placeholders-text :lines="3" />
        </content-placeholders>
      </v-card-text>
      <v-card-text
        v-else-if="neuigkeitenText"
        class="subheading text-xs-center pt-0"
        style="white-space: pre-line" v-html="neuigkeitenText" />
      <v-card-text v-else
        class="subheading text-xs-center pt-0">Herzlich Willkommen im Marmelädchen!</v-card-text>
      <v-alert v-if="siteNotice" v-bind="siteNotice.props">{{ siteNotice.text }}</v-alert>
    </v-container>
    <v-container class="py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly, 'px-2': $vuetify.breakpoint.smAndUp}">
      <v-subheader>Nächster Markt:</v-subheader>
      <template v-if="loading">
        <v-card-text class="pt-0">
          <content-placeholders>
            <content-placeholders-heading />
            <content-placeholders-text :lines="2" />
          </content-placeholders>
        </v-card-text>
      </template>
      <!-- https://forum.vuejs.org/t/v-for-on-templates/8359/3 -->
      <template v-else-if="upcoming.length > 0" v-for="(markt, index) in upcoming">
        <v-divider v-if="index > 0" class="mx-3" :key="`divider-${markt.market_id}`"></v-divider>
        <v-card-text :class="index === 0 ? 'pt-0' : 'pt-3'" :key="`info-${markt.market_id}`">          
          <h4 class="title">{{ markt.title }}</h4>
          <div class="my-1">
            <span class="font-weight-bold">{{ markt.place }}</span>
            <span v-if="markt.content" style="white-space: pre-wrap">&nbsp;&ndash; {{ markt.content }}</span>
          </div>          
          <div>
            <v-icon class="mr-1">event</v-icon>
            <span>{{ formatDatespan(markt.date_start, markt.date_end) }}</span> &middot;
            <span class="grey--text caption text--darken-3">{{ formatDayspan(markt.date_start, markt.date_end) }}</span><br>
            <span class="grey--text caption text--darken-3">{{ formatDays(daysUntilNext) }}</span>
          </div>
          <div v-if="!!markt.cancel_flag">
            <v-alert :value="true" type="warning" outline class="d-inline-flex py-2">
              <span class="grey--text text--darken-3">
                <span class="font-weight-bold">Teilnahme abgesagt</span>: 
                {{ markt.cancel_reason}}
              </span>
            </v-alert>
          </div>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="pt-0 subheading">
          Aktuell kein Markt geplant.<br>Sie können sich gerne zum <router-link to="/maerkte#newsletter">Markt-Newsletter</router-link> anmelden.
        </v-card-text>
      </template>
    </v-container>
    <slot name="card-footer"></slot>
  </v-card>
</template>

<script>
  import marktHelperMixin from '@/components/mixins/markt-helper'

  import * as moment from 'moment'
  moment.locale('de')

  export default {
    mixins: [marktHelperMixin],
    props: {
      loading: { type: Boolean, default: true },
      next: { type: Array, default: () => [] },
      last: { type: Array, default: () => [] },
      current: { type: Array, default: () => [] },
      neuigkeitenText: {type: String},
      siteNotice: {type: Object, default: null}
    },
    computed: {
      // Current if available or next
      upcoming () {
        if (this.current.length > 0) {
          const currentCanceled = this.current.filter(markt => !!markt.cancel_flag)
          if (currentCanceled.length === this.current.length) {
            return [...this.next, ...this.current]
          } else {
            return this.current
          }
        } else {
          return this.next
        }
      },
      calendarNext () {
        // Edge case: Not responsive on day change
        if (this.current.length > 0) {
          // Return current day of month
          return moment().format('D')
        } else if (this.next.length > 0) {
          const earliest = this.next[this.next.length - 1]
          return moment(earliest.date_start).format('D')
        } else {
          return null
        }
      },
      daysUntilNext () {
        // Presence indicates that date_start <= Date.now <= date_end + 1d
        if (this.current.length > 0) {
          return 0
        } else if (this.next.length > 0) {
          const earliest = this.next[this.next.length - 1]
          // date_start and date_end is midnight 00:00 local time
          return Math.ceil(moment(earliest.date_start).diff(moment(), 'days', true))
        } else {
          return null
        }
      }
    }
  }
</script>