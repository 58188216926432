import axios from 'axios'

const state = {
  form: {
    email: null,
    name: null,
    subject: null, // Not actively used
    message: null,
    sendCopy: true
  }
}

const getters = {
  formEmail: state => state.form.email,
  formName: state => state.form.name,
  formSubject: state => state.form.subject,
  formMessage: state => state.form.message,
  formSendCopy: state => state.form.sendCopy
}

const mutations = {
  updateFormEmail (state, formEmail) {
    state.form.email = formEmail
  },
  updateFormName (state, formName) {
    state.form.name = formName
  },
  updateFormSubject (state, formSubject) {
    state.form.subject = formSubject
  },
  updateFormMessage (state, formMessage) {
    state.form.message = formMessage
  },
  updateFormSendCopy (state, formSendCopy) {
    state.form.sendCopy = formSendCopy
  }
}

const actions = {
  resetForm: ({ state, getters, commit, dispatch, rootState }) => {
    commit('updateFormEmail', null)
    commit('updateFormName', null)
    commit('updateFormSubject', null)
    commit('updateFormMessage', null)
    commit('updateFormSendCopy', true)
  },
  sendMessage: ({ state, getters, commit, dispatch, rootState }, { email, name, message, sendCopy, verified }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/${verified ? 'nachrichten/verified' : 'nachrichten'}`,
        method: 'POST',
        data: { email, name, message, sendCopy },
        // Send auth if available
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
