import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import UserKonto from '@/components/UserKonto'
import UserBestellungen from '@/components/UserBestellungen'
import UserBestellungDetail from '@/components/UserBestellungDetail'
//import ProductsMain from '@/components/ProductsMain'
//import ProductsCategory from '@/components/ProductsCategory'
import ProductsList from '@/components/ProductsList'
import ProductDetails from '@/components/ProductDetails'
import EventsMain from '@/components/EventsMain'
import HofladenMain from '@/components/HofladenMain'
import Bestellung from '@/components/Bestellung'
import Login from '@/components/special/Login'
import Logout from '@/components/special/Logout'
import FAQ from '@/components/special/FAQ'
import ContactForm from '@/components/special/ContactForm'
import ContactPhone from '@/components/special/ContactPhone'
import Imprint from '@/components/special/Imprint'
import PrivacyPolicy from '@/components/special/PrivacyPolicy'
import Forbidden from '@/components/special/Forbidden'
import NotFound from '@/components/special/NotFound'
import ServiceUnavailable from '@/components/special/ServiceUnavailable'
//import Spielwiese from '@/components/special/Spielwiese'
import GlasUpdate from '@/components/special/GlasUpdate'

/* https://router.vuejs.org/guide/advanced/lazy-loading.html
import AdminProduct from '@/components/AdminProduct'
import AdminCategories from '@/components/AdminCategories'
import AdminEtikettenDruck from '@/components/AdminEtikettenDruck'
import AdminEtiketten from '@/components/AdminEtiketten'
import AdminNaehrwerte from '@/components/AdminNaehrwerte'
import AdminNaehrwerteRezeptur from '@/components/AdminNaehrwerteRezeptur'
import AdminNaehrwerteRezepturDetails from '@/components/AdminNaehrwerteRezepturDetails'
import AdminNaehrwerteRezepturEdit from '@/components/AdminNaehrwerteRezepturEdit'
import AdminNaehrwerteLebensmittel from '@/components/AdminNaehrwerteLebensmittel'
import AdminNaehrwerteLebensmittelDetails from '@/components/AdminNaehrwerteLebensmittelDetails'
//import AdminBestellungen from '@/components/AdminBestellungen'
*/

import {
  routeAuthorize,
  routeQRCodeRedirect,
  routeKatalogFaidRedirect,
  routeKatalogOldwebSlugRedirect,
  routeNotFoundRedirect,  
  routeResetUI
} from './navguards'

const NProgress = require('nprogress/nprogress.js')
NProgress.configure({ showSpinner: false })
require('nprogress/nprogress.css')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash /*, offset: { x: 0, y: 10 } */ }
    } else if (savedPosition) {
      return savedPosition
    } else if (to.fullPath !== from.fullPath) {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    { path: '/', component: Home },
    //{ path: '/produkte', component: ProductsMain },
    //{ path: '/produkte/:category+', component: ProductsCategory },
    { path: '/maerkte', component: EventsMain },
    { path: '/hofladen', component: HofladenMain },
    { path: '/qrcode/:type/:lfdNr', beforeEnter: routeQRCodeRedirect },
    { path: '/katalog', component: ProductsList },
    { path: '/katalog/verfuegbarkeit',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminProductAvailability'),
      meta: { requiresAuth: 'admin' }
    },    
    { path: '/katalog/anlegen',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminProduct'),
      meta: { requiresAuth: 'admin' }
    },
    // For quick access based on old id
    { path: '/katalog/faid/:faid', beforeEnter: routeKatalogFaidRedirect },
    { path: '/katalog/oldweb/:oldweb_slug', beforeEnter: routeKatalogOldwebSlugRedirect },
    { path: '/katalog/:slug', component: ProductDetails },
    { path: '/katalog/:slug/bearbeiten',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminProduct'),
      meta: { requiresAuth: 'admin' } 
    },
    { path: '/katalog/:slug/drucken',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminEtikettenDruck'),
      meta: { requiresAuth: 'admin' }
    },
    { path: '/bestellung', name: 'bestellung', component: Bestellung },
    { path: '/bestellungen', component: UserBestellungen, meta: { requiresAuth: 'user' } },
    { path: '/bestellungen/:bestellungNr', component: UserBestellungDetail, meta: { requiresAuth: 'user' } },
    { path: '/konto', component: UserKonto, meta: { requiresAuth: 'user' } },
    { path: '/etiketten',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminEtiketten'),
      meta: { requiresAuth: 'admin' }
    },
    { path: '/etiketten/drucken',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminEtikettenDruck'),
      meta: { requiresAuth: 'admin' }
    },
    { path: '/etiketten/:jobId',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminEtikettenDruck'),
      meta: { requiresAuth: 'admin' }
    },
    { path: "/naehrwerte",
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerte'),
      meta: { requiresAuth: 'admin' },
      children: [
        { path: "rezeptur",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteRezeptur')
        },
        { path: "rezeptur/anlegen",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteRezepturEdit')
        },
        { path: "rezeptur/:rezepturId",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteRezepturDetails')
        },
        { path: "rezeptur/:rezepturId/bearbeiten",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteRezepturEdit')
        },
        { path: "lebensmittel",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteLebensmittel')
        },
        { path: "lebensmittel/anlegen",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteLebensmittelDetails')
        },
        { path: "lebensmittel/:lebensmittelId",
          component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminNaehrwerteLebensmittelDetails')
        }
      ]
    },
    { path: '/kategorien',
      component: () => import(/* webpackChunkName: "admin" */ '@/components/AdminCategories'),
      meta: { requiresAuth: 'admin' }
    },
    //{ path: '/spielwiese', component: Spielwiese },
    { path: '/neue-glaeser', component: GlasUpdate },
    { path: '/anmelden/:authToken?', component: Login },
    { path: '/abmelden', component: Logout },
    { path: '/fragen', component: FAQ },
    { path: '/kontakt/email', component: ContactForm },
    { path: '/kontakt/telefon', component: ContactPhone },
    { path: '/impressum', component: Imprint },
    { path: '/impressum/datenschutzerklaerung', component: PrivacyPolicy },
    { path: '/403', component: Forbidden },
    { path: '/404', /* alias: '*', */ component: NotFound },
    { path: '/503', component: ServiceUnavailable },
    { path: '*', beforeEnter: routeNotFoundRedirect }
  ]
})

router.beforeEach(routeAuthorize)
router.beforeEach(routeResetUI)
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
