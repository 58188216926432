<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    max-width="500">
    <v-card>
      <v-card-title>
        <h3>{{ label ? label : 'Adresse' }} ändern</h3>
      </v-card-title>
      <v-card-text class="pt-0">
        <slot name="prepend-form"></slot>        
        <!-- FIXME: autocomplete shipping vs. billing -->
        <v-form ref="formAddressRef" @submit.prevent>
          <v-text-field
            v-model.trim="formAddress.fullname"
            label="Vor- und Nachname"
            :rules="[v => !!v || 'Kein Name angegeben']"
            hide-details
            name="name"
            autocomplete="name"
            :disabled="disabled || loading"></v-text-field>
          <v-text-field
            v-model.trim="formAddress.company"
            label="Zusatz / Firma"
            hide-details class="mt-3"
            :disabled="disabled || loading"></v-text-field>
          <v-text-field
            v-model.trim="formAddress.addressLine1"
            label="Straße und Hausnr."
            :rules="[v => !!v || 'Keine Straße angegeben']"
            hide-details class="mt-3"
            name="address"
            autocomplete="street-address"
            :disabled="disabled || loading"></v-text-field>
          <v-text-field
            v-model.trim="formAddress.addressLine2"
            label="2. Adresszeile"
            hide-details class="mt-3"           
            :disabled="disabled || loading"></v-text-field>
          <v-layout>
            <v-flex xs4 class="pr-1">
              <v-text-field
                v-model.trim="formAddress.zipcode"
                label="PLZ"
                :rules="[v => !!v || 'Keine PLZ angegeben']"
                hide-details class="mt-3"
                name="zip"
                autocomplete="postal-code"                      
                :disabled="disabled || loading"></v-text-field>
            </v-flex>
            <v-flex xs8 class="pl-1">
              <v-text-field
                v-model.trim="formAddress.city"
                label="Stadt"
                :rules="[v => !!v || 'Keine Stadt angegeben']"
                hide-details class="mt-3"
                name="city"
                autocomplete="address-level2"                
                :disabled="disabled || loading"></v-text-field>
            </v-flex>
          </v-layout>
          <v-combobox
            v-model="formAddress.country"
            label="Land"
            :items="$store.getters['user/addressCountries']"
            hide-details class="my-3"
            name="country"
            autocomplete="country"                
            :disabled="disabled || loading"></v-combobox>          
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="deletable"
          @click.native="$emit('delete')"
          :disabled="loading"          
          small flat icon color="error"><v-icon size="18">far fa-trash-alt</v-icon></v-btn>
        <v-btn 
          @click.native="$emit('input', false)"
          small flat >Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click.native="formSave"
          :disabled="loading"
          color="primary" small flat>{{ saveText || 'Speichern' }}</v-btn>              
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: [
      'value',
      'label',
      'disabled',
      'loading',
      'deletable',
      'address',
      'returnObject',
      'saveText'
    ],
    data () {
      return {
        formAddress: {
          fullname: null,
          company: null,
          addressLine1: null,
          addressLine2: null,
          zipcode: null,
          city: null,
          country: null,
          // Be sure there are no objects or arrays when passing props to data
          ...this.address
        }
      }
    },
    watch: {
      address (address) {
        this.$refs.formAddressRef.reset()
        this.$nextTick(() => {
          this.formAddress = {
            fullname: null,
            company: null,
            addressLine1: null,
            addressLine2: null,
            zipcode: null,
            city: null,
            country: null,
            ...address
          }
        })
      }
    },
    methods: {
      formSave () {
        if (this.disabled && !this.loading) {
          this.$emit('save')
        } else if (this.$refs.formAddressRef.validate() && !this.loading) {
          this.$emit('update:returnObject', { ...this.formAddress })
          this.$emit('save')
        }
      },
      getData () {
        return {
          ...this.formAddress
        }
      }
    }
  }
</script>