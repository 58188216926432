<template>
  <v-container>
    <v-layout column>
      <ml-banner></ml-banner>

      <ml-news
        :loading="maerkteRecent === null"
        v-bind="maerkteRecent || {}"
        :neuigkeitenText="neuigkeitenText"
        :siteNotice="siteNotice"
        class="mt-3"
        @news-click="newsClick">
      </ml-news>

      <v-container class="flex pa-0 mt-3">
        <ml-empfehlung  
          :loading="produkteEmpfehlung === null"
          :produkteEmpfehlung="produkteEmpfehlung != null ? produkteEmpfehlung : []">
        </ml-empfehlung>
      </v-container>

      <v-container class="flex pa-0 mt-3">
        <ml-social-media></ml-social-media>
      </v-container>
    </v-layout>
    <v-dialog
      v-model="dialogNeuigkeiten"
      :transition="false"
      max-width="500"
      lazy
      scrollable>       
      <v-card>
        <v-form ref="formNeuigkeitenRef" @submit.prevent="formNeuigkeitenSend">
        <v-card-title class="subheading">
          Neuigkeiten bearbeiten
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea v-model="editForm.neuigkeitenText" auto-grow :rows="5"
            label="Text"></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn
            small flat @click="dialogNeuigkeiten = false">Abbrechen</v-btn>
          <v-btn 
            small flat color="primary" type="submit">Speichern</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  //import HomeBanner from '@/components/HomeBanner'
  import HomeNews from '@/components/HomeNews'
  import HomeEmpfehlung from '@/components/HomeEmpfehlung'
  import HomeSocialMedia from '@/components/HomeSocialMedia'

  export default {
    data: () => ({
      maerkteRecent: null,
      produkteEmpfehlung: null,
      neuigkeitenText: null,
      siteNotice: null,
      editForm: {
        neuigkeitenText: null
      },
      dialogNeuigkeiten: false,
    }),
    metaInfo: {
      meta: [
        { name: 'description', content: 'Hausgemachte Marmelade und Gelee aus dem Hofladen in Preußisch Oldendorf' }
      ]
    },
    computed: {
      ...mapGetters('auth', ['isAuthenticated', 'isAdmin']),      
    },
    created () {
      this.getData()
    },
    watch: {
      dialogNeuigkeiten (val, oldVal) {
        if (val) {
          this.editForm.neuigkeitenText = this.neuigkeitenText
        }
      }
    },
    methods: {
      getData () {
        Promise.all([
          this.queryMaerkteRecent().then(({ maerkte, neuigkeitenText = null, siteNotice = null }) => {
            this.maerkteRecent = maerkte
            this.siteNotice = siteNotice
            this.neuigkeitenText = neuigkeitenText
          }),
          this.queryProdukteEmpfehlung().then(produkte => {
            this.produkteEmpfehlung = produkte
          })
        ])
      },
      async formNeuigkeitenSend () {
        await this.updateNeuigkeiten(this.editForm.neuigkeitenText);
        this.getData();
        this.dialogNeuigkeiten = false;
      },
      newsClick () {
        if (this.isAdmin) {
          this.dialogNeuigkeiten = true
        }
      },
      ...mapActions('events', ['queryMaerkteRecent', 'updateNeuigkeiten']),
      ...mapActions('products', ['queryProdukteEmpfehlung'])      
    },
    components: {
      'ml-banner': () => import(/* webpackChunkName: "banner" */ '@/components/HomeBanner'),
      'ml-news': HomeNews,
      'ml-empfehlung': HomeEmpfehlung,
      'ml-social-media': HomeSocialMedia
    }
  }
</script>

<style>
  .title {
    line-height: 1.2 !important;
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}  
</style>