module.exports = {
  API_URL:'https://api.marmelaedchen.net',
  API_MAX_AGE: 30,
  // For canonical links on product page
  SITE_URL: 'https://www.marmelaedchen.net',
  PRINTER_URL: 'https://pi.mesterheide.net:8443',
  INSTAGRAM_CONNECT_URL: 'https://api.marmelaedchen.net/instagram/connect',
  SHIPPING_LOW: 430, // New: 490
  SHIPPING_HIGH: 630 // New: 590
}
