<template>
  <v-container fill-height>
    <v-layout fill-height>
      <v-card class="flex">
        <v-card-text class="px-2 pt-2 pb-0">
          <v-carousel height="auto" hide-delimiters class="elevation-0" :cycle="true" :interval="6000">
            <v-carousel-item  src="/img/marmelaedchen/marmelaedchen-regal.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-regal_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-einfahrt.jpg" min-height="200" position="75%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-einfahrt_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-eingang.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-eingang_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-enten.jpg" min-height="200" position="40%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-enten_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-gaense.jpg" min-height="200" position="35%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-gaense_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-geschenk-rose.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-geschenk-rose_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-fahrrad.jpg" min-height="200" position="right"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-fahrrad_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-kasse-cl.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-kasse-cl_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-klucke.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-klucke_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-korb.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-korb_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-mittellandkanal.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-mittellandkanal_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-l557.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-l557_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-pflaumen.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-pflaumen_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-essig.jpg" min-height="200" position="55%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-essig_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-presse.jpg" min-height="200" position="40%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-presse_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-pupi.jpg" min-height="200" position="75%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-pupi_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-böhnchen.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-böhnchen_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-schmetterling.jpg" min-height="200" position="center"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-schmetterling_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-bild.jpg" min-height="200" position="25%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-bild_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-teich.jpg" min-height="200" position="45%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-teich_xs.jpg')"></v-carousel-item>
            <v-carousel-item src="/img/marmelaedchen/marmelaedchen-tierchen.jpg" min-height="200" position="75%"
              :lazy-src="require('@/../public/img/marmelaedchen/marmelaedchen-tierchen_xs.jpg')"></v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title">Hofladen</h3>          
          </v-card-title>
          <v-card-text class="subheading py-0">            
          <p>Besuchen Sie unseren Hofladen in Getmold am Mittellandkanal:</p>
          <address class="font-weight-bold">
          Neuenfelder Str. 4<br>
          32361 Preußisch Oldendorf-Getmold
          </address>
          </v-card-text>
          <v-subheader>Öffnungszeiten</v-subheader>
          <v-card-text class="py-0">
            <p>Da wir keine festen Öffnungszeiten haben, bitten wir Sie, Ihren Besuch vorher telefonisch anzumelden:</p>
            <strong>Telefon: 05742 700687</strong>
          </v-card-text>
          <v-subheader id="anfahrt">Anfahrt</v-subheader>
          <v-card-text class="pt-0">
            <v-responsive :aspect-ratio="mapAspectRatio">
              <l-map :zoom="zoom" :center="center" :options=" {dragging}">
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker :lat-lng="center" />
              </l-map>
            </v-responsive>
            <div class="text-xs-center text-sm-left mt-3">
              <v-btn small color="info" 
                href="https://www.google.com/maps/dir/?api=1&destination=Marmelädchen,+Inh.+Olga+Mesterheide&destination_place_id=ChIJx0OFI9AGukcRP6dSQCBs0Aw">
                <v-icon left>directions</v-icon>Google Maps
              </v-btn>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import {LMap, LTileLayer, LMarker } from 'vue2-leaflet';

export default {
  data: () => ({
    zoom: 13,
    center: ['52.3446881', '8.493700163315067'],
    url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    dragging: false,
  }),
  metaInfo: {
    title: 'Hofladen in Preußisch Oldendorf-Getmold'
  },
  beforeMount() {
    this.dragging = !window.L.Browser.mobile;
  },
  computed: {
    mapAspectRatio () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 1
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 1.5
      } else {
        return 2
      }
    }
  },
  components: {
    LMap,
    LTileLayer,
    LMarker
  }
}
</script>

<style>
  .leaflet-pane {
    z-index: 1 !important;
  }
  .leaflet-top, .leaflet-bottom {
    z-index: 2 !important;
  }
</style>