<template>
  <v-toolbar
    fixed
    app
    clipped-left
    :color="toolbarGradient ? '' : 'grey darken-3'"
    :style="toolbarGradient ? `background: linear-gradient(to bottom, ${colors.grey.darken3}, ${$vuetify.theme.secondary})` : ''"
    :height="toolbarHeight"
    :extension-height="tabsHeight"
    id="ml-toolbar">
    <v-toolbar-side-icon dark v-show="!searchBar" @click.stop="$emit('sideIconClick', $event)"></v-toolbar-side-icon>
    <v-toolbar-title v-show="!searchBar" 
      class="display-1 ml-font font-weight-bold white--text"
      style="cursor: pointer;"
      :style="$vuetify.breakpoint.xsOnly ? 'font-size: 32px !important;' : ''">
      <router-link to="/" tag="span" role="button">{{ title }}</router-link>
    </v-toolbar-title>
    <v-spacer v-show="!searchBar"></v-spacer>
    <v-flex v-show="searchBar || $vuetify.breakpoint.mdAndUp" :md5="!searchBar"
      id="ml-search-container" style="position: relative">
      <v-autocomplete
        ref="searchInputRef"
        @change="goToProdukt"
        @focus="produkteSelection = null"
        v-model="produkteSelection"
        :search-input.sync="produkteSearch"
        :loading="produkteSearchLoading"
        :items="produkteList"
        item-text="title"
        item-value="slug"
        attach="#ml-search-container"
        flat
        dark
        solo-inverted
        no-filter
        hide-no-data
        label="Suche">
        <template slot="append-outer">
          <v-icon v-if="searchBar" @click.prevent="searchBar = false">clear</v-icon>
        </template>
        <template slot="no-data">
          <v-card flat class="subheading">
            <v-card-text class="font-weight-bold">
              Suche nach Produkte
            </v-card-text>
            <v-card-text class="pt-0">
              Beispiele: <code>erdbeer</code>, <code>holunder apfel</code>
            </v-card-text>
          </v-card>
        </template>        
        <template slot="item" slot-scope="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-tile-content v-text="data.item"></v-list-tile-content>
          </template>
          <template v-else>
            <v-list-tile-avatar>
              <v-img v-if="!!data.item.images.length"
                :src="data.item.images[0].urls.thumb" contain></v-img>
              <v-img v-else :src="fallbackImageSrc"></v-img>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>
                <span class="font-weight-bold">{{ data.item.title }}</span>
              </v-list-tile-title>
              <v-list-tile-sub-title
                v-if="data.item.zutaten.length">
                {{ formatZutaten(data.item.zutaten, { anteil: false, details: false, common: false})}}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-spacer v-show="!searchBar"></v-spacer>
    <v-btn 
      v-show="!searchBar" @click="searchBar = !searchBar"
      icon dark medium class="hidden-md-and-up">
      <v-icon medium>search</v-icon>
      <!--<v-avatar size="32">
        <img :src="require('@/assets/icons8-detective-128.png')">
      </v-avatar>-->
    </v-btn>
    <!-- https://vuejs.org/v2/guide/components-slots.html#Scoped-Slots -->
    <ml-cart v-show="!searchBar">
      <template slot-scope="{ count, disabled }">
        <v-btn class="ml-cart-btn"
          :icon="$vuetify.breakpoint.smAndDown"
          :disabled="disabled"
          dark medium flat>
          <v-badge color="grey lighten-4" :value="!!count">
            <span class="hidden-sm-and-down">Warenkorb</span>               
            <span class="grey--text text--darken-3" slot="badge">{{ count }}</span>
            <v-avatar size="32" :style="{filter: disabled ? 'grayscale(100%)' : 'none'}">
              <!--<img :src="require('@/assets/icons8-shopping-basket-128.png')">-->
              <img :src="require('@/assets/icons8-shopping-bag-128_white.png')">
            </v-avatar>         
          </v-badge>
        </v-btn>
      </template>
    </ml-cart>
    <!-- mobile-break-point affects show-arrows -->
    <v-tabs v-if="!!navTabs" slot="extension" 
      :hide-slider="hideSlider"
      mobile-break-point="600"
      :fixed-tabs="$vuetify.breakpoint.smAndUp"      
      centered
      :height="tabsHeight"
      :color="toolbarGradient ? 'transparent' : 'secondary'"
      :dark="toolbarTabsColorsInverted">
      <!--
        <v-tab v-if="navTabs.upTo" :to="navTabs.upTo" class="tabs__up"><v-icon>arrow_upward</v-icon></v-tab>
      -->
      <!-- resetKatalogInputs in beforeTabChange -->
      <v-tab v-for="item in navTabs.items" :key="item.text" :to="item.to"
        @click.capture="beforeTabChange(item)"
        active-class="v-tabs__item--active theme--dark white--text">
        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
        <span v-else>{{ item.text }}</span>
      </v-tab>
    </v-tabs>    
  </v-toolbar>
</template>

<script>
  // Helpers
  import colors from 'vuetify/es5/util/colors'

  import { mapGetters, mapActions, mapMutations } from 'vuex'

  import EventBus from '@/router/event-bus'

  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import CartMenu from './CartMenu.vue'

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      colors,
      searchBar: null,
      produkteSearch: null,
      produkteSearchLoading: false,
      produkteSelection: null,
      produkteList: [],
      title: 'Marmelädchen',
      fallbackImageSrc: require('@/assets/jar.png')
    }),
    watch: {
      produkteSearch (val) {
        if (!val) {
          this.produkteList = []
        } else {
          this.produkteSearchLoading = true
          // Order by produkttyp
          this.searchProdukte(val).then(produkte => {
            const produkttypenSearch = [...new Set(produkte.map(produkt => produkt.produkttyp))]
            const produkteSorted = produkte.slice(0).sort((p1, p2) => {
              const p1ProdukttypIndex = produkttypenSearch.indexOf(p1.produkttyp)
              const p2ProdukttypIndex = produkttypenSearch.indexOf(p2.produkttyp)
              const produkttypCompare = p1ProdukttypIndex - p2ProdukttypIndex
              const originalCompare = produkte.indexOf(p1) - produkte.indexOf(p2)
              return produkttypCompare !== 0
                ? produkttypCompare
                : originalCompare
            })
            const produkteListHydrated = []
            produkteSorted.forEach((produkt, index, produkte) => {
              if (index === 0 || produkte[index - 1].produkttyp !== produkt.produkttyp) {
                produkteListHydrated.push({ header: this.produkttypFriendly(produkt.produkttyp) })
              }
              produkteListHydrated.push(produkt)
            })
            this.produkteList = produkteListHydrated
          }).finally(() => {
            this.produkteSearchLoading = false
          })
        }
      }
    },
    methods: {
      goToProdukt (slug) {
        if (slug !== null) {
          // Indicate search bar is already closed to fool navguard
          this.closeSearchBar()
          this.$nextTick(() => {
            this.$router.push(`/katalog/${slug}`)
          })
        }
      },
      beforeTabChange (navItem) {
        if (navItem.to.includes('katalog')) {
          this.resetKatalogInputs()
        }
      },
      closeSearchBar () {
        this.$refs.searchInputRef && this.$refs.searchInputRef.blur()
        this.searchBar = false
      },
      ...mapActions('products', ['searchProdukte', 'resetKatalogInputs']),
      ...mapMutations('navigation', ['updateSearchBarMenuActive'])
    },
    mounted () {
      // Watch searchBar isMenuActive and update store
      // Refs are not watchable on created
      this.$watch(() => this.$refs.searchInputRef.isMenuActive, val => {
        this.updateSearchBarMenuActive(val)
      })
      // React to route changes emitted by router navguards
      EventBus.$on('routeResetUI', this.closeSearchBar)
    },
    computed: {
      ...mapGetters('navigation', {
        navTabs: 'tabs',
        toolbarGradient: 'toolbarGradient',
        toolbarTabsColorsInverted: 'toolbarTabsColorsInverted',
        searchBarMenuActive: 'searchBarMenuActive'
      }),
      ...mapGetters('products', ['produkttypFriendly']),
      hideSlider () {
        return !this.navTabs.items.find(item => item.to === this.$route.path)
      },
      toolbarHeight () {
        return 56
      },
      tabsHeight () {
        return 48
      }
    },
    components: {
      'ml-cart': CartMenu
    }
  }
</script>

<style>
  #ml-toolbar .v-input__slot {
    /* Search input margins workaround */
    margin-bottom: 0px !important;
  }

  #ml-toolbar .v-toolbar__extension {
    /* Toolbar horizontal padding workaround */    
    padding-left: 0px;
    padding-right: 0px;
  }

  .v-tabs__item--active.theme--dark .v-icon {
    color: #fff;
  }

  #ml-toolbar .tabs__up {
    min-width: auto !important;
    margin-left: 0px !important;
    margin-right: auto !important;
  }

  .ml-cart-btn .v-badge__badge {
    top: 50%;
    transform: translate(0px, -100%);
  }
</style>
