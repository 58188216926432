<template>
  <!-- grid-list -->
  <v-layout wrap style="margin: -8px">      
    <v-flex v-for="produkt in produkteEmpfehlung" :key="produkt._id"
      class="pa-2" xs12 sm6 lg4>
      <v-card :flat="flat">
        <v-container class="py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly, 'px-2': $vuetify.breakpoint.smAndUp}">
          <v-card-title>
            <v-layout column>
              <div class="caption">
                <span v-if="produkt.empfehlungDate"
                  class="grey--text text--darken-1"
                  style="float:right">
                  {{ formatDatespan(produkt.empfehlungDate, produkt.empfehlungDate) }}</span>
                <span class="font-weight-bold">Empfehlung</span>
              </div>
              <h3 class="title pt-1">{{ produkt.title }}</h3>
              <div
                v-if="produkt.empfehlungText"
                class="grey--text text--darken-1 pt-2" style="white-space: pre-wrap">{{ produkt.empfehlungText }}
              </div>            
            </v-layout>
          </v-card-title>
          <v-card-text class="py-0">
            <v-layout v-if="produkt.images.length > 0" justify-center>
              <transition name="flex-transition">
                <v-flex v-if="expanded[produkt._id] || !produkt.beschreibung" class="mb-2">
                  <v-img
                    @click.prevent="toggleExpanded(produkt._id)"
                    :lazy-src="produkt.images[0].urls.small"
                    :src="produkt.images[0].urls.medium"
                    :aspect-ratio="0.75"></v-img>            
                </v-flex>
              </transition>
            </v-layout>
            <v-layout>
              <transition name="flex-transition">            
                <v-flex v-if="produkt.images.length > 0 && !expanded[produkt._id] && produkt.beschreibung" 
                  xs4 class="mr-2" style="flex-shrink: 0">
                  <v-img              
                    @click.prevent="toggleExpanded(produkt._id)"
                    :lazy-src="produkt.images[0].urls.thumb"
                    :src="produkt.images[0].urls.small"
                    :aspect-ratio="0.75"></v-img>
                </v-flex>
              </transition>
              <v-flex>
                {{ produkt.beschreibung }}
              </v-flex>          
            </v-layout>
            <template v-if="produkt.skus.length">
              <div class="caption mt-2">
                {{ formatFuellmengePreis(produkt.skus[0].preis) }}
              </div>
              <div v-if="!isSetpreis(produkt.skus[0].preis)" class="grey--text text--darken-1 caption">
                Grundpreis: {{ formatGrundpreis(produkt.skus[0].preis, 100) }}
              </div>
              <div v-if="formatLieferumfang(produkt.skus[0])" class="grey--text text--darken-1 caption">
                inkl. {{ formatLieferumfang(produkt.skus[0]) }}
              </div>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small flat :to="`/katalog/${produkt.slug}`">Produktseite</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-flex>
    <v-flex v-if="loading" class="pa-2" xs12 sm6 lg4 >
      <v-card>
        <v-container class="py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly, 'px-2': $vuetify.breakpoint.smAndUp}">
          <v-card-title>
            <v-layout column>
              <div class="caption font-weight-bold">Empfehlung</div>
              <h3 class="title pt-1">Aktuelle Empfehlung</h3>
            </v-layout>
          </v-card-title>        
          <v-card-text class="pt-0">
            <content-placeholders>
              <content-placeholders-text :lines="1" />
              <content-placeholders-heading :img="true" />
              <content-placeholders-text :lines="2" />
            </content-placeholders>
          </v-card-text>    
        </v-container>
      </v-card>
    </v-flex>
    <v-flex class="pa-2" xs12 sm6 lg4>
      <v-card :flat="flat">
        <v-container class="py-0" :class="{'px-0': $vuetify.breakpoint.xsOnly, 'px-2': $vuetify.breakpoint.smAndUp}">
          <v-card-title>
            <v-layout column>
              <div class="caption font-weight-bold">Geschenke</div>
              <h3 class="title pt-1">Marmelädchen-Geschenkset</h3>
              <marquee-text class="grey--text text--darken-1 pt-2" :duration="10">
                <span class="mr-1">Wieder 👏 erhältlich 👏 mit 👏 freier 👏 Produktwahl! 🎉</span>
              </marquee-text>
            </v-layout>
          </v-card-title>
          <v-card-text class="py-0">
            <v-img src="/img/produkte/geschenkkorb.jpg"></v-img>
            <div class="mt-2">Ob klassisch, exklusiv oder ihr eigenes Unikat, unser Geschenkset ist immer eine gute Wahl für alle Marmeladen-Liebhaber.</div>
            <div class="caption mt-2">Setpreis: 30 € zzgl. Versand</div>
            <div class="caption grey--text text--darken-1">Inhalt variiert, z.B. 1 x Sirup 500 ml oder Likör 250 ml, 1 x Fruchtaufstrich 340 g-Glas, 3 x Fruchtaufstrich 225 g-Glas</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small flat to="/katalog/marmelade-geschenk">Zum Geschenkset</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import marktHelperMixin from '@/components/mixins/markt-helper'

  import MarqueeText from 'vue-marquee-text-component'

  import * as moment from 'moment'
  moment.locale('de')

  export default {
    mixins: [produktHelperMixin, marktHelperMixin],
    props: {
      loading: { type: Boolean, default: true },
      flat: { type: Boolean, default: false },
      produkteEmpfehlung: { type: Array, default: () => [] }
    },
    data: () => ({
      expanded: {}
    }),
    computed: {
    },
    methods: {
      toggleExpanded (produktId) {
        this.$set(this.expanded, produktId, !this.expanded[produktId])
      }
    },
    components: {
      MarqueeText
    }
  }
</script>

<style>
  .flex-transition-enter-active, .flex-transition-leave-active {
    transition: all .2s;
  }
  .flex-transition-enter, .flex-transition-leave-to {
    flex-basis: 0% !important;
    max-width: 0% !important;
    margin: 0;
  }

</style>