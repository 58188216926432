<template>
  <v-container fill-height>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-card-text class="text-xs-center">
            <p class="display-1">Ooops...</p>
            <p><v-avatar tile size="150px"><img src="/img/glas-leer.jpg"></v-avatar></p>            
            <p class="subheading">Diese Seite ist nicht vorhanden!</p>
            <a v-if="$route.query.path" :href="$route.query.path" rel="nofollow">{{ $route.query.path }}</a>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  metaInfo: {
    title: '404 - Seite nicht gefunden',
  }
}
</script>