<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10>      
      <v-card>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title">Häufige Fragen</h3>
          </v-card-title>          
          <v-card-text class="pt-0 px-0">
            <template v-for="faqGroup in faqGroups" >
              <v-subheader :key="`header-${faqGroup.topic}`">{{ faqGroup.topic }}</v-subheader>            
              <v-expansion-panel :key="`questions-${faqGroup.topic}`">
                <v-expansion-panel-content
                  class="grey lighten-3"
                  v-for="(faq, index) in faqGroup.items" :key="`faq-${faqGroup.topic}-${index}`">
                  <div slot="header" style="margin-left: -8px">{{ faq.question }}</div>
                  <v-card color="secondary lighten-3">
                    <v-card-text class="faq-answer" v-html="faq.answer"></v-card-text>
                  </v-card>
                </v-expansion-panel-content>              
              </v-expansion-panel>                
            </template>
          </v-card-text>
        </v-container>
      </v-card>
    </v-flex>
    </v-layout>
    <v-layout justify-center class="mt-3">    
    <v-flex xs12 sm10>
      <v-card>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title">Helfen Sie uns diese Seite zu verbessern</h3>
          </v-card-title>              
          <v-card-text class="pt-0">
            <div>Zu welchen Themen würden Sie gerne mehr erfahren?</div>
            <form class="mt-3" @submit.prevent="formSubmit">
              <v-textarea
                v-model="formPoll.userQuestion"
                hide-details box rows="1" auto-grow 
                required
                :disabled="loading"
                label="Ihre Frage"></v-textarea>
              <div class="mt-3">Bei Fragen zu Ihrer Bestellung verwenden Sie bitte unser <router-link to="/kontakt/email">Kontaktformular</router-link>.</div>
              <v-layout class="mt-3" justify-end>
                <v-btn :loading="loading" type="submit">Absenden</v-btn>
              </v-layout>
              <v-alert 
                :value="pollSubmission"
                type="success" color="primary" outline
                transition="fade-transition"
                class="mt-3">
                <div>Vielen Dank! Wir freuen uns über Ihren Beitrag.</div>
              </v-alert>
            </form>
          </v-card-text>
        </v-container>
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    data: vm => ({
      loading: false,
      formPoll: {
        userQuestion: null
      },
      pollSubmission: false,
    }),
    metaInfo () {
      return {
        title: 'Häufige Fragen',
        script: [{
          vmid: 'ldjson-schema',
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org/",
            "@type": "FAQPage",
            "mainEntity": this.faq.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          }
        }]
      }
    },
    computed: {
      faqGroups () {
        return this.faq.reduce((faqGroups, faq) => {
          var faqGroup = faqGroups.find(faqGroup => faqGroup.topic === faq.topic)
          if (!faqGroup) {
            faqGroup = { topic: faq.topic, items: [] }
            faqGroups.push(faqGroup)
          }
          faqGroup.items.push(faq)
          return faqGroups
        }, [])
      },
      ...mapGetters('faq', ['faq'])
    },
    methods: {
      formSubmit () {
        if (!this.loading) {
          this.loading = true
          this.submitPoll(this.formPoll)
            .then(() => {
              this.pollSubmission = true              
              this.formPoll.userQuestion = null
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      ...mapActions('faq', ['submitPoll'])
    }
  }
</script>

<style>
.faq-answer p:last-child {
  margin-bottom: 0;
}
</style>