<template>
  <v-container fill-height>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>      
      <v-card class="fill-height">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title">Ihre Bestellungen</h3>
          </v-card-title>
          <template v-if="bestellungen === null">
            <v-card-text>            
              <content-placeholders>
                <content-placeholders-heading />
                <content-placeholders-text :lines="1" />
                <content-placeholders-heading />
                <content-placeholders-text :lines="1" />
                <content-placeholders-heading />
                <content-placeholders-text :lines="1" />
              </content-placeholders>
            </v-card-text>
          </template>
          <template v-else-if="!bestellungen.length">
            <v-card-text class="pt-0">                      
              <div class="grey--text">Keine Bestellungen verfügbar</div>
            </v-card-text>
          </template>
          <template v-else>
            <v-list three-line dense>
              <v-list-tile v-for="bestellung in bestellungen" :key="bestellung._id" :to="`/bestellungen/${bestellung.bestellungNr}`">
                <v-list-tile-content>
                  <v-list-tile-title>Bestellung #{{ bestellung.bestellungNr }} &ndash; {{ formatDate(new Date(bestellung.createdAt)) }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ bestellung.positionen.length }} Positionen &ndash; {{ formatPositionen(bestellung) }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </template>
        </v-container>        
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  import bestellungHelperMixin from '@/components/mixins/bestellung-helper'

  import * as moment from 'moment'
  moment.locale('de')  

  export default {
    mixins: [bestellungHelperMixin],
    data: () => ({
      bestellungen: null,
    }),
    metaInfo: {
      title: 'Ihre Bestellungen'
    },    
    methods: {
      formatDate(date) {
        return moment(date).format('LL')        
      },
      formatPositionen(bestellung) {
        return bestellung.positionen.map(position => {
          return position.produkt.title
        }).join(', ')
      },
      ...mapActions('orders', ['queryOrders'])
    },
    created () {
      this.queryOrders().then(bestellungen => {
        this.bestellungen = bestellungen
      }).then(() => {
        document.dispatchEvent(new Event('custom-render-trigger'))
      })
    }
  }
</script> 