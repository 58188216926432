<template>
  <v-container fill-height>
    <v-layout fill-height>
      <v-card class="flex">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="headline">Impressum</h3>
          </v-card-title>
          <v-card-text class="pt-0">
            <p><strong>Marmelädchen</strong><br>
            Olga Mesterheide & Ralf Schulte<br>
            Neuenfelder Str. 4<br>
            32361 Preußisch Oldendorf</p>
            <p>Telefon: 05742 700687<br>
            E-Mail: kontakt@marmelaedchen.net</p>
            <p>Inhaltlich Verantwortlicher gemäß § 5 Telemediengesetz (TMG): Olga Mesterheide</p>
            <h4 class="subheading font-weight-bold">Haftungshinweis</h4>
            <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
            Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
            Unser Angebot enthält weiterhin Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
            <h4 class="subheading font-weight-bold">Datenschutz</h4>
            <p>Wir verarbeiten Ihre Daten nach den gesetzlichen Bestimmungen der Datenschutzgrundverordnung (DSGVO). Es gilt unsere <router-link to="datenschutzerklaerung" append>Datenschutzerkärung</router-link>.</p>
          </v-card-text>
        </v-container>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    metaInfo: {
      title: 'Impressum von marmelaedchen.net',
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    },
    computed: {
      ...mapGetters('legal', ['privacyPolicy'])
    }
  }
</script>
