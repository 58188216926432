import axios from 'axios'

const state = {
  faq: [{
    id: 'q0',
    topic: 'Bestellung',
    question: 'Wie lange beträgt die Lieferzeit?',
    answer: '<p>In der Regel 5 Tage ab verbindlicher Bestellung.</p><p><strong>Lange Antwort:</strong> Es hängt davon ab, ob alle gewünschten Produkte vorrätig sind oder ggf. noch produziert werden müssen. Kurz vor Marktveranstaltungen kann es ebenfalls etwas länger dauern. Wenn Sie eine schnelle Lieferung wünschen, schreiben Sie uns einfach eine Nachricht in Ihrer Anfrage/Bestellung und wir sehen, was wir machen können.</p><p>Sie können den Versandstatus auch in Ihren Bestellungen einsehen.</p>'
  },
  {
    id: 'q13a',
    topic: 'Bestellung',
    question: 'Wie berechnen sich die Versandkosten?',
    answer: `<ul><li>Für kleine Lieferungen bis 10 Gläser Marmelade berechnen wir 4,30 €</li>
<li>Ab 11 Gläser 6,30 €</li></ul><br/>
<p>Grundsätzlich erfolgt der Versand kostendeckend. Für Großbestellungen mit Warenwert über 100 € berechnen wir den Versand je nach Bestellvolumen.</p>
<p>Alle Sendungen werden versichert mit DHL oder Hermes verschickt.</p>`
  },
  {
    id: 'q13b',
    topic: 'Bestellung',
    question: 'Sendungsverfolgung',
    answer: `<p>Gerne teilen wir Ihnen den Liefertermin und die Versandnummer für Ihre Bestellung <a href="/kontakt/email">auf Anfrage</a> mit.</p>`
  },
  { 
    id: 'q3a',
    topic: 'Geschenke',    
    question: 'Geschenkset zusammenstellen',
    answer: `<h4>Geschenkset „Marmelädchen“</h4>
<p>Vielseitig, lecker und natürlich immer frisch zubereitet. Um Ihnen die Auswahl zu erleichern, bieten wir Ihnen <a href="/katalog/marmelade-geschenk">dieses Geschenk</a> in der Variante Klassisch und Exklusiv an.</p>
<p>Sie wissen schon, welche Produkte sie verschenken wollen? Wählen Sie die Variante Unikat und stellen Sie Ihr persönliches Geschenkset einfach selber zusammen.
<h4>Weitere Geschenksets thematisch abgestimmt</h4>
<p>Jeweils zwei Gläser Fruchtaufstrich 225 g und eine Flasche Sirup 250 ml.</p>
<ul class="mb-3">
<li><a href="/katalog/geschenkset-holunderbluete">Geschenkset „Holunderblüte“</a></li>
<li><a href="/katalog/geschenkset-ingwer">Geschenkset „Ingwer“</a></li>
<li>Geschenkset „Rose“ (telefonisch)</li>
<li>Geschenkset „Holunderbeere“ (telefonisch)</li>
<li>Geschenkset „Zwiebelchen“ (telefonisch)</li>
</ul>
<h4>Beratung vor Ort im Hofladen</h4>
<p>Vereinbaren Sie am besten <a href="/kontakt/telefon">telefonisch</a> einen Termin mit uns.</p>`
  },
  { 
    id: 'q3b',
    topic: 'Geschenke',    
    question: 'Gastgeschenke und Mitarbeitergeschenke (für Firmen)',
    answer: `<p>Wir unterstützen Sie gerne bei Ihren privaten oder geschäftlichen Feierlichkeiten. Passend zum Anlass wählen wir die richtige Marmelade mit Ihnen aus.</p>
<p>Teilen Sie uns Ihre Wünsche und Vorstellungen am Besten telefonisch mit und wir machen Ihnen ein unverbindliches Angebot.<p>
<p><strong>Telefon:</strong> 05742 700687</p>`
  },  
],
  questionaire: [
    { id: 'q1', question: 'Produkte: Zutaten, Herstellung, Haltbarkeit' },
    { id: 'q2', question: 'Hofladen: Öffnungszeiten, Terminvereinbarung' },
    { id: 'q4', question: 'Diabetiker, Allergiker, Vegane Lebensweise' },
    { id: 'q5', question: 'Bio-Zertifizierung' },
    { id: 'q6', question: 'Rezeptvorschläge (Pikantes, Essig, Sirup)' },
    { id: 'q7', question: 'Wie schmeckt X im Vergleich zu Z' },
    { id: 'q8', question: 'Unterschied zwischen Marmelade, Konfitüre, Gelee, Fruchtaufstrich' },
    { id: 'q9', question: 'Aktuelle Empfehlung der Saison' },
    { id: 'q10', question: 'Marmelade selber zusammenstellen' },
    { id: 'q11', question: 'Abo: Regelmäßige Lieferung' },
    { id: 'q12', question: 'Bestellvorgang: Anfrage, Angebot, Bestätigung' },
    { id: 'q14', question: 'Bezahlung: Paypal, Vorkasse, Rechnung' },
    { id: 'q15', question: 'Homepage: Technische Umsetzung, QR-Code, Produktsuche' },
    { id: 'q16', question: 'Konto: Registrierung, Anmeldung, E-Mail, Kennwort' },
    { id: 'q17', question: 'Datenschutz, sichere Bestellung, Konto löschen' },
    { id: 'q18', question: 'Allgemeine Geschäftsbedingugnen, Widerruf, Stornierung' },
    { id: 'q19', question: 'Newsletter: Markttermine, Produktneuheiten' }
  ]
}

const getters = {
  faq: state => state.faq,
  faqItem: state => id => state.faq.find(faqItem => faqItem.id === id),
}

const actions = {
  submitPoll: ({ state, getters, commit, dispatch, rootState }, { userQuestion }) => {
    return axios({
      url: `${rootState.apiUrl}/poll`,
      data: {
        userQuestion
      },
      method: 'POST'
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions
}
