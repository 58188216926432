import axios from 'axios'

const state = {
  produkttypen: [
    { value: 'konfituere', text: 'Konfitüre', smallText: 'Fruchtaufstrich aus Früchten', group: 'fruchtaufstrich' },
    { value: 'gelee', text: 'Gelee', smallText: 'Fruchtaufstrich aus Fruchtsaft', group: 'fruchtaufstrich' },
    { value: 'marmelade', text: 'Marmelade', smallText: 'Fruchtaufstrich aus Zitrusfrüchten', group: 'fruchtaufstrich' },
    { value: 'fruchtaufstrich', text: 'Fruchtaufstrich' },
    { value: 'pikantes', text: 'Pikantes' },
    { value: 'essig', text: 'Gewürzessig' },
    { value: 'eingemachtes', text: 'Eingemachtes' },
    { value: 'sirup', text: 'Sirup' },
    //{ value: 'likoer', text: 'Likör' },
    { value: 'geschenk', text: 'Geschenk' }
  ],
  fuellMengeUnits: ['g', 'ml'],
  fuellMengeContainers: ['Glas', 'Flasche'],
  // Remote
  merkmale: null,
  preise: null,
  skuPartials: null
}

const getters = {
  produkttypen: state => state.produkttypen,
  fuellMengeUnits: state => state.fuellMengeUnits,
  fuellMengeContainers: state => state.fuellMengeContainers,
  merkmaleList: state => state.merkmale ? state.merkmale : [],
  preiseList: state => state.preise ? state.preise : [],
  skuPartialsList: state => state.skuPartials ? state.skuPartials : []
}

const mutations = {
  updateMerkmale (state, merkmale) {
    state.merkmale = merkmale
  },
  updatePreise (state, preise) {
    state.preise = preise
  },
  updateSKUPartials (state, skuPartials) {
    state.skuPartials = skuPartials
  }
}

const actions = {
  createProdukt: ({ commit, dispatch, rootState }, produkt) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte`,
        data: produkt,
        method: 'POST',
        requiresAuth: true
      }).then(resp => {
        commit('products/setProdukt', resp.data, { root: true })
        resolve(resp.data)
      }).catch(reject)
    })
  },
  updateProdukt: ({ commit, dispatch, rootState }, produkt) => {
    if (!produkt._id) {
      return Promise.reject(new Error('Product Id missing'))
    }
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/${produkt._id}`,
        data: produkt,
        method: 'PATCH',
        requiresAuth: true
      }).then(resp => {
        commit('products/setProdukt', resp.data, { root: true })
        resolve(resp.data)
      }).catch(reject)
    })
  },
  updateProduktAvailability: ({ commit, dispatch, rootState }, {produkt, stock}) => {
    if (!produkt._id) {
      return Promise.reject(new Error('Product Id missing'))
    }
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/${produkt._id}/inventory/${stock}`,
        method: 'POST',
        requiresAuth: true
      }).then(resp => {
        resolve(resp.data)
      }).catch(reject)
    })
  },  
  deleteProdukt: ({ commit, dispatch, rootState }, produktId) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/produkte/${produktId}`,
        method: 'DELETE',
        requiresAuth: true
      }).then(resolve).catch(reject)
    })
  },
  getZutaten: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/zutaten`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        resolve(resp.data.zutaten)
      }).catch(reject)
    })
  },
  findZutat: ({ commit, dispatch, rootState }, zutatId) => {
    return new Promise((resolve, reject) => {
      if (!zutatId) {
        reject(new Error('No id provided'))
      }
      axios({
        url: `${rootState.apiUrl}/zutaten/${zutatId}`,
        requiresAuth: true,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data.zutat)
      }).catch(reject)
    })
  },
  createZutat: ({ commit, dispatch, rootState }, { title, details, frucht, alkoholisch, allergen }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/zutaten`,
        data: { title, details, frucht, alkoholisch, allergen },
        method: 'POST',
        requiresAuth: true
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  },
  // Not implemented yet
  updateZutat: ({ commit, dispatch, rootState }, { _id: zutatId, title, details, frucht, alkoholisch, allergen }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/zutaten/${zutatId}`,
        data: { title, details, frucht, alkoholisch, allergen },
        method: 'PATCH',
        requiresAuth: true
      }).then(resp => { resolve(resp.data) }).catch(reject)
    })
  },
  searchZutaten: ({ commit, dispatch, rootState }, term) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/zutaten`,
        params: { search: term },
        method: 'GET',
        requiresAuth: true
      }).then(resp => { resolve(resp.data.zutaten) }).catch(reject)
    })
  },
  loadMerkmale: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/merkmale`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        commit('updateMerkmale', resp.data.merkmale)
        resolve()
      }).catch(reject)
    })
  },
  createMerkmal: ({ commit, dispatch, rootState }, { title, typ, auspraegungen }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/merkmale`,
        data: { title, typ, auspraegungen },
        method: 'POST',
        requiresAuth: true
      }).then(resp => {
        // Load all merkmale including newly created one instead of adding locally
        // Return new merkmal
        return dispatch('loadMerkmale').then(() => { resolve(resp.data) })
      }).catch(reject)
    })
  },
  loadPreise: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/preise`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        commit('updatePreise', resp.data.preise)
        resolve()
      }).catch(reject)
    })
  },
  createPreis: ({ commit, dispatch, rootState }, { title, produkttyp, fuellMenge, fuellMengeUnit, fuellMengeContainer, fuellMengeSet, preis }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/preise`,
        data: { title, produkttyp, fuellMenge, fuellMengeUnit, fuellMengeContainer, fuellMengeSet, preis },
        method: 'POST',
        requiresAuth: true
      }).then(resp => {
        // Load all preise including newly created one instead of adding locally
        // Return new preis
        return dispatch('loadPreise').then(() => { resolve(resp.data) })
      }).catch(reject)
    })
  },
  loadSKUPartials: ({ commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/partials`,
        method: 'GET',
        requiresAuth: true
      }).then(resp => {
        commit('updateSKUPartials', resp.data.partials)
        resolve()
      }).catch(reject)
    })
  },
  createSKUPartial: ({ commit, dispatch, rootState }, skuPartial) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/partials`,
        data: skuPartial,
        method: 'POST',
        requiresAuth: true
      }).then(resp => {
        // Load all preise including newly created one instead of adding locally
        // Return new preis
        return dispatch('loadSKUPartials').then(() => { resolve(resp.data) })
      }).catch(reject)
    })
  },  
  uploadImage: ({ commit, dispatch, rootState }, { filename, blob }) => {
    return new Promise((resolve, reject) => {
      var filenameTimestamped
      Promise.resolve().then(() => {
        return axios({
          url: `${rootState.apiUrl}/aws/generate-image-upload-url`,
          data: { filename },
          method: 'POST',
          requiresAuth: true
        })
      }).then(resp => {
        // Use outer promise scope
        filenameTimestamped = resp.data.filenameTimestamped
        const s3UploadUrl = resp.data.url

        return axios({
          url: s3UploadUrl,
          data: blob,
          method: 'PUT',
          headers: {
            'Content-Type': blob.type
          },
          timeout: 30000
        })
      }).then(resp => {
        return axios({
          url: `${rootState.apiUrl}/aws/generate-thumbnails`,
          data: { filename: filenameTimestamped },
          method: 'POST',
          requiresAuth: true
        })
      }).then(resp => {
        resolve({
          filenameTimestamped,
          urls: resp.data.urls
        })
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
