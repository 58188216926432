const state = {
  navMeinLaedchen: true,
  navSeiten: true
}

const getters = {
  navMeinLaedchen: state => state.navMeinLaedchen,
  navSeiten: state => state.navSeiten,
}

const mutations = {
  setNavMeinLaedchen (state, bool) {
    state.navMeinLaedchen = bool
  },
  setNavSeiten (state, bool) {
    state.navSeiten = bool
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
