<template>
  <v-app>
    <ml-navigation v-model="drawer"></ml-navigation>
    <ml-toolbar ref="toolbar" @sideIconClick="drawer = !drawer"></ml-toolbar>
    <ml-snackbar v-model="snackbar" :color="snackbarColor">{{ snackbarMessage }}</ml-snackbar>
    <ml-ls-watcher v-if="isLoginLinkPending"></ml-ls-watcher>
    <ml-background></ml-background>
    <v-content>
        <router-view></router-view>
    </v-content>
    <ml-footer></ml-footer>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'

  import NavigationDrawer from '@/components/main/NavigationDrawer.vue'
  import Toolbar from '@/components/main/Toolbar.vue'
  import ErrorSnackbar from '@/components/main/ErrorSnackbar'
  import LocalStorageWatcher from '@/components/main/LocalStorageWatcher'
  import Background from '@/components/main/Background.vue'
  import Footer from '@/components/main/Footer.vue'

  export default {
    // Parenthesize the body of function to return an object literal expression
    data: () => ({
      drawer: false,
      snackbar: false,
      snackbarColor: 'info',
      snackbarMessage: ''
    }),
    metaInfo: {
      title: 'Marmelädchen'
    },
    computed: {
      ...mapGetters('auth', ['isLoginLinkPending'])
    },
    methods: {
      showMessage (message, color = 'info') {
        this.snackbarMessage = message
        this.snackbarColor = color
        this.snackbar = true
      }
    },
    created () {
      // Authentication request interceptor in main.js
      axios.interceptors.response.use(response => {
        if (response.config.showSuccessMessage) {
          this.showMessage(response.data.message || response.statusText, 'success')
        }
        return response
      }, err => {
        if (!err.response) {
          this.showMessage('Netzwerkfehler')
        } else if (err.response.status >= 500) {
          this.showMessage('Server nicht erreichbar')
        } else if (err.response.status === 404 && err.response.data.message) {
          this.showMessage(err.response.data.message)
        } else if (err.response.status === 403) {
          this.$router.push('/403')
        } else if (err.response.status === 401 && !err.config.loginAttempt) {
          this.$store.dispatch('auth/resetSessionToken')
          this.showMessage('Sitzung abgelaufen')
        } else if (err.response.status === 400 && err.response.data.message) {
          this.showMessage(err.response.data.message)
        } else if (err.response.status === 200 && err.response.data.message) {
          this.showMessage(err.response.data.message)
        }
        return Promise.reject(err)
      })
      if (this.$store.getters['cart/cartItems'].some(cartItem => !cartItem.posId)) {
        this.$store.commit('cart/emptyCart');
        console.log('Produktupdate: Ihr Warenkorb wurde geleert.');
      }
    },
    components: {
      'ml-navigation': NavigationDrawer,
      'ml-toolbar': Toolbar,
      'ml-snackbar': ErrorSnackbar,
      'ml-ls-watcher': LocalStorageWatcher,
      'ml-background': Background,
      'ml-footer': Footer
    }
  }
</script>
