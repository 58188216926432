<template>
  <div></div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      interval: {
        type: Number,
        default: 5000
      }
    },
    data: () => ({
      timer: null
    }),
    // However, because the destroy hooks will not be called during SSR,
    // the timers will stay around forever. To avoid this,
    // move your side-effect code into beforeMount or mounted instead.
    mounted () {
      this.timer = setInterval(this.syncFromLocalStorage, this.interval)
    },
    destroyed () {
      clearInterval(this.timer)
    },
    methods: {
      ...mapActions('auth', ['syncFromLocalStorage'])
    }
  }
</script>
