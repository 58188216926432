<template>
  <v-container fill-height>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-card-text class="headline text-xs-center">
            <p><v-avatar size="150px"><img src="/img/hof_300x300.jpg"></v-avatar></p>            
            <p>Auf Wiedersehen!</p>            
            <v-alert :value="accountDeleted" outline type="success" class="mb-3">
              Ihr Konto wurde gelöscht!
            </v-alert>
            <v-layout justify-center>
              <v-btn to="/" color="primary" dark>Zur Startseite</v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  import store from '@/store'

  export default {
    metaInfo: {
      title: 'Abgemeldet'
    },        
    computed: {
      ...mapGetters('auth', ['accountDeleted'])
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('auth/resetAccount')
      next()
    }
  }
</script>