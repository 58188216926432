<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>
      <v-card class="flex" v-if="produkt">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
        <v-card-title primary-title>
          <v-layout column>
          <h3 class="title">{{ produkt.title }}</h3>
          <template v-if="produkt.titleMarkt">
            <div class="grey--text text-darken-1 caption">auch bekannt als</div>
            <h4 class="subheading">{{ produkt.titleAlt }}</h4>
          </template>
          </v-layout>
        </v-card-title>
        <v-tabs v-if="isAdmin" fixed-tabs :mandatory="false" hide-slider color="grey lighten-3">
          <v-tab to="bearbeiten" append exact><v-icon color="grey darken-3">edit</v-icon></v-tab>
          <v-tab to="drucken" append exact><v-icon color="grey darken-3">print</v-icon></v-tab>
          <v-tab :to="`/naehrwerte/rezeptur/${produkt.rezeptur}`" :disabled="!produkt.rezeptur">
            <v-icon :color="produkt.naehrwertangaben ? 'grey darken-3' : 'error'" >menu_book</v-icon>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-card-text>
          <v-container grid-list-lg class="pa-0">
          <v-layout wrap>
            <v-flex xs12 sm6 v-show="produkt.images.length || $vuetify.breakpoint.smAndUp">
              <v-carousel v-if="produkt.images.length > 1"
                id="produkt-details-carousel"
                :cycle="false"
                height="auto" class="elevation-0"
                v-model="carouselIndex">
                <v-carousel-item
                  v-for="image in produkt.images" :key="image.filenameTimestamped"                  
                  aspect-ratio="0.75">
                  <img :src="image.urls.small" style="width: 100%; height: 100%; object-fit: cover;"
                    :srcset="`${image.urls.small} 600w, ${image.urls.medium} 1200w, ${image.urls.large} 1800w`"
                    :alt="produkt.title + (image.caption ? ` ${image.caption}` : '')">
                </v-carousel-item>
              </v-carousel>
              <v-responsive v-else-if="produkt.images.length === 1"                
                aspect-ratio="0.75">
                <img :src="produkt.images[0].urls.small" style="width: 100%; height: 100%; object-fit: cover;"
                  :srcset="`${produkt.images[0].urls.small} 600w, ${produkt.images[0].urls.medium} 1200w, ${produkt.images[0].urls.large} 1800w`"
                  :alt="produkt.title + (produkt.images[0].caption ? ` ${produkt.images[0].caption}` : '')">                
              </v-responsive>
              <v-img v-else
                src="/img/IMG_2254-1534429470-mini.JPG"
                :aspect-ratio="0.75">
                <!-- Absolute positioning relative to padding box of relative parent -->
                <v-card
                  class="mx-2"
                  style="background-color: rgba(255,255,255,0.8); position: absolute; left: 0; right: 0; top: 50%; transform: translate(0px, -50%)">
                  <v-card-text
                    class="subheading text-xs-center"
                    style="overflow-wrap: break-word">
                    Leider kein Bild</v-card-text>
                </v-card>
              </v-img>
              
              <div v-if="produkt.images.length || produktImagesOutdated"
                class="mt-1">
                <!--<v-icon small color="accent" class="mr-2">info</v-icon>-->
                <template v-if="produkt.images.length">
                  <span v-if="activeImageCaption">{{ activeImageCaption }} &ndash; </span>
                  <span v-if="produktImagesOutdated">Abb. ähnlich &ndash; </span>
                  <span v-else>Abb. ähnlich</span>
                </template>
                <router-link v-if="produktImagesOutdated" to="/neue-glaeser">Neue Gläser seit Sep. 2019</router-link>
              </div>
            </v-flex>

            <v-flex xs12 sm6>
              <v-layout column class="fill-height">
              <v-flex shrink order-sm2>
              <template v-if="produktSkusAvailble.length">
                <v-textarea
                  label="Preis:"
                  rows="1"
                  auto-grow
                  :value="formatFuellmengePreis(produktSkuSelected.preis)"
                  :hint="produktSkuIdSelected ? `Grundpreis: ${formatGrundpreis(produktSkuSelected.preis, 100)}` : ''"
                  :persistent-hint="!!produktSkuIdSelected"
                  readonly></v-textarea>
                <div class="caption">Endpreis, zzgl. <a href="#" @click.prevent="showVersandkosten = true">Versandkosten</a></div>
                <div v-for="group in produktSkusGrouped" :key="group.type">
                  <label class="v-label theme--light caption d-block mt-2 mb-1" >{{ group.title }}: 
                    <strong v-if="produktSkuVariants[group.type]">{{produktSkuVariants[group.type]}}</strong>
                    <span v-if="produktSkuVariantsAdultsOnly.find(variant => variant.variantName === group.type)"> *</span>
                  </label>
                  <template v-if="group.skus.length > 1">
                    <v-chip
                      v-for="(sku, index) in group.skus" :key="index"
                      class="v-chip--cursor"
                      small
                      @click="() => sku.inventory && (produktSkuIdSelected = sku._id)"
                      :color="produktSkuIdSelected === sku._id ? 'secondary' : undefined"
                      :selected="produktSkuIdSelected === sku._id"
                      :disabled="!sku.inventory">
                      <v-avatar
                        v-if="sku.titleIcon" style="margin-left: -8px; margin-right: 4px;">
                        <v-img max-width="22" contain src="/favicon.ico" />
                      </v-avatar>
                      {{ sku.title }}</v-chip>
                  </template>
                </div>
                <div v-if="produktSkuSelected.preis.lieferumfang.length">
                  <span class="caption d-block my-1">inklusive:</span>
                  <ul>
                    <li v-for="(item, index) in produktSkuSelected.preis.lieferumfang" :key="index">{{ item }}</li>
                  </ul>
                </div>

                <div class="text-xs-center">
                  <v-btn class="mt-3" color="primary"
                    @click.native="produktAddToCart"
                    :disabled="!produktSkuIdSelected || !produktSkuValid">
                    <v-avatar size="24" class="mr-1"
                      :style="{filter: !produktSkuIdSelected || !produktSkuValid ? 'grayscale(100%)' : 'none'}">
                      <img :src="require('@/assets/icons8-shopping-bag-128_white.png')">
                    </v-avatar>
                    Hinzufügen
                  </v-btn>
                </div>
              </template>
              <template v-else>
                <p class="subheading">Produkt zur Zeit nicht lieferbar</p>              
              </template>
              </v-flex>
              
              <v-flex grow v-if="produkt.produkttyp !== 'geschenk' && (produkt.beschreibung || produktGeschmack.length)">
              <div>
                <h4 class="body-2 font-weight-bold">Beschreibung</h4>
                <div v-if="produkt.beschreibung"
                  style="white-space: pre-wrap">{{ produkt.beschreibung }}</div>
                <div v-if="produktGeschmack.length"
                  class="mt-1">
                  <v-chip small v-for="(item, index) in produktGeschmack" :key="index">
                    {{ item.merkmal.title }}
                  </v-chip>
                </div>
              </div>
              </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-alert :value="!produktSkuValid" type="info" outline>
            Bitte unten auswählen, um Produkt freizuschalten.</v-alert>

          <v-layout wrap>
            <!--
            <v-flex xs12>
              <div v-if="isFruchtaufstrich && produkt.fruchtanteil">
                <div class="font-italic">
                  Hergestellt aus {{ produkt.fruchtanteil }} g Früchten je 100 g.
                </div>
              </div>
            </v-flex>
            -->

            <v-flex v-if="produktSkuSlots.length" xs12>
              <h4 class="body-2 font-weight-bold">Zusammenstellung</h4>
              <template v-for="(partial, index) in produktSkuSlots">
                <template v-if="partial.kind === 'Slot'">
                  <v-autocomplete
                    :key="partial.slotName"
                    v-model="skuSelections.slots[partial.slotName]"
                    :items="produktSlotItems[partial.slotName]"
                    item-value="_id"
                    item-text="title"
                    :item-disabled="item => item.skus.every(sku => !sku.inventory)"
                    :label="`Slot ${index+1}: ${partial.title} [${formatFuellmenge(partial)}]`"
                    placeholder="Bitte auswählen"
                    menu-props="offsetY"
                    hide-details
                    clearable
                    dense>
                    <template slot="item" slot-scope="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-tile-content v-text="data.item"></v-list-tile-content>
                      </template>
                      <template v-else>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ data.item.title }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title
                            v-if="data.item.skus.every(sku => !sku.inventory)">
                            aktuell nicht lieferbar
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </template>
                <template v-else-if="partial.kind === 'Option'">
                  <v-select
                    :key="partial.optionName"
                    v-model="skuSelections.options[partial.optionName]"
                    :items="partial.optionValues"
                    :label="`Slot ${index+1}: ${partial.title} [${formatFuellmenge(partial)}]`"
                    placeholder="Bitte auswählen"
                    hide-details
                    denses></v-select>
                </template>
              </template>              
            </v-flex>

            <template v-if="(produkt.produkttyp === 'geschenk' && produkt.beschreibung) || produkt.inhaltHtml">
            <v-flex xs12>
              <h4 class="body-2 font-weight-bold">Beschreibung</h4>
              <p v-if="produkt.produkttyp === 'geschenk' && produkt.beschreibung"
                style="white-space: pre-wrap">{{ produkt.beschreibung }}</p>
              <div v-if="produkt.inhaltHtml" v-html="produkt.inhaltHtml"></div>
            </v-flex>
            </template>

            <v-flex v-if="produkt.zutaten.length" xs12>
              <h4 class="body-2 font-weight-bold">Zutaten</h4>
              <div v-html="formatZutaten(this.produkt.zutaten, { details: true, htmlAllergen: true })">
              </div>
            </v-flex>

            <!-- allergieHinweis -->
            <v-flex v-if="produkt.naehrwertangaben" xs12>
              <h4 class="body-2 font-weight-bold">
                Nährwerte je {{ produkt.naehrwertangaben.referenzWert }} {{ produkt.naehrwertangaben.referenzEinheit }}
              </h4>
              <v-list dense subheader
                id="produkt-details-naehrwertangaben"
                style="margin-left: -16px; margin-right: -16px">
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Energie</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.brennwertKJ.formatted }} kJ / {{ produkt.naehrwertangaben.brennwertKJ.formattedKcal }} kcal
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Fett</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.fett.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="pl-3">
                    <v-list-tile-title>davon gesättigte Fettsäuren</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.fettSaturiert.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Kohlenhydrate</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.carbs.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content class="pl-3">
                    <v-list-tile-title>davon Zucker</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.carbsZucker.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Eiweiß</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.eiweiss.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Salz</v-list-tile-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    {{ produkt.naehrwertangaben.salz.formatted }} g
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
            </v-flex>
            <v-flex v-if="produktSkuVariantsAdultsOnly.length" class="caption grey--text text--darken-1">
            * Abgabe nur an Erwachsense
            </v-flex>
          </v-layout>
          <v-alert :value="produkt.produkttyp === 'geschenk'" outline color="grey darken-3" type="success">
            Wir holen vor Versand Ihre Freigabe der endgültigen Zusammenstellung ein.
          </v-alert>
          </v-container>
        </v-card-text>
        </v-container>
      </v-card>
    </v-flex>
    </v-layout>
    <v-dialog
      v-model="showVersandkosten"
      :transition="false"
      max-width="500"
      scrollable>       
      <v-card>
        <v-card-title class="subheading font-weight-bold">
          Versandkosten
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-html="faqVersandkosten.answer">
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-around">
          <v-btn flat small @click="showVersandkosten = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'

  import store from '@/store'
  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import { routeNotFoundRedirect, routeUnavailableRedirect } from '@/router/navguards'

  import hash from 'object-hash'

  import * as moment from 'moment'
  moment.locale('de')

  export default {
    mixins: [produktHelperMixin],
    data: () => ({
      produkt: null,
      produktSlots: null,
      skuIdSelectedUser: null,
      carouselIndex: 0,
      showVersandkosten: false,
      skuSelections: { slots: {}, options: {}}
    }),
    metaInfo () {
      var title = 'Produktdetails';
      const link = [];
      const script = [];      
      if (this.produkt) {
        title = this.produkt.title;

        const canonicalURL = `${this.$store.state.siteUrl}/katalog/${this.produkt.slug}`
        link.push({ 
          vmid: 'rel-canonical',
          rel: 'canonical',
          href: canonicalURL
        })

        const productJSON = {
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": this.produkt.title,
          "image": this.produkt.images.map(image => image.urls.large),
          "sku": `${this.produkt.lfdNr}`,
        }
        const productJSONOffers = {
          "@type": "Offer",
          "url": canonicalURL,
          "priceCurrency": "EUR",
          "availability": this.produkt.skus.some(sku => !!sku.inventory)
            ? "https://schema.org/InStock"
            : "https://schema.org/OutOfStock",
          "seller": {
            "@type": "Organization",
            "name": "Marmelädchen, Inh. Olga Mesterheide"
          }
        }
        if (this.produkt.skus.length) {
          const skuPreiseSorted = this.produkt.skus
            .map(sku => sku.preis)
            .sort((p1, p2) => p1.preis - p2.preis);
          if (skuPreiseSorted.length >= 2) {
            productJSONOffers["@type"] = "AggregateOffer";
            productJSONOffers["lowPrice"] = (skuPreiseSorted[0].preis/100).toFixed(2);
            productJSONOffers["highPrice"] = (skuPreiseSorted[skuPreiseSorted.length-1].preis/100).toFixed(2)
          } else {
            productJSONOffers["price"] = (skuPreiseSorted[0].preis/100).toFixed(2);
          }
          const descFuellmenge = this.formatFuellmenge(skuPreiseSorted[0]);
          const descGrundpreis = this.formatGrundpreis(skuPreiseSorted[0], 100);
          const descVersand = "Endpreis zzgl. Versandkosten";
          if (descGrundpreis) {
            productJSON["description"] = `Füllmenge: ${descFuellmenge}, Grundpreis: ${descGrundpreis}, ${descVersand}.`;
          } else {
            productJSON["description"] = `Füllmenge: ${descFuellmenge}, ${descVersand}.`;
          }
          productJSON["offers"] = productJSONOffers;
        }
        script.push({
          vmid: 'ldjson-schema',
          type: 'application/ld+json',
          json: productJSON
        })
      }
      return { title, link, script }
    },
    // See also: navigation gaurd routeQRCodeRedirect
    // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-before-navigation
    beforeRouteEnter (to, from, next) {
      const { params: { slug }, query: { forceReload } } = to;
      store.dispatch('products/findProdukt', { slug, forceReload: !!forceReload }).then(produkt => {
        next(vm => { vm.setData(produkt) })
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          return routeNotFoundRedirect(to, from, next);
        } else {
          return routeUnavailableRedirect(to, from, next);
        }
      })
    },
    beforeRouteUpdate (to, from, next) {
      const { params: { slug }, query: { forceReload } } = to;      
      store.dispatch('products/findProdukt', { slug, forceReload: !!forceReload }).then(produkt => {
        this.setData(produkt)
        next()
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          return routeNotFoundRedirect(to, from, next);
        } else {
          return routeUnavailableRedirect(to, from, next);
        }
      })
    },
    methods: {
      produktAddToCart () {
        if (this.produktSkuIdSelected) {
          this.cartIncItem({ produkt: this.produkt, sku: this.produktSkuSelected, notes: this.produktSkuNotes })
        }
      },
      setData(produkt) {
        this.produkt = produkt;
        this.skuSelections = produkt.skus.reduce((skuSelections, sku) => {
          sku.partials.forEach(partial => {
            if (partial.kind === 'Slot' && skuSelections.slots[partial.slotName] === undefined) {
              this.$set(skuSelections.slots, partial.slotName, partial.slotDefault || null)
            } else if (partial.kind === 'Option' && skuSelections.options[partial.optionName] === undefined) {
              this.$set(skuSelections.options, partial.optionName, partial.optionDefault || null)
            }
          })
          return skuSelections;
        }, this.skuSelections)
        this.produktSlots = null;
        this.queryProduktSlots(produkt._id).then(slots => {
          this.produktSlots = slots
        })
        const actvieSkuImgIndex = this.produkt.images.findIndex(image => image.sku === this.produktSkuIdSelected)
        if (this.produktSkuIdSelected !== null && actvieSkuImgIndex !== -1) {
          this.carouselIndex = actvieSkuImgIndex
        } else {
          this.carouselIndex = 0
        }
      },
      ...mapActions('products', ['queryProduktSlots']),
      ...mapMutations('cart', ['cartIncItem', 'cartDecItem'])
    },
    computed: {
      ...mapGetters('auth', ['isAuthenticated', 'isAdmin']),
      ...mapGetters('faq', ['faqItem']),
      ...mapGetters('cart', ['cartItems']),
      faqVersandkosten () {
        return this.faqItem('q13a')
      },
      carouselNextLabel () {
        return '<i aria-hidden="true" ' +
          'style="font-size: 32px" class="v-icon material-icons"' +
          '>chevron_right</i>'
      },
      carouselPrevLabel () {
        return '<i aria-hidden="true" ' +
          'style="font-size: 32px" class="v-icon material-icons"' +
          '>chevron_left</i>'
      },
      produktGeschmack () {
        return this.produkt.merkmale.filter(item => item.merkmal.typ === 'Geschmack')
      },
      produktSkusAvailble () {
        return this.produkt.skus.filter(item => item.inventory)
      },
      produktSkuIdSelected: {
        get () {
          if (this.skuIdSelectedUser) {
            return this.skuIdSelectedUser
          } else if (this.produktSkusAvailble.length) {
            // Auto select first SKU
            return this.produktSkusAvailble[0]._id
          } else {
            return null
          }
        },
        set (skuId) {
          this.skuIdSelectedUser = skuId
        }
      },
      produktSkusGrouped () {
        const skusHavePartials = this.produkt.skus.some(sku => {
           return sku.partials.some(partial => partial.kind === 'Variant')
        })
        const skusFuellmengeIsomorph = this.produkt.skus.length === [...new Set(this.produkt.skus.map(sku => this.formatPreisVariant(sku.preis)))].length
        if (!skusHavePartials && skusFuellmengeIsomorph) {
          return [{title: 'Größe', type: 'fuellMenge', skus: this.produkt.skus.map(sku => ({
            ...sku,
            title: this.formatPreisVariant(sku.preis)
          }))}]
        }

        var groups = this.produktSkusAvailble.reduce((groups, sku) => {
          groups[0].values.push(this.formatPreisVariant(sku.preis));
          sku.partials.filter(partial => partial.kind === 'Variant').forEach(partial => {
            var group = groups.find(group => group.type === partial.variantName);
            if (!group) {
              group = {title: partial.title, type: partial.variantName, values: [partial.variantValue]}
              groups.push(group)
            } else {
              group.values.push(partial.variantValue)
            }
          })
          return groups
        }, [{title: 'Größe', type: 'fuellMenge', values: []}]).map(group => {
          group.values = [...new Set(group.values)];
          return group;
        })
        const partialsExtractorFn = sku => {
          return Object.assign({
            fuellMenge: this.formatPreisVariant(sku.preis),
          }, sku.partials.filter(partial => partial.kind === 'Variant').reduce((partials, partial) => {
            return {
              ...partials,
              [partial.variantName]: partial.variantValue
            }
          }, {}))
        }        
        const skusCount = this.produktSkusAvailble.length;
        const skusGrouped = this.produktSkusAvailble.filter(sku => {
          return sku.partials.filter(partial => partial.kind === 'Variant').length === groups.length - 1
        }).length
        const skusMax = groups.reduce((anzahl, group) => anzahl * group.values.length, 1)
        const skusUnique = [...new Set(this.produktSkusAvailble.map(sku => hash(partialsExtractorFn(sku))))].length
        if (skusCount === skusGrouped && skusCount === skusMax && skusCount === skusUnique) {
          const produktSkuSelectedPartials = partialsExtractorFn(this.produktSkuSelected)
          groups.forEach(group => {
            group.skus = group.values.map(variantValue => {
              const produktSkuTargetPartials = {
                ...produktSkuSelectedPartials,
                [group.type]: variantValue
              }
              const targetSku = this.produktSkusAvailble.find(sku => hash(partialsExtractorFn(sku)) === hash(produktSkuTargetPartials));
              const variant = targetSku.partials.find(partial => {
                return partial.kind === 'Variant' && partial.variantName === group.type && partial.variantValue === variantValue
              })
              return { ...targetSku, title: variantValue, titleIcon: !!variant?.vendorIcon }
            })
          })
        } else {
          const skus = this.produktSkusAvailble.map(sku => {
            const titles = [this.formatPreisVariant(sku.preis)]
            titles.push(...sku.partials.filter(partial => partial.kind === 'Variant').map(partial => partial.variantValue))
            return { ...sku, title: titles.join(', ') }
          })
          groups = [{title: 'Variante', type: 'generic', skus}]
        }
        //return groups.filter(group => group.skus.length > 1)
        return groups
      },
      produktSkuSelected () {
        return this.produktSkuIdSelected
          ? this.produkt.skus.find(sku => sku._id === this.produktSkuIdSelected)
          : null
      },
      produktSkuNotes () {
        var notes = []
        if (this.produktSkuSelected && this.produktSlots) {
          this.produktSkuSelected.partials.forEach(partial => {
            if (partial.kind === 'Slot') {
              const selection = this.skuSelections.slots[partial.slotName];
              const slotData = this.produktSlots.find(slotData => slotData.slot.slotName === partial.slotName);
              if (selection && slotData) {
                const item = slotData.items.find(item => item._id === selection);
                if (item) {
                  notes.push(`${item.title} [${this.formatFuellmenge(partial)}]`)
                }
              }
            } else if (partial.kind === 'Option') {
              const selection = this.skuSelections.options[partial.optionName];
              if (selection) {
                notes.push(`${selection} [${this.formatFuellmenge(partial)}]`)
              }
            }
          })
          notes = notes.reduce((notesQty, note) => {
            const noteQty = notesQty.find(noteQty => noteQty.title === note)
            if (noteQty) noteQty.quantity++;
            else notesQty.push({ title: note, quantity: 1 })
            return notesQty
          }, []).map(noteQty => `${noteQty.quantity} x ${noteQty.title}`)
        }
        return notes.length ? notes.join("\n") : null;
      },
      produktSkuSlots () {
        if (this.produktSkuSelected) {
          return this.produktSkuSelected.partials.filter(partial => ['Slot', 'Option'].includes(partial.kind))
        }
        return []
      },
      produktSkuVariants () {
        if (this.produktSkuSelected) {
          return this.produktSkuSelected.partials.filter(partial => partial.kind === 'Variant')
            .reduce((variants, partial) => {
              return { ...variants, [partial.variantName]: partial.variantValue}
            }, {fuellMenge: this.formatPreisVariant(this.produktSkuSelected.preis)})
        }
        return {}
      },
      produktSkuVariantsAdultsOnly () {
        if (this.produktSkuSelected) {
          return this.produktSkuSelected.partials.filter(partial => partial.adultsOnly)
        }
        return []
      },      
      produktSkuValid () {
        return this.produktSkuSlots.every(partial => {
          if (partial.kind === 'Slot') {
            return !!this.skuSelections.slots[partial.slotName]
          } else if (partial.kind === 'Option') {
            return !!this.skuSelections.options[partial.optionName]
          }
        })
      },
      produktSlotItems () {
        const cartProduktIds = this.cartItems.map(cartItem => cartItem.produkt._id)
        return (this.produktSlots || []).reduce((produktSlotItems, slotData) => {
          const highlightedItems = slotData.items.filter(item => cartProduktIds.includes(item._id))
          const regularItems = slotData.items.filter(item => !cartProduktIds.includes(item._id) )
          const slotItems = highlightedItems.length
            ? [{header: 'Aus Warenkorb'}, ...highlightedItems, {header: 'Weitere'}, ...regularItems]
            : regularItems
          return { ...produktSlotItems, [slotData.slot.slotName]: slotItems }
        }, {})
      },
      produktImagesOutdated () {
        const sturzglasDate = moment('2019-09');
        if (this.isFruchtaufstrich) {
          return !this.produkt.images.some(image => {
            return moment(image.lastModified).isAfter(sturzglasDate)
          })
        }
        return false;
      },
      isFruchtaufstrich () {
        return ['konfituere', 'gelee', 'marmelade', 'fruchtaufstrich'].includes(this.produkt.produkttyp)
      },
      activeImageCaption () {
        if (this.produkt.images[this.carouselIndex]) {
          return this.produkt.images[this.carouselIndex].caption
        }
      }
    }
  }
</script>

<style lang="scss" type="text/scss">
  #produkt-details-carousel .v-carousel__controls {
    background: none;

    .v-carousel__controls__item.v-btn--active .v-icon {
      // text--accent
      color: #ffc107 !important;
    }
  }

  #produkt-details-naehrwertangaben .v-list__tile {
    height: auto !important;
  }

  .v-chip--cursor .v-chip__content {
    cursor: pointer !important;
  }
</style>

<style>
  .ml-chip--variant {
    height: auto !important;
    min-height: 24px;
  }
  .ml-chip--variant .v-chip__content {
    white-space: normal !important;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;
  }  
</style>