<template>
  <v-container fill-height>
    <v-layout align-center justify-center fill-height>
      <v-flex xs12 sm8 md6>
        <v-card class="elevation-12">
          <v-card-text class="text-xs-center">
            <p class="display-1">API error</p>
            <p><v-avatar tile size="150px"><img src="/img/fail.jpg"></v-avatar></p>
            <p class="subheading">Der Server kann die Anfrage nicht beantworten.<br>Versuchen Sie es später wieder!</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

export default {
  metaInfo: {
    title: '503 - API error'
  }
}
</script>