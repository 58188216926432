<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>      
      <v-card v-if="bestellung">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
        <v-card-title primary-title>
          <h3 class="title">Bestellung #{{ bestellung.bestellungNr }}</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <v-subheader class="pa-0">Status</v-subheader>
          <v-chip v-if="bestellung.oldweb_status.beantwortet && bestellung.oldweb_status.angenommen" color="primary" small text-color="white">bestätigt</v-chip>
          <v-chip v-else small>noch nicht bestätigt</v-chip>
          <v-chip v-if="bestellung.oldweb_status.bezahlt" color="primary" small text-color="white">bezahlt</v-chip>
          <v-chip v-else small>nocht nicht bezahlt</v-chip>          
          <v-chip v-if="bestellung.oldweb_status.geliefert" color="primary" small text-color="white">geliefert</v-chip>
          <v-chip v-else small>noch nicht geliefert</v-chip>     
          <v-subheader class="pa-0">Warenkorb</v-subheader>
          <v-data-table
            ref="dTable"
            :items="bestellung.positionen"
            :headers="headers"
            :hide-headers="$vuetify.breakpoint.xsOnly"
            item-key="_id"
            hide-actions>
            <template v-if="headers" slot="headers" slot-scope="props">
              <tr style="height: auto">
                <th v-for="header in props.headers" :key="header.text"
                  :class="{'text-xs-left': header.align === 'left', 'text-xs-right': header.align === 'right'}"
                  class="px-1 py-1">{{ header.text }}</th>
              </tr>
            </template>                
            <template slot="items" slot-scope="props">
              <tr @click="props.expanded = !props.expanded">
                <td v-if="$vuetify.breakpoint.mdAndUp" class="px-1 py-1">
                  <v-avatar size="40">
                    <v-img v-if="!!props.item.produkt.images.length" 
                      :src="props.item.produkt.images[0].urls.thumb" contain></v-img>
                    <v-img v-else :src="fallbackImageSrc"></v-img>                                       
                  </v-avatar>
                </td>
                <td class="px-1 py-1">
                  <div class="font-weight-bold">
                    <span v-if="$vuetify.breakpoint.xsOnly">{{ props.item.quantityRequested }} x</span>
                    {{ props.item.produkt.title }}
                  </div>
                  <div v-if="$vuetify.breakpoint.smAndDown">
                    <span v-if="$vuetify.breakpoint.xsOnly">{{ formatFuellmengePreis(props.item.sku.preis) }}</span>
                    <span v-else> {{ formatFuellmenge(props.item.sku.preis) }}</span>
                  </div>
                  <div class="caption grey--text text--darken-1">Grundpreis: {{ formatGrundpreis(props.item.sku.preis, 100) }}</div>
                  <div v-if="formatSkuVariants(props.item.sku)" 
                    :class="{caption: $vuetify.breakpoint.smAndDown}"><i>{{ formatSkuVariants(props.item.sku) }}</i></div>
                  <div v-if="props.item.notes"
                    :class="{caption: $vuetify.breakpoint.smAndDown}" style="white-space: pre-wrap">{{ props.item.notes }}</div>
                  <div v-else-if="props.item.sku.preis.lieferumfang && props.item.sku.preis.lieferumfang.length"
                    :class="{caption: $vuetify.breakpoint.smAndDown}" style="white-space: pre-wrap">{{ props.item.sku.preis.lieferumfang.join("\n") }}</div>            
                </td>
                <td v-if="$vuetify.breakpoint.mdAndUp" class="px-1 py-1 text-xs-center">
                  {{ formatFuellmenge(props.item.sku.preis) }}
                </td>
                <td v-if="$vuetify.breakpoint.smAndUp" class="px-1 py-1 text-xs-center">
                  {{ props.item.quantityRequested }} Stk.
                </td>
                <td v-if="$vuetify.breakpoint.smAndUp" class="px-1 py-1 text-xs-right">
                  {{ formatPreisInt(props.item.sku.preis.preis) }}
                </td>
                <td class="px-1 pl-1 py-1 text-xs-right">
                  {{ formatPreisInt(props.item.quantityRequested * props.item.sku.preis.preis) }}
                </td> 
              </tr>
            </template>
            <template slot="expand" slot-scope="props">
              <v-card flat>
                <v-card-actions>
                  <v-btn small flat :to="`/katalog/${props.item.produkt.slug}`">Zum Produkt</v-btn>
                </v-card-actions>
              </v-card>
            </template>            
            <template slot="footer">
              <tr style="font-size: 13px">
                <td :colspan="headers ? headers.length-1 : 1"
                  class="px-1 py-1" :class="{'text-xs-right': $vuetify.breakpoint.smAndUp}">
                  <slot name="shipping">
                    <div>Versandkosten</div>
                    <div class="caption grey--text text--darken-1">innerhalb Deutschlands</div>
                  </slot>
                </td>
                <td class="px-1 py-1 text-xs-right">{{ formatPreisInt(bestellung.versandkosten) }}</td>
              </tr>
              <tr style="font-size: 13px">
                <td :colspan="headers ? headers.length-1 : 1"
                  class="px-1 py-1 font-weight-bold"
                  :class="{'text-xs-right': $vuetify.breakpoint.smAndUp}">
                  <slot name="sum">
                    <div>Summe</div>
                    <div class="caption grey--text text--darken-1">Gemäß § 19 UStG wird keine Umsatzsteuer berechnet</div>
                  </slot>
                </td>
                <td class="px-1 py-1 text-xs-right font-weight-bold">
                  {{ formatPreisInt(getBestellungGesamtwert(bestellung))}}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-layout wrap>
            <v-flex xs12 md6 :class="{'pr-2': $vuetify.breakpoint.mdAndUp}">
              <v-subheader class="pa-0">{{bestellung.adresseLieferung ? 'Rechnungsadresse' : 'Rechnungs- und Lieferadresse' }}</v-subheader>
              <address style="white-space: pre-wrap">{{ formatAddress(bestellung.adresseRechnung) }}</address>
            </v-flex>
            <v-flex v-if="bestellung.adresseLieferung" xs12 md6 :class="{'pr-2': $vuetify.breakpoint.mdAndUp}">
              <v-subheader class="pa-0">Lieferadresse</v-subheader>
              <address style="white-space: pre-wrap">{{ formatAddress(bestellung.adresseLieferung) }}</address>
            </v-flex>
          </v-layout>
          <v-subheader class="pa-0">Zahlweise</v-subheader>
          <v-layout align-center>
            <v-icon class="mr-2">radio_button_checked</v-icon>
            {{ paymentMethodFriendly(bestellung.zahlweise) }}
          </v-layout>
          <template v-if="!!bestellung.nachrichten.length">
            <v-subheader class="pa-0">Nachricht</v-subheader>
            <div class="mb-3" v-for="(nachricht, index) in bestellung.nachrichten" :key="index">{{ nachricht.message }}</div>
          </template>
        </v-card-text>
        </v-container>        
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'

  import produktHelperMixin from '@/components/mixins/produkt-helper'
  import userHelperMixin from '@/components/mixins/user-helper'
  import bestellungHelperMixin from '@/components/mixins/bestellung-helper'

  export default {
    mixins: [produktHelperMixin, userHelperMixin, bestellungHelperMixin],
    data: () => ({
      bestellung: null,
      fallbackImageSrc: require('@/assets/jar.png')
    }),
    metaInfo () {
      return {
        title: this.bestellung ? `Bestellung Nr. ${this.bestellung.bestellungNr}` : 'Bestellung'
      }
    },    
    computed: {
      headers () {
        if (this.$vuetify.breakpoint.smAndUp) {
          var headers = [
            { text: 'Produkt', value: 'produkt.title', align: 'left' },
            { text: 'Anzahl', value: 'quantity' },
            { text: 'Preis', value: 'sku.preis.preis', align: 'right' },
            { text: 'Betrag', value: 'endpreis', align: 'right' }
          ]
          if (this.$vuetify.breakpoint.mdAndUp) {
            headers.splice(0, 0, { text: '' })
            headers.splice(2, 0, { text: 'Größe', value: 'sku.preis.fuellMenge' })
          }
          return headers.map(header => ({ ...header, sortable: false }))
        }
      },
      ...mapGetters('orders', ['paymentMethodFriendly'])
    },
    methods: {
      ...mapActions('orders', ['findOrder', 'queryOrders'])
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('orders/findOrder', to.params.bestellungNr).then(bestellung => {
        next(vm => { vm.bestellung = bestellung })
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          next('403')
        } else {
          next(false)
        }
      })
    },
    beforeRouteUpdate (to, from, next) {
      store.dispatch('orders/findOrder', to.params.bestellungNr).then(bestellung => {
        this.bestellung = bestellung
        next()
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          next('403')
        } else {
          next(false)
        }
      })
    }
  }
</script> 