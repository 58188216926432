import axios from 'axios'

const state = {
  paymentMethods: [
    { value: 'ueberweisung', text: 'Überweisung (Vorkasse)' },
    { value: 'paypal', text: 'Paypal' }
  ]
}

const getters = {
  paymentMethods: state => state.paymentMethods,
  paymentMethodFriendly: (state, getters) => value => {
    if (value) {
      const method = getters['paymentMethods'].find(method => method.value === value)
      return method
        ? method.text
        : value.charAt(0).toUpperCase() + value.slice(1)  
    }
    return null
  }
}

const actions = {
  findOrder: ({ state, commit, dispatch, rootState }, bestellungNr) => {
    return new Promise((resolve, reject) => {
      if (!bestellungNr) {
        reject(new Error('No id provided'))
      }
      axios({
        url: `${rootState.apiUrl}/bestellungen/${bestellungNr}`,
        requiresAuth: true,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data.bestellung)
      }).catch(reject)
    })
  },
  queryOrders: ({ state, commit, dispatch, rootState }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/bestellungen`,
        requiresAuth: true,
        method: 'GET'
      }).then(resp => {
        resolve(resp.data.bestellungen)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions
}
