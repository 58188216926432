import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import de from 'vuetify/es5/locale/de'
import colors from 'vuetify/es5/util/colors'

import '@/styles/main.styl'
import '@/styles/style.css'

Vue.use(Vuetify, {
  theme: {
    primary: colors.lightGreen.darken4,
    secondary: colors.lightGreen.lighten2,
    accent: colors.amber.base,
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },
  customProperties: true,
  iconfont: 'md',
  lang: {
    locales: { de },
    current: 'de'
  },
})
