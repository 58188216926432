import { render, staticRenderFns } from "./ProductsList.vue?vue&type=template&id=c6950650&scoped=true&"
import script from "./ProductsList.vue?vue&type=script&lang=js&"
export * from "./ProductsList.vue?vue&type=script&lang=js&"
import style0 from "./ProductsList.vue?vue&type=style&index=0&id=c6950650&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6950650",
  null
  
)

export default component.exports