<template>
  <v-footer
    height="auto"
    ref="footer"
    app
    absolute
    inset
    style="background: rgba(0,0,0,0.4)">
    <v-card
      flat
      tile
      class="flex"
      color="transparent"
      style="font-size: 13px">
      <v-card-text 
        v-show="!collapsed" 
        class="pa-2 white--text">
        <v-layout>
          <v-flex class="hidden-xs-only" style="flex-grow: 0;">
            <v-layout row align-center>
              <v-icon dark size="18px" class="mr-3">home</v-icon>
              <address v-html="addressHtml"></address>
            </v-layout>
          </v-flex>
          <v-flex>
            <v-layout fill-height align-center justify-space-around>
              <v-flex v-for="item in navFooter.items" :key="item.text"
                class="text-xs-center"
                :class="item.containerClass || []"
                style="flex-grow: 0">
                <v-btn color="white" :to="item.to" :href="item.href || undefined"
                  icon small :aria-label="item.ariaLabel">
                  <v-icon color="grey darken-3" :size="item.iconSize">{{ item.icon }}</v-icon>
                </v-btn><br/>
                <span class="font-weight-bold">{{ item.text }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <!-- class="secondary application theme--light px-2 py-1 no-wrap justify-center caption" -->
      <v-card-actions class="grey darken-3 white--text px-2 py-1 no-wrap">
        <span>&copy; {{ copyrightYear }}</span>
        <span class="ml-1" v-show="$vuetify.breakpoint.smAndUp">version {{ $store.state.packageVersion }}</span>
        <!-- https://stackoverflow.com/a/34063808 -->
        <span style="margin-left: auto">
          <strong>{{ copyrightTitle }}</strong>
          <span v-show="$vuetify.breakpoint.smAndUp">&nbsp;— {{ copyrightSubtitle }}</span>
        </span>    
        <v-btn style="margin-left: auto;" 
          @click.native="collapsed = !collapsed"        
          icon small dark>
          <v-icon>{{ collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</v-icon>
        </v-btn>            
      </v-card-actions>
    </v-card>
  </v-footer>        
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      addressHtml: `<strong>Olga Mesterheide &amp; Ralf Schulte</strong><br/>
                    Neuenfelder Str. 4<br/>
                    32361 Preußisch Oldendorf`,
      email: 'kontakt@marmelaedchen.net',
      phone: '+49 5742 700687',
      copyrightYear: '2018',
      copyrightTitle: 'Marmelädchen',
      copyrightSubtitle: 'Hausgemachte Marmelade & Gelee',
      collapsed: false
    }),
    computed: {
      ...mapGetters('navigation', {
        navFooter: 'footer'
      })
    },
    watch: {
      collapsed () {
        // height=auto does not recalculate app padding
        this.$nextTick(this.$refs.footer.callUpdate)
      }
    },
    methods: {
      onResize () {
        // React to height changes due to responsive design
        this.$nextTick(this.$refs.footer.callUpdate)
      }
    }
  }
</script>