<template>
  <v-container fill-height>
    <v-layout column fill-height>
      <v-card class="flex">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="headline">Märkte</h3>          
          </v-card-title>
          <template v-if="maerkte === null">
            <content-placeholders v-for="i in ['p1','p2','p3']" :key="i">
              <v-card-text>
                <v-layout >
                  <v-flex xs12 sm8 md6>
                    <content-placeholders-heading />
                    <content-placeholders-text :lines="1" />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-divider></v-divider>
            </content-placeholders>                  
          </template>
          <v-card-text v-else-if="covid && maerkte.length === 0" class="subheading pt-0">
            <p>Liebe Kunden, leider sind unsere regulären Veranstaltungen für 2020 größtenteils abgesagt worden.<br><br>

            Gerne schicken wir Ihnen unsere Produkte auf dem Postweg!<br>
            Unser Hofladen ist nach telefonischer Absprache natürlich auch geöffnet.<br><br>

            Wir wünschen allen gute Gesundheit und kommen Sie gut durch die Krise! 🍀</p>
            <div class="my-3">
              <v-img src="/img/maerkte/IMG_20181020_091632.jpg" max-height="250" contain aspect-ratio="2"></v-img>
              <div class="caption text-xs-center">Gerne würden wir Ihnen wieder unsere Produkte präsentieren wie hier<br />
              am 20.10.2018 auf dem Apfeltag in Rinteln</div>
            </div>            
          </v-card-text>
          <template v-else-if="maerkte !== null">
            <v-card-text class="subheading pt-0">
              Besuchen Sie unseren Marktstand auf einer der folgenden Veranstaltungen:
            </v-card-text>
            <div v-for="markt in maerkte" :key="markt.market_id">
              <v-card-text>            
                <h4 class="title">{{ markt.title }}</h4>
                <div class="subheading my-1">
                  <span class="font-weight-bold">{{ markt.place }}</span>
                  <span v-if="markt.content" style="white-space: pre-wrap">&nbsp;&ndash; {{ markt.content }}</span>
                </div>          
                <div>
                  <v-icon class="mr-1">event</v-icon>
                  <span>{{ formatDatespan(markt.date_start, markt.date_end) }}</span> &middot;
                  <span class="grey--text caption text--darken-3">{{ formatDayspan(markt.date_start, markt.date_end) }}</span>
                  <!--  &middot; <span class="grey--text caption text--darken-3">{{ formatDays(daysUntilNext) }}</span>-->
                </div>
                <div v-if="!!markt.cancel_flag">
                  <v-alert :value="true" type="warning" outline class="d-inline-flex py-2">
                    <span class="grey--text text--darken-3">
                      <span class="font-weight-bold">Teilnahme abgesagt</span>: 
                      {{ markt.cancel_reason}}
                    </span>
                  </v-alert>
                </div>
              </v-card-text>
              <v-divider :class="{'mx-3': $vuetify.breakpoint.xsOnly}"></v-divider>
            </div>

          </template>
        </v-container>
        <v-card-actions v-if="lastModified" class="justify-center py-3">
          Stand: {{ lastModified }}
        </v-card-actions>
      </v-card>
      <v-card class="mt-3">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="title" id="newsletter">Informiert bleiben</h3>
          </v-card-title>
          <v-card-text class="subheading pt-0">          
            <p>Falls Sie eine Nachricht erhalten wollen, wenn es wieder los geht, können Sie sich zum <strong>Markt-Newsletter</strong> anmelden:</p>
            <form @submit.prevent="subscribe">
            <v-layout><v-flex lg6>
            <v-text-field
              v-model="subscribeEmail"
              type="email"
              required
              label="E-Mail"
              hide-details
              :disabled="subscription"
              box
            ></v-text-field>
            </v-flex></v-layout>
            <v-btn
              class="ml-0 mt-3 d-block"
              type="submit"              
              :loading="subscriptionLoading"
              :disabled="subscription">Eintragen</v-btn>
            </form>
            <v-alert v-if="subscriptionError" :value="true" outline type="error" class="mt-3">
              Fehler: {{ subscriptionError }}
            </v-alert>
            <v-alert v-else-if="subscription" :value="true" outline type="success" class="mt-3">
              <p class="font-weight-bold">Sie wurden erfolgreich im Newsletter eingetragen. Wir hoffen, dass wir Ihnen schon bald unsere neuen Termine mitteilen können.</p>
              <p class="mb-0">Informationen zum Abmelden aus dem Verteiler erhalten Sie in der Bestätigungsemail.</p>
            </v-alert>
          </v-card-text>
        </v-container>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  import marktHelperMixin from '@/components/mixins/markt-helper'

  import * as moment from 'moment'
  moment.locale('de')

  export default {
    mixins: [marktHelperMixin],
    data: () => ({
      maerkte: null,
      covid: true,
      subscribeEmail: null,
      subscription: false,
      subscriptionLoading: false,
      subscriptionError: null
    }),
    metaInfo: {
      title: 'Aktuelle Märkte',
      meta: [
        { name: 'description', content: 'Termine von Märkten und Veranstaltungen, auf denen das Marmelädchen mit einem Marktstand vertreten ist' }
      ]      
    },
    computed: {
      lastModified () {
        if (this.maerkte !== null && !!this.maerkte.length) {
          var lastModifiedSorted = this.maerkte.map(markt => markt.timestamp).filter(ts => !!ts).sort()
          return moment(lastModifiedSorted[lastModifiedSorted.length - 1]).format('LL')
        } else {
          return null
        }
      }
    },
    created () {
      this.queryMaerkte().then(maerkte => {
        this.maerkte = maerkte
      })
    },
    methods: {
      subscribe() {
        if (!this.subscriptionLoading) {
          this.subscriptionLoading = true
          this.subscribeNewsletter(this.subscribeEmail)
            .then(data => {
              this.subscriptionError = null;
              this.subscription = true;
            })
            .catch(err => {
              if (err.response?.data?.message) {
                this.subscriptionError = err.response.data.message
              } else {
                this.subscriptionError = err.response?.statusText || 'Unbekannter Fehler'
              }
              this.subscription = false;
            })
            .finally(() => {
              this.subscriptionLoading = false
            })
        }
      },
      ...mapActions('events', ['queryMaerkte', 'subscribeNewsletter'])
    }
  }
</script>
