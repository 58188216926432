import axios from 'axios'

import getLocalAuthState, * as storageUtils from '../ls'

const state = {
  accountEmail: '',
  sessionToken: '',
  loginLinkRequested: false,
  accountDeleted: false,
  ...getLocalAuthState()
}

const getters = {
  hasAccount: state => !!state.accountEmail,
  accountEmail: state => state.accountEmail,
  isAuthenticated: state => !!state.sessionToken,
  sessionToken: state => state.sessionToken,
  // Cannot reliably verify client-side. API authorizes
  isAdmin: (state, getters) => getters.isAuthenticated
    ? storageUtils.getSessionTokenRole(state.sessionToken) === 'admin'
    : false,
  isLoginLinkPending: (state, getters) => state.loginLinkRequested && !getters.isAuthenticated,
  accountDeleted: state => state.accountDeleted
}

const mutations = {
  sessionToken (state, sessionToken) {
    state.sessionToken = sessionToken
  },
  accountEmail (state, accountEmail) {
    state.accountEmail = accountEmail
  },
  loginLinkRequested (state, loginLinkRequested) {
    state.loginLinkRequested = loginLinkRequested
  },
  accountDeleted (state, accountDeleted) {
    state.accountDeleted = accountDeleted
  }
}

const actions = {
  probeEmail: ({ commit, dispatch, rootState }, { email }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/auth/login/probe`,
        data: { email },
        method: 'POST'
      }).then(resp => {
        commit('accountEmail', email)
        resolve(resp)
      }).catch(reject)
    })
  },
  register: ({ commit, dispatch, rootState }, { email, name }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/auth/register`,
        data: { email, name },
        method: 'POST'
      }).then(resp => {
        dispatch('updateCredentials', {
          sessionToken: resp.data.sessionToken,
          accountEmail: resp.data.email
        })
        commit('accountDeleted', false)
        resolve(resp)
      }).catch(reject)
    })
  },
  requestLoginLink: ({ commit, dispatch, rootState }, { email }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/auth/login/email/request`,
        data: { email },
        method: 'POST',
        // Supress snackbar error
        loginAttempt: true
      }).then(resp => {
        commit('loginLinkRequested', true)
        resolve(resp)
      }).catch(reject)
    })
  },
  loginWithAuthToken: ({ commit, dispatch, rootState }, { authToken }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/auth/login/email/verify`,
        data: { authToken },
        method: 'POST'
      }).then(resp => {
        dispatch('updateCredentials', {
          sessionToken: resp.data.sessionToken,
          accountEmail: resp.data.email
        })
        commit('loginLinkRequested', false)
        commit('accountDeleted', false)
        resolve(resp)
      }).catch(reject)
    })
  },
  loginWithPassword: ({ commit, dispatch, rootState }, { email, password }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${rootState.apiUrl}/auth/login/password`,
        data: { email, password },
        method: 'POST',
        // Supress snackbar error
        loginAttempt: true
      }).then(resp => {
        dispatch('updateCredentials', {
          sessionToken: resp.data.sessionToken,
          accountEmail: resp.data.email
        })
        commit('accountDeleted', false)
        resolve(resp)
      }).catch(reject)
    })
  },
  updateCredentials: ({ commit }, { sessionToken, accountEmail }) => {
    storageUtils.setLocalSessionToken(sessionToken)
    commit('sessionToken', sessionToken)
    commit('accountEmail', accountEmail)
  },
  resetSessionToken: ({ commit }) => {
    storageUtils.removeLocalSessionToken()
    commit('sessionToken', '')
  },
  resetAccount: ({ commit, dispatch }, { accountDeleted = false } = {}) => {
    dispatch('resetSessionToken')
    commit('accountEmail', '')
    commit('loginLinkRequested', false)
    if (accountDeleted) {
      commit('accountDeleted', true)
    }
    // Remove user details form user module
    dispatch('user/unloadUser', null, { root: true })
  },
  syncFromLocalStorage: ({ state, getters, commit, dispatch }) => {
    if (!getters.isAuthenticated) {
      const localSessionToken = storageUtils.getLocalSessionToken()
      if (localSessionToken && !storageUtils.isSessionTokenExpired(localSessionToken)) {
        const localSessionTokenEmail = storageUtils.getSessionTokenEmail(localSessionToken)
        commit('accountEmail', localSessionTokenEmail)
        commit('sessionToken', localSessionToken)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
