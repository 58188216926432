<template>
  <v-snackbar
    :value="value"
    @input="$emit('input', $event)"
    :color="color"
    :timeout="timeout" 
    top
    auto-height
  >
    <v-layout justify-center>
      <v-icon dark class="mr-2">{{ icon }}</v-icon>
      <div style="align-self: center">
        <slot></slot>
      </div>
    </v-layout>   
  </v-snackbar>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: 'priority_high'
      },
      color: {
        type: String,
        default: 'info'
      },
      timeout: {
        type: Number,
        default: 5000
      }
    }
  }
</script>
