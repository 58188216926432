<template>
  <!-- https://stackoverflow.com/questions/26372127/background-fixed-no-repeat-not-working-on-mobile -->
  <div
    style="display: block; position: fixed; left: 0; top: 0; width: 100%; height: 100vh"
    :style="backgroundStyle($vuetify.breakpoint.name)"></div>
<!--
  <v-container 
    fluid
    fill-height
    :style="backgroundStyle"
    class="pa-0">
    <slot></slot>
  </v-container>
-->
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('background', { backgroundStyle: 'currentStyle' })
    }
  }
</script>