<template>
  <v-data-table
    :id="compUid"
    ref="dTable"
    :items="cartItemsWrapper"
    :headers="headers"
    :hide-headers="$vuetify.breakpoint.xsOnly"
    expand
    hide-actions>
    <template v-if="headers" slot="headers" slot-scope="props">
      <tr style="height: auto">
        <th v-for="header in props.headers" :key="header.text"
          :class="{'text-xs-left': header.align === 'left', 'text-xs-right': header.align === 'right'}"
          class="px-1 py-1">{{ header.text }}</th>
      </tr>
    </template>                
    <template slot="items" slot-scope="props">
      <tr :class="`posId-${props.item.posId}`" @click.prevent="quantityDialogShow(props.item.posId)">
        <td v-if="$vuetify.breakpoint.mdAndUp" class="px-1 py-1">
          <v-avatar size="40">
            <v-img v-if="!!props.item.produkt.images.length" 
              :src="props.item.produkt.images[0].urls.thumb" contain></v-img>
            <v-img v-else :src="fallbackImageSrc"></v-img>                                                  
          </v-avatar>
        </td>
        <td class="px-1 py-1">
          <div class="font-weight-bold">
            <span v-if="$vuetify.breakpoint.xsOnly">{{ props.item.quantity }} x</span>
            {{ props.item.produkt.title }}
          </div>
          <slot name="subtitle" :item="props.item"></slot>
          <div v-if="$vuetify.breakpoint.smAndDown">
            <span v-if="$vuetify.breakpoint.xsOnly">{{ formatFuellmengePreis(props.item.sku.preis) }}</span>
            <span v-else> {{ formatFuellmenge(props.item.sku.preis) }}</span>
          </div>
          <div class="caption grey--text text--darken-1">Grundpreis: {{ formatGrundpreis(props.item.sku.preis, 100) }}</div>
          <div v-if="formatSkuVariants(props.item.sku)" 
            :class="{caption: $vuetify.breakpoint.smAndDown}"><i>{{ formatSkuVariants(props.item.sku) }}</i></div>
          <div v-if="props.item.notes"
            :class="{caption: $vuetify.breakpoint.smAndDown}" style="white-space: pre-wrap">{{ props.item.notes }}</div>
          <div v-else-if="props.item.sku.preis.lieferumfang && props.item.sku.preis.lieferumfang.length"
            :class="{caption: $vuetify.breakpoint.smAndDown}" style="white-space: pre-wrap">{{ props.item.sku.preis.lieferumfang.join("\n") }}</div>            
          <v-menu
            v-if="!disabled"
            :value="quantityDialogId === props.item.posId"
            @input="quantityDialogId = $event ? props.item.posId : null"
            :activator="`#${compUid} .posId-${props.item.posId}`"
            :close-on-content-click="false"
            max-width="500px">
            <v-card>
              <v-list dense>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      {{ props.item.produkt.title }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title>{{ quantityChange ? `${quantityChange} Stk.` : 'entfernt' }} </v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action class="pl-3">
                    <v-layout fill-height align-center justify-end>
                      <v-icon 
                        @click.prevent="quantityChange = Math.max(0, quantityChange - 1)"
                        medium :color="quantityChange ? 'secondary' : 'grey lighten-3'">indeterminate_check_box</v-icon>
                      <v-icon 
                        @click.prevent="quantityChange = quantityChange + 1"              
                        medium color="primary">add_box</v-icon>                
                    </v-layout>                
                  </v-list-tile-action>
                </v-list-tile>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions class="justify-end py-2">
                <v-btn
                  @click.native="quantityDialogId = null"
                  small flat color="primary">Aktualisieren</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </td>
        <td v-if="$vuetify.breakpoint.mdAndUp" class="px-1 py-1 text-xs-center">
          {{ formatFuellmenge(props.item.sku.preis) }}
        </td>
        <td v-if="$vuetify.breakpoint.smAndUp" class="px-1 py-1 text-xs-center">
          {{ props.item.quantity }} Stk.
        </td>
        <td v-if="$vuetify.breakpoint.smAndUp" class="px-1 py-1 text-xs-right">
          {{ formatPreisInt(props.item.sku.preis.preis) }}
        </td>
        <td class="px-1 pl-1 py-1 text-xs-right">
          {{ formatPreisInt(props.item.betrag) }}
        </td> 
      </tr>
    </template>
    <template slot="footer" v-if="!!cartTotal.count">
      <tr style="font-size: 13px">
        <td :colspan="headers ? headers.length-1 : 1"
          class="px-1 py-1" :class="{'text-xs-right': $vuetify.breakpoint.smAndUp}">
          <slot name="shipping">
            <div>Versandkosten</div>
            <div class="caption grey--text text--darken-1">innerhalb Deutschlands</div>
          </slot>
        </td>
        <td class="px-1 py-1 text-xs-right">{{ formatPreisInt(cartShippingCost) }}</td>
      </tr>
      <tr style="font-size: 13px">
        <td :colspan="headers ? headers.length-1 : 1"
          class="px-1 py-1 font-weight-bold"
          :class="{'text-xs-right': $vuetify.breakpoint.smAndUp}">
          <slot name="sum">
            <div>Summe</div>
            <div class="caption grey--text text--darken-1">Gemäß § 19 UStG wird keine Umsatzsteuer berechnet</div>
          </slot>
        </td>
        <td class="px-1 py-1 text-xs-right font-weight-bold">{{ formatPreisInt(cartTotal.sum + cartShippingCost)}}</td>
      </tr>
    </template>
    <!--
    <template v-if="!disabled" slot="expand" slot-scope="props">
      <v-card flat>
        <v-card-text class="pa-1">
          <v-textarea
            :value="props.item.notes"
            @input="updateNotes(props.item, $event)"
            label="Notizen"
            placeholder="Besondere Wünsche"
            :rows="1"
            auto-grow
            hide-details
            class="body-1"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </template>
    -->
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import produktHelperMixin from '@/components/mixins/produkt-helper'

let compUid = 0

export default {
  mixins: [produktHelperMixin],
  props: ['disabled'],
  data: () => {
    compUid += 1
    return {
      compUid: `ml-cart-${compUid}`,
      quantityDialogId: null,
      quantityChange: 0,
      fallbackImageSrc: require('@/assets/jar.png')
    }
  },
  watch: {
    quantityDialogId (val, oldVal) {
      if (!this.disabled) {
        if (oldVal) {
          const item = this.cartItemsWrapper.find(item => item.posId === oldVal)
          // const cartItemsConfirmed = this.cartItemsConfirmed
          this.$nextTick(() => {
            this.cartChangeItemQuantity({ ...item, quantity: this.quantityChange })
          })
          // Keep confirmed once we pass step 1
          // this.setCartItemsConfirmed(cartItemsConfirmed)
        }
        if (val) {
          const item = this.cartItemsWrapper.find(item => item.posId === val)
          this.quantityChange = item.quantity
        }
      }
    }
  },
  methods: {
    quantityDialogShow (itemId) {
      if (!this.disabled) {
        this.quantityDialogId = itemId
      }
    },
    ...mapActions('cart', ['cartChangeItemQuantity'])
  },
  /*
  mounted () {
    for (let i = 0; i < this.cartItemsWrapper.length && !this.disabled; i += 1) {
      const item = this.cartItemsWrapper[i]
      if (item.sku.preis.produkttyp === 'geschenk') {
        this.$set(this.$refs.dTable.expanded, item.id, true)
      }
    }
  },
  */
  computed: {
    cartItemsWrapper () {
      return this.cartItems.map((item, index) => ({
        ...item,
        betrag: item.quantity * item.sku.preis.preis
      }))
    },
    headers () {
      if (this.$vuetify.breakpoint.smAndUp) {
        var headers = [
          { text: 'Produkt', value: 'produkt.title', align: 'left' },
          { text: 'Anzahl', value: 'quantity' },
          { text: 'Preis', value: 'sku.preis.preis', align: 'right' },
          { text: 'Betrag', value: 'endpreis', align: 'right' }
        ]
        if (this.$vuetify.breakpoint.mdAndUp) {
          headers.splice(0, 0, { text: '' })
          headers.splice(2, 0, { text: 'Größe', value: 'sku.preis.fuellMenge' })
        }
        return headers.map(header => ({ ...header, sortable: false }))
      }
    },
    ...mapGetters('cart', ['cartItems', 'cartTotal', 'cartShippingCost'])
  }
}
</script>