export default {
  namespaced: true,
  state: {
    selection: 'default',
    styles: {
      'default': {
        style: {
          backgroundImage: `url('/img/backgrounds/IMG_1297.JPG')`,
          backgroundColor: 'rgba(255,255,255,0.1)',
          backgroundBlendMode: 'overlay',
          backgroundRepeat: 'no-repeat',
          // backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center center'
        },
        responsive: {
          xs: {
            backgroundImage: `url('/img/backgrounds/IMG_1297_xs.JPG')`
          }
        }
      }
    }
  },
  getters: {
    currentStyle: (state) => breakpoint => {
      var bg = state.styles[state.selection]
      if (bg) {
        if (breakpoint && bg.responsive && bg.responsive[breakpoint]) {
          return { ...bg.style, ...bg.responsive[breakpoint] }
        }
        return bg.style
      }
      return {}
    },
    currentSelection: (state) => state.selection,
    allOptions: (state) => Object.keys(state.styles)
  },
  mutations: {
    changeBackground (state, name) {
      state.selection = state.styles[name] ? name : state.selection
    },
    unsetBackground (state) {
      state.selection = null
    },
    resetBackground (state) {
      state.selection = 'default'
    },
    addBackground (state, { name, style }) {
      // Replace object: Reactivity
      state.styles = { ...state.styles, [name]: { style } }
    }
  }
}
