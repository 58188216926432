<!--
  Images:
  Home component: v-img (height)
  ProductsList component: v-responsive + vue-lazyload, v-img
  AdminProduct: v-img(fixed height)
  ProductDetails: v-img (fixed height + contain)
-->
<template>
  <v-container>
    <v-layout ref="scrollContainerRef" column>
      <v-card class="flex" style="position: relative">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
          <v-card-title primary-title>
            <h3 class="headline">Katalog</h3>
          </v-card-title>
          <v-card-text class="pt-0">
            <div class="subheading">Alle Produkte auf einen Blick.</div>
            <v-text-field
              v-model="produkteSearch"              
              label="Suche"
              prepend-inner-icon="search"
              single-line
              :persistent-hint="!!produkteSearch"
              :hint="produkteSearch ? trefferHint : ''"
              clearable>
            </v-text-field>
            <v-checkbox
              v-model="checkboxValue"
              :indeterminate="checkboxIndeterminate"
              hide-details
              @click.prevent.stop="toggleProduktgruppenAll" class="mb-0">
              <template slot="label">
                <!-- Workaround because prevent does not work on label -->                
                <span @click.stop="toggleProduktgruppenAll">Alle Produktgruppen</span>
              </template>
            </v-checkbox>
            <div v-show="!produktgruppenAll">
              <v-chip 
                v-for="produktgruppe in produktgruppen" :key="produktgruppe.value"
                @click.prevent="toggleProduktgruppe(produktgruppe)" small outline
                text-color="grey darken-3"
                style="cursor: pointer;">
                <v-avatar>
                  <v-icon color="primary"
                    v-if="produktgruppenSelected.includes(produktgruppe.value)">check_box</v-icon>
                  <v-icon v-else>check_box_outline_blank</v-icon>
                </v-avatar>
                {{ produktgruppe.text }}
              </v-chip>
            </div>
          </v-card-text>
        </v-container>
        <v-progress-linear
          :active="produkteKatalog === null"
          style="position: absolute; right: 0; left: 0; bottom: 0;"
          class="mb-0" color="accent"
          :indeterminate="true"></v-progress-linear>
      </v-card>
      <div v-infinite-scroll="loadMore" infinite-scroll-disabled="isLoading" infinite-scroll-distance="200">
      <v-container 
        v-for="produktgruppe in produktgruppen.map(produktgruppe => produktgruppe.value)" :key="produktgruppe"
        v-if="produktgruppenSelected.includes(produktgruppe) && produkteKatalogFilteredMaxResults[produktgruppe].length"
        fluid grid-list-md class="pa-0">
        <div class="py-3 text-xs-center">
          <v-chip color="secondary">
            <v-subheader class="black--text">
              {{ produkttypFriendly(produktgruppe) }}
            </v-subheader>
          </v-chip>
        </div>
        <v-layout row wrap>
          <v-flex xs6 sm4 md3
            v-for="produkt in produkteKatalogFilteredMaxResults[produktgruppe]"
            :key="produkt._id">
            <v-card :to="produkt.slug" append class="fill-height"
              :title="produkt.title" :aria-label="produkt.title">              
              <v-card-text class="pa-2">
                <template v-if="produkt.images.length">
                  <v-responsive :aspect-ratio="0.75" class="v-image">
                    <div
                      v-lazy:background-image="{src: produkt.images[0].urls.small, loading: produkt.images[0].urls.thumb}"
                      class="v-image__image v-image__image--contain"
                      :class="{'produkt-na': produkt.skus.every(sku => !sku.inventory)}">
                    </div>
                    <div
                      v-if="produkt.skus.every(sku => !sku.inventory)"
                      class="white--text text-xs-center caption font-weight-bold"
                      style="position: absolute; left: 0; bottom: 0; width: 100%">aktuell nicht lieferbar</div>                    
                  </v-responsive>
                </template>
                <template v-else>
                  <v-img 
                    src="/img/IMG_2254-1534429470-mini.JPG"
                    :aspect-ratio="0.75"
                    :class="{'produkt-na': produkt.skus.every(sku => !sku.inventory)}">
                    <!-- Absolute positioning relative to padding box of relative parent -->
                    <v-card
                      class="mx-2"
                      style="background-color: rgba(255,255,255,0.8); position: absolute; left: 0; right: 0; top: 50%; transform: translate(0px, -50%)">
                      <v-card-text
                        class="subheading text-xs-center"
                        style="overflow-wrap: break-word">
                        {{ produkt.title }}
                      </v-card-text>
                    </v-card>
                    <div
                      v-if="produkt.skus.every(sku => !sku.inventory)"
                      class="white--text text-xs-center caption font-weight-bold"
                      style="position: absolute; left: 0; bottom: 0; width: 100%">aktuell nicht lieferbar</div>
                  </v-img>       
                </template>
                <v-avatar
                  v-if="produkteKatalogFiltered[produktgruppe].length > 10 && produkt.showTitleLetter"
                  size="28"
                  style="position: absolute; top: 0; left: 0; transform: translate(-25%, -25%)"
                  class="white"><span class="font-weight-bold">{{ produkt.titleLetter }}</span></v-avatar>
              </v-card-text>
            </v-card>
          </v-flex>            
        </v-layout>
      </v-container>      
      </div>
      <v-container
        v-if="produkteKatalogFilteredMaxResultsCount < produkteKatalogFilteredCount" 
        fluid grid-list-md class="pa-0">
        <v-layout row style="overflow: hidden">
          <v-flex 
            v-for="placeholder in [0,1,2,3]" :key="placeholder"
            xs6 sm4 md3
            style="flex-shrink: 0">
            <v-card class="fill-height">              
              <v-card-text class="pa-2">
                <v-responsive :aspect-ratio="0.75">
                  <v-layout align-center justify-center fill-height>
                    <v-progress-circular color="grey darken-3" size="50" indeterminate></v-progress-circular>
                  </v-layout>
                </v-responsive>
              </v-card-text>
            </v-card>
          </v-flex>            
        </v-layout>
      </v-container>
      <v-btn fab fixed bottom right @click="scrollTop">
        <v-icon large>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    // keep-alive ref
    name: 'ProductsList',
    created () {
      // Load or reload produkteKatalog
      this.loadProdukte()
    },
    data: () => ({
      pageSize: 48,
      pageSizeInitial: 48
    }),
    metaInfo: {
      title: 'Produktkatalog'
    },
    computed: {
      isLoading () {
        return this.produkteKatalog === null
      },
      produktgruppenAll: {
        get () {
          return this.$store.getters['products/katalogProduktgruppenAll']
        },
        set (produktgruppenAll) {
          this.$store.commit('products/updateKatalogProduktgruppenAll', produktgruppenAll)
        }
      },
      produkteSearch: {
        get () {
          return this.$store.getters['products/katalogProdukteSearch']
        },
        set (produkteSearch) {
          this.$store.commit('products/updateKatalogProdukteSearch', produkteSearch)
        }
      },
      produktgruppenSelectedUser: {
        get () {
          return this.$store.getters['products/katalogProduktgruppenSelectedUser']
        },
        set (produktgruppenSelectedUser) {
          this.$store.commit('products/updateKatalogProduktgruppenSelectedUser', produktgruppenSelectedUser)
        }
      },
      produktgruppenSelected () {
        return this.produktgruppenAll || !this.produktgruppenSelectedUser // Empty array is valid (truthy)
          ? this.produktgruppen.map(produktgruppe => produktgruppe.value)
          : this.produktgruppenSelectedUser
      },
      maxResults: {
        get () {
          const katalogMaxResults = this.$store.getters['products/katalogMaxResults']
          return katalogMaxResults !== null ? katalogMaxResults : this.pageSizeInitial
        },
        set (maxResults) {
          this.$store.commit('products/updateKatalogMaxResults', maxResults)
        }
      },
      checkboxIndeterminate () {
        return !this.produktgruppenAll && !!this.produktgruppenSelected.length
      },
      checkboxValue () {
        return this.produktgruppenSelected.length === this.produktgruppen.length
      },
      // Contains unselected Produktgruppen. Filter in View
      produkteKatalogFiltered () {
        const emptyKalalog = this.produktgruppen.reduce((katalog, produktgruppe) => ({
          ...katalog,
          [produktgruppe.value]: []
        }), {})

        if (!this.produkteKatalog) {
          return emptyKalalog
        }

        const terms = this.produkteSearch
          ? this.produkteSearch.trim().toLowerCase().split(/[\s,]+/)
          : []

        return this.produkteKatalog.reduce((katalog, produkt) => {
          // Filter
          for (let term of terms) {
            if (!produkt.title.toLowerCase().includes(term)) {
              if (!produkt.titleAlt.toLowerCase().includes(term)) {
                // Skip this produkt
                return katalog
              }
            }
          }

          // produkt.produkttyp is produkttyp.value
          const produktgruppe = this.produktgruppenLookup[produkt.produkttyp]
          const katalogProduktgruppe = katalog[produktgruppe]

          const titleLetter = produkt.titleAlt.charAt(0).toUpperCase()
          const showTitleLetter = !katalogProduktgruppe.length ||
            katalogProduktgruppe[katalogProduktgruppe.length - 1].titleLetter !== titleLetter

          katalogProduktgruppe.push({
            ...produkt,
            titleLetter,
            showTitleLetter
          })

          return katalog
        }, emptyKalalog)
      },
      produkteKatalogFilteredCount () {
        return Object.values(this.produkteKatalogFiltered).reduce((count, katalogProduktgruppe) => {
          return count + katalogProduktgruppe.length
        }, 0)
      },
      // Contains unselected Produktgruppen. Filter in View
      produkteKatalogFilteredMaxResults () {
        const produkteKatalogFilteredMaxResults = Object.assign({}, this.produkteKatalogFiltered)
        var itemCount = 0
        for (let { value: produktgruppe } of this.produktgruppen) {
          if (this.produktgruppenSelected.includes(produktgruppe)) {
            const maxResultsLeft = this.maxResults - itemCount
            produkteKatalogFilteredMaxResults[produktgruppe] = produkteKatalogFilteredMaxResults[produktgruppe].slice(0, maxResultsLeft)
            itemCount += produkteKatalogFilteredMaxResults[produktgruppe].length
          }
        }
        return produkteKatalogFilteredMaxResults
      },
      produkteKatalogFilteredMaxResultsCount () {
        return Object.values(this.produkteKatalogFilteredMaxResults).reduce((count, katalogProduktgruppe) => {
          return count + katalogProduktgruppe.length
        }, 0)
      },
      trefferHint () {
        const matches = this.produktgruppenSelected.reduce((matches, produktgruppe) => {
          const produktgruppeProdukteCount = this.produkteKatalogFiltered[produktgruppe].length
          if (produktgruppeProdukteCount) {
            matches.produkteCount += produktgruppeProdukteCount
            matches.produktgruppen.push(produktgruppe)
          }
          return matches
        }, { produkteCount: 0, produktgruppen: [] })
        if (matches.produkteCount) {
          const treffer = `${matches.produkteCount} Treffer`
          if (matches.produktgruppen.length > 1) {
            return `${treffer} in ${matches.produktgruppen.length} Produktgruppen`
          } else if (matches.produktgruppen.length === 1) {
            return `${treffer} in ${this.produkttypFriendly(matches.produktgruppen[0])}`
          } else {
            return treffer
          }
        } else {
          return 'Keine Treffer'
        }
      },
      // Produktgruppe is subset of Produkttypen (objects)
      produktgruppen () {
        return this.produkttypen.filter(produkttyp => !produkttyp.group)
      },
      // Produkttyp => Produktgruppe (values)
      produktgruppenLookup () {
        return this.produkttypen.reduce((lookup, produkttyp) => ({
          ...lookup,
          [produkttyp.value]: produkttyp.group ? produkttyp.group : produkttyp.value
        }), {})
      },
      ...mapGetters('products', ['produkttypen', 'produkttypFriendly', 'produkteKatalog'])
    },
    watch: {
      produktgruppenSelected () {
        this.resetPageSize()
      },
      produkteSearch (val, oldVal) {
        if (val === null || oldVal === null || val.length < oldVal.length) {
          this.resetPageSize()
        }
      }
    },
    methods: {
      toggleProduktgruppe (produktgruppe) {
        if (this.produktgruppenSelected.includes(produktgruppe.value)) {
          this.produktgruppenSelectedUser = this.produktgruppenSelected.filter(value => value !== produktgruppe.value)
        } else {
          const produktgruppenValues = this.produktgruppen.map(produktgruppe => produktgruppe.value)
          this.produktgruppenSelectedUser = [...this.produktgruppenSelected, produktgruppe.value].sort((pa1, pa2) => {
            return produktgruppenValues.indexOf(pa1) - produktgruppenValues.indexOf(pa2)
          })
        }
      },
      toggleProduktgruppenAll () {
        this.produktgruppenAll = !this.produktgruppenAll
      },
      loadMore () {
        this.maxResults = this.maxResults + this.pageSize
      },
      resetPageSize () {
        this.maxResults = this.pageSizeInitial
      },
      scrollTop () {
        window.scrollTo(0, 0)
      },
      ...mapActions('products', ['loadProdukte'])
    }
  }
</script>

<style scoped>
  .produkt-na {
    filter: grayscale(100%);    
  }
</style>