<template>
  <v-container>
    <v-layout justify-center>
    <v-flex xs12 sm10 md8>      
      <v-card>
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
        <v-card-title primary-title>
          <h3 class="title">Kontaktformular</h3>
        </v-card-title>
        <v-card-text class="pt-0">
          <div class="subheading">Schreiben Sie uns eine Nachricht oder melden Sie sich <router-link to="/kontakt/telefon">telefonisch</router-link>.</div>
          <v-alert
            :value="messageSent"
            type="success" color="primary" outline
            transition="fade-transition"
            class="mt-3">
            <div>Vielen Dank! Wir freuen uns über Ihren Beitrag.</div>
          </v-alert>
          <v-form ref="formRef" v-model="formValid" lazy-validation @submit.prevent="submitForm" class="mt-3">
            <v-text-field
              v-model.trim="name"
              :disabled="loading"
              :rules="[v => !!v || 'Kein Name angegeben']"
              label="Ihr Name"
              name="name"
              autocomplete="name"></v-text-field>
            <v-text-field
              v-if="isAuthenticated"
              :value="accountEmail"
              :disabled="loading"
              append-icon="verified_user"
              readonly
              label="Ihre E-Mail"
              hint="Sie können Ihre E-Mail in den Kontoeinstellungen ändern"></v-text-field>
            <v-text-field
              v-else
              v-model.trim="email"
              :disabled="loading"
              :rules="[
                v => !!v || 'Keine E-Mail angegeben',
                v => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-Mail ungültig'
              ]"
              validate-on-blur
              label="Ihre E-Mail"
              type="email"
              name="email"
              autocomplete="email"></v-text-field>
            <v-textarea
              ref="formMessageRef"
              v-model.trim="message"
              :disabled="loading"
              label="Nachricht"
              auto-grow
              box
              hide-details
              class="mt-3"></v-textarea>
            <v-checkbox
              v-if="isAuthenticated"
              v-model="sendCopy"
              :disabled="loading"              
              label="Kopie an mich"
              hide-details></v-checkbox>
            <v-checkbox 
              v-else
              :input-value="false"
              :disabled="true"
              hide-details>
              <template slot="label">
                <span>Kopie an mich<br><small>nur möglich mit registrierter E-Mail</small></span>
              </template>
            </v-checkbox>
            <v-layout justify-end>
              <v-btn :loading="loading && !!message" type="submit">Absenden</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
        </v-container>
      </v-card>
    </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'

  export default {
    data: () => ({
      // lazy-validation: If enabled, value will always be true
      // unless there are visible validation errors.
      // You can still call validate() to manually trigger validation
      formValid: true,
      loading: false,
      messageSent: false
    }),
    metaInfo: {
      title: 'Kontaktformular'
    },    
    computed: {
      email: {
        get () { return this.formEmail },
        set (email) { this.updateFormEmail(email) }
      },
      name: {
        get () { return this.formName },
        set (name) { this.updateFormName(name) }
      },
      message: {
        get () { return this.formMessage },
        set (message) { this.updateFormMessage(message) }
      },
      sendCopy: {
        get () { return this.formSendCopy },
        set (sendCopy) { this.updateFormSendCopy(sendCopy) }
      },
      ...mapGetters('auth', ['isAuthenticated', 'accountEmail']),
      ...mapGetters('user', { userProfile: 'profile' }),
      ...mapGetters('contact', ['formEmail', 'formName', 'formMessage', 'formSendCopy'])
    },
    watch: {
      message () {
        this.messageSent = false
      }
    },
    methods: {
      submitForm () {
        if (this.$refs.formRef.validate() && !this.loading) {
          this.loading = true
          this.sendMessage({
            email: this.isAuthenticated ? this.accountEmail : this.email,
            name: this.name,
            message: this.message,
            sendCopy: this.isAuthenticated ? this.sendCopy : false,
            verified: this.isAuthenticated
          }).then(() => {
            if (this.isAuthenticated) {
              // If logged in leave email and name
              this.$refs.formMessageRef.reset()
            } else {
              this.$refs.formRef.reset()
            }
            this.$nextTick(() => {
              this.messageSent = true
            })
          }).finally(() => {
            this.loading = false
          })
        }
      },
      ...mapMutations('contact', ['updateFormEmail', 'updateFormName', 'updateFormMessage', 'updateFormSendCopy']),
      ...mapActions('contact', ['sendMessage']),
      ...mapActions('user', ['loadUser'])
    },
    created () {
      this.loading = true
      // Load user (cached)
      this.loadUser().then(loaded => {
        // Authenticated and profile data loaded from server
        if (loaded) {
          // Do not override form data (Vuex session storage)
          if (!this.name) {
            this.name = this.userProfile.name
          }
          if (!this.email) {
            // Still available when logged out (!isAuthenticated)
            // userProfile.email === accountEmail
            this.email = this.userProfile.email
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
</script>