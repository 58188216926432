<template>
  <v-container fill-height>
    <v-layout fill-height>
      <v-card flat class="flex">
        <v-container class="py-0" :class="{'pa-0': $vuetify.breakpoint.xsOnly}">                    
          <v-card-title primary-title class="px-4">
            <h3 class="headline">Bestellung</h3>
          </v-card-title>
          <v-card-text class="py-0 px-4">
            <v-fade-transition mode="out-in">
              <div v-if="!!order" class="subheading" key="done">
                <p>Ihre Bestellung ist im Marmelädchen eingegangen.
                  <span class="ml-font-face-emoji">&#x1F44F;</span>
                </p>
                <p>Wir prüfen jetzt, ob alle Produkte lieferbar sind und melden uns in Kürze erneut bei Ihnen!</p>
                <p><v-btn small :to="`/bestellungen/${order.bestellungNr}`">Zur Bestellung</v-btn></p>
              </div>
              <div v-else class="subheading" key="cart">
                Nur noch wenige Schritte bis zur Lieferung...
                <span class="ml-font-face-emoji">&#x1F60B;</span>
                <!--<v-icon size="20" color="grey darken-3" class="ml-2">far fa-smile-beam</v-icon>-->
              </div>
            </v-fade-transition>
          </v-card-text>
          <v-slide-y-transition>
            <v-stepper v-if="!order" v-model="step" vertical class="elevation-0">
              <v-stepper-step
                :step="STEP_CART"
                :editable="stepperEditable[STEP_CART]"
                :complete="stepperCompleted[STEP_CART] && step > STEP_CART"
                :rules="[() => stepperCompleted[STEP_CART] || step <= STEP_CART]"                
                :edit-icon="$vuetify.icons.complete">
                Warenkorb
                <small v-if="stepperCompleted[STEP_CART] && step > STEP_CART">
                  {{ cartTotal.count }} Artikel &ndash; {{ formatPreisInt(cartTotal.sum + cartShippingCost) }}
                </small>
              </v-stepper-step>
              <v-stepper-content :step="STEP_CART">
                <ml-bestellung-cart></ml-bestellung-cart>
                <v-layout justify-end class="mt-3">
                  <v-btn small @click.native="nextStep">Weiter</v-btn>
                </v-layout>
              </v-stepper-content>
              <v-stepper-step
                :step="STEP_LOGIN"
                :editable="stepperEditable[STEP_LOGIN]"
                :complete="stepperCompleted[STEP_LOGIN]"
                :rules="[() => stepperCompleted[STEP_LOGIN] || step <= STEP_LOGIN]"
                :edit-icon="$vuetify.icons.complete">
                Anmelden
                <small v-if="stepperCompleted[STEP_LOGIN] && step > STEP_LOGIN">{{ accountEmail }}</small>
              </v-stepper-step>
              <v-stepper-content :step="STEP_LOGIN">
                <template v-if="isAuthenticated">
                  <div>Sie sind angemeldet als <span class="font-weight-bold">{{ accountEmail }}</span></div>
                  <v-layout justify-end class="mt-3">
                    <v-btn small @click.native="step = step - 1">Zurück</v-btn>                    
                    <v-btn small @click.native="nextStep">Weiter</v-btn>
                  </v-layout>                
                </template>
                <template v-else>
                  <div>Um fortzufahren, bitte anmelden oder neues Konto erstellen:</div>
                  <v-layout class="pt-2" wrap>
                    <v-btn small flat color="primary" :to="{ path: '/anmelden', query: { redirect: '/bestellung' } }">Anmelden</v-btn>
                    <v-btn small flat color="primary" :to="{ path: '/anmelden', query: { redirect: '/bestellung', registrieren: 1 } }">Registrieren</v-btn>
                  </v-layout>                
                  <div style="font-size: 13px" class="grey--text text--darken-1 pt-2">
                    Keine Werbemails! Wir verarbeiten Ihre Daten nach den gesetzlichen Bestimmungen der Datenschutzgrundverordnung (DSGVO).
                  </div>
                </template>
              </v-stepper-content>
              <v-stepper-step
                :step="STEP_ADDRESS"        
                :editable="stepperEditable[STEP_ADDRESS]"
                :complete="stepperCompleted[STEP_ADDRESS] && step > STEP_ADDRESS"
                :rules="[() => stepperCompleted[STEP_ADDRESS] || step <= STEP_ADDRESS]"
                :edit-icon="$vuetify.icons.complete">
                Rechnungs- und Lieferadresse
                <small v-if="stepperCompleted[STEP_ADDRESS] && step > STEP_ADDRESS">
                  Lieferung an {{ addressShippingName }}
                </small>
              </v-stepper-step>
              <v-stepper-content :step="STEP_ADDRESS">
                <v-layout wrap style="margin: -8px">
                  <v-flex xs12 md6 class="pa-2">
                    <v-textarea
                      @click.prevent="dialogAddressInvoice = true"
                      :value="formatAddress(addressInvoice)"                      
                      hint="In das Feld klicken, um Adresse zu bearbeiten"
                      persistent-hint
                      :error="addressInvoiceError"
                      :error-messages="addressInvoiceError ? 'In das Feld klicken, um Adresse zu bearbeiten' : ''"
                      auto-grow :rows="1"
                      class="ml-address body-2">
                      <template slot="label">
                        <v-fade-transition mode="out-in">
                          <span v-if="addressShippingDifferent" key="rechnung">Rechnungsadresse</span>
                          <span v-else key="adresse">Adresse</span>
                        </v-fade-transition>
                      </template>
                      </v-textarea>
                  </v-flex>
                  <component :is="$vuetify.breakpoint.mdAndUp ? 'v-slide-x-transition' : 'v-slide-y-transition'">
                  <v-flex v-if="addressShippingDifferent" xs12 md6 class="pa-2">                      
                    <v-textarea
                      @click.prevent="dialogAddressShipping = true"                  
                      :value="formatAddress(addressShipping)"
                      label="Lieferadresse"
                      hint="In das Feld klicken, um Adresse zu bearbeiten"
                      persistent-hint
                      :error="addressShippingError"
                      :error-messages="addressShippingError ? 'In das Feld klicken, um Adresse zu bearbeiten' : ''"
                      auto-grow :rows="1"
                      class="ml-address body-2"></v-textarea>
                  </v-flex>
                  </component>
                </v-layout>
                <v-checkbox
                  v-model="addressShippingDifferent"
                  @change="addressShippingError = false"             
                  hide-details>
                  <template slot="label"><span class="body-2">Lieferadresse abweichend</span></template>
                </v-checkbox>
                <v-layout justify-end class="mt-3">
                  <v-btn small @click.native="step = step - 1">Zurück</v-btn>                  
                  <v-btn small @click.native="nextStep">Weiter</v-btn>
                </v-layout>                  
                <ml-dialog-address
                  v-model="dialogAddressInvoice"
                  :address="addressInvoice || addressInvoiceDefault"
                  :return-object.sync="addressInvoice"
                  @save="addressInvoiceError = false; dialogAddressInvoice = false"
                  :label="addressShippingDifferent ?  'Rechnungsadresse' : 'Adresse'"
                  saveText="Übernehmen"></ml-dialog-address>
                <ml-dialog-address
                  v-model="dialogAddressShipping"
                  :address="addressShipping"
                  :return-object.sync="addressShipping"
                  @save="addressShippingError = false; dialogAddressShipping = false"
                  @delete="addressShipping = null; addressShippingDifferent = false; dialogAddressShipping = false"
                  :deletable="!!addressShipping"
                  label="Lieferadresse"
                  saveText="Übernehmen"></ml-dialog-address>
              </v-stepper-content>
              <v-stepper-step 
                :step="STEP_PAYMENT"
                :editable="stepperEditable[STEP_PAYMENT]"
                :complete="stepperCompleted[STEP_PAYMENT] && step > STEP_PAYMENT"
                :rules="[() => stepperCompleted[STEP_PAYMENT] || step <= STEP_PAYMENT]"
                :edit-icon="$vuetify.icons.complete">
                Zahlweise
                <small v-if="stepperCompleted[STEP_PAYMENT] && step > STEP_PAYMENT">
                  {{ paymentMethodFriendly(paymentMethod) }}
                </small>
              </v-stepper-step>
              <v-stepper-content :step="STEP_PAYMENT">
                <v-radio-group v-model="paymentMethod" hide-details class="mt-0" style="margin-bottom: -20px !important;">
                  <v-radio v-for="method in paymentMethods" :key="method.value" :value="method.value">
                    <template slot="label"><span class="body-2">{{ method.text }}</span></template>
                  </v-radio>
                </v-radio-group>
                <v-layout justify-end class="mt-3">
                  <v-btn small @click.native="step = step - 1">Zurück</v-btn>                                    
                  <v-btn small @click.native="nextStep">Weiter</v-btn>
                </v-layout>
              </v-stepper-content>
              <v-stepper-step
                :step="STEP_ORDER"
                :editable="stepperEditable[STEP_ORDER]"
                :complete="stepperCompleted[STEP_ORDER] && !!order"
                :edit-icon="$vuetify.icons.complete">
                Bestellung abschließen
              </v-stepper-step>
              <v-stepper-content :step="STEP_ORDER">
                <v-subheader class="pa-0">Warenkorb</v-subheader>
                <ml-bestellung-cart :disabled="true">
                  <template slot="subtitle" slot-scope="props">
                  </template>
                  <template v-if="addressShippingOutsideGermany" slot="shipping">
                    <div>Versandkosten</div>
                    <div class="caption error--text text--darken-1">
                      abweichend für {{ addressShippingOutsideGermany }} &ndash; bitte Angebot abwarten
                    </div>
                  </template>
                </ml-bestellung-cart>
                <v-layout wrap>
                  <v-flex xs12 md6 :class="{'pr-2': $vuetify.breakpoint.mdAndUp}">
                    <v-subheader class="pa-0">{{addressShippingDifferent ? 'Rechnungsadresse' : 'Rechnungs- und Lieferadresse' }}</v-subheader>
                    <address style="white-space: pre-wrap">{{ formatAddress(addressInvoice) }}</address>
                  </v-flex>
                  <v-flex v-if="addressShippingDifferent" xs12 md6 :class="{'pr-2': $vuetify.breakpoint.mdAndUp}">
                    <v-subheader class="pa-0">Lieferadresse</v-subheader>
                    <address style="white-space: pre-wrap">{{ formatAddress(addressShipping) }}</address>
                  </v-flex>
                </v-layout>
                <v-subheader class="pa-0">Zahlweise</v-subheader>
                <v-layout align-center>
                  <v-icon class="mr-2">radio_button_checked</v-icon>
                  {{ paymentMethodFriendly(paymentMethod) }}
                </v-layout>
                <v-subheader class="pa-0">Wichtige Hinweise</v-subheader>                
                <p>Mit der Bestellung akzeptieren Sie unsere <a href="#" @click.prevent="showAGB = true">Allgemeinen Geschäftsbedingungen</a>
                <small><v-icon size="18">save_alt</v-icon><a href="/AGB.pdf" download>PDF</a></small>.
                Beachten Sie auch unsere <a href="#" @click.prevent="showPrivacyPolicy = true">Datenschutzerklärung</a>.
                Das Angebot ist <a href="#" @click.prevent="showAngebotFreibleibend = true">freibleibend</a>.</p>
                <v-divider></v-divider>
                <v-layout class="mt-3">
                  <v-flex xs12>
                    <v-textarea
                      v-model="message"
                      :disabled="loading"
                      label="Nachricht (optional)"
                      auto-grow
                      :rows="3"
                      box
                      hide-details>
                    </v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout justify-end class="mt-3">
                  <v-btn small @click.native="step = step - 1">Zurück</v-btn>                  
                  <v-btn small color="primary" :loading="loading" @click.native="submitOrder">
                    {{ $vuetify.breakpoint.smAndUp ? 'Bestellung abschicken' : 'Bestellen' }}
                  </v-btn>
                </v-layout>                                   
              </v-stepper-content>
            </v-stepper>
          </v-slide-y-transition>
        </v-container>        
      </v-card>
    </v-layout>
    <v-dialog v-model="showAngebotFreibleibend" lazy scrollable max-width="500">
      <v-card>
        <v-card-title>
          <h3>Angebot freibleibend</h3>
        </v-card-title>
        <v-card-text class="py-0">
          <p>Wir versuchen unser Sortiment so gut es geht im Internet abzubilden. Leider kann es aber vorkommen, dass einzelne Produkte in Ihrem Warenkorb kurzfristig nicht lieferbar sind.</p>
          <p>In diesem Fall informieren wir Sie vor Abschluss der Bestellung und Sie erhalten die Möglichkeit aus verschiedenen Alternativen zu wählen oder ggf. Ihre Bestellung wieder zu stornieren</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat small @click.native="showAngebotFreibleibend = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
    <v-dialog v-model="showAGB" lazy max-width="500" content-class="ml-agb-dialog">
      <v-card class="flex d-flex" style="flex-direction: column">
        <v-card-title>
          <h3>Allgemeine Geschäftsbedingungen</h3>
        </v-card-title>
        <v-card-text class="py-0 fill-height">
          <object data="/AGB.pdf" type="application/pdf" width="100%" height="100%">
            <p>This browser does not support PDFs. Please download the PDF to view it: <a href="/AGB.pdf" download>Download PDF</a>.</p>
          </object>
        </v-card-text>
        <v-card-actions class="shrink" style="flex-grow: 0 !important;">
          <v-spacer></v-spacer>
          <v-btn flat small @click.native="showAGB = false">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>        
    <v-dialog v-model="showPrivacyPolicy" lazy scrollable max-width="500">
      <v-card>
        <v-card-title primary-title>
          <h3>Datenschutzerklärung</h3>
        </v-card-title>        
        <v-card-text
          class="pt-0" style="word-wrap: break-word"
          v-html="privacyPolicy"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat small @click.native="showPrivacyPolicy = false">Zurück</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import store from '@/store'

import DialogAddress from '@/components/special/DialogAddress'
import BestellungWarenkorb from '@/components/BestellungWarenkorb'

import produktHelperMixin from '@/components/mixins/produkt-helper'
import userHelperMixin from '@/components/mixins/user-helper'

export default {
  mixins: [produktHelperMixin, userHelperMixin],
  data: () => ({
    STEP_CART: 1,
    STEP_LOGIN: 2,
    STEP_ADDRESS: 3,
    STEP_PAYMENT: 4,
    STEP_ORDER: 5,
    // Status
    order: null,
    loading: false,
    addressInvoiceError: false,
    addressShippingError: false,
    addressShippingDifferent: false,
    // Dialogs STEP_ADDRESS
    dialogAddressInvoice: false,
    dialogAddressShipping: false,
    // Dialogs STEP_ORDER
    showAngebotFreibleibend: false,
    showAGB: false,
    showPrivacyPolicy: false
  }),
  metaInfo: {
    title: 'Bestellung aufgeben'
  },
  watch: {
    isAuthenticated (isAuthenticated, wasAuthenticated) {
      if (!isAuthenticated && wasAuthenticated && this.step > this.STEP_LOGIN) {
        this.step = this.STEP_LOGIN
      }
    },
    step (step, oldStep) {
      // Prefill default payment method
      if (step === this.STEP_PAYMENT && !this.paymentMethod) {
        this.paymentMethod = this.paymentMethods[0].value || 'ueberweisung'
      }
      // Prefill address from profile
      if (step === this.STEP_ADDRESS) {
        if (!this.addressInvoice && this.userProfile.address.invoice) {
          this.addressInvoice = { ...this.userProfile.address.invoice }
          if (!this.addressShipping && this.userProfile.address.shipping) {
            this.addressShipping = { ...this.userProfile.address.shipping }
          }
        }
        this.addressShippingDifferent = this.addressShipping !== null
      }
      // Remove addressShipping if addressShippingDifferent is unchecked
      if (oldStep <= this.STEP_ADDRESS && step > this.STEP_ADDRESS && !this.addressShippingDifferent) {
        this.addressShipping = null
      }
    }
  },
  methods: {
    nextStep () {
      if (this.step === this.STEP_ADDRESS) {
        this.addressShippingError = this.addressShippingDifferent && !this.addressShipping
        this.addressInvoiceError = !this.addressInvoice
      }
      if (this.stepperCompleted[this.step]) {
        this.step = this.step + 1
      }
    },
    submitOrder () {
      if (!this.loading) {
        this.loading = true
        this.sendOrder().then(order => {
          this.order = order
        }).finally(() => {
          this.loading = false
        })
      }
    },
    ...mapMutations('cart', [
      'setOrderAddressInvoice',
      'setOrderAddressShipping',
      'setOrderPaymentMethod',
      'setOrderMessage',
      'setOrderStep'
    ]),
    ...mapActions('user', ['loadUser']),
    ...mapActions('cart', ['sendOrder'])
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('user/loadUser').then(() => {
      // Attempt to load user (fails silently if not logged in)
      next()
    }).catch(() => {
      next(false)
    })
  },
  computed: {
    stepperCompleted () {
      return {
        [this.STEP_CART]: true, // Mindestbestellwert
        [this.STEP_LOGIN]: this.isAuthenticated,
        [this.STEP_ADDRESS]: !!this.addressInvoice && (!this.addressShippingDifferent || !!this.addressShipping),
        [this.STEP_PAYMENT]: !!this.paymentMethod,
        [this.STEP_ORDER]: true // AGBs accepted
      }
    },
    stepperEditable () {
      const editableLogin = this.stepperCompleted[this.STEP_CART]
      const editableAddress = editableLogin && this.stepperCompleted[this.STEP_LOGIN]
      const editablePayment = editableAddress && this.stepperCompleted[this.STEP_ADDRESS]
      const editableOrder = editablePayment && this.stepperCompleted[this.STEP_PAYMENT]
      return {
        [this.STEP_CART]: true,
        [this.STEP_LOGIN]: editableLogin,
        [this.STEP_ADDRESS]: editableAddress,
        [this.STEP_PAYMENT]: editablePayment,
        [this.STEP_ORDER]: editableOrder
      }
    },
    addressInvoiceDefault () {
      return {
        fullname: this.userProfile.name,
        country: this.addressCountries[0] || 'Deutschland'
      }
    },
    addressShippingOutsideGermany () {
      const address = this.addressShipping ? this.addressShipping : this.addressInvoice
      if (address) {
        return address.country !== 'Deutschland' ? address.country : false
      } else {
        return false
      }
    },
    addressShippingName () {
      const address = this.addressShipping ? this.addressShipping : this.addressInvoice
      return address
        ? address.fullname
        : null
    },
    addressInvoice: {
      get () {
        return this.orderAddressInvoice
      },
      set (address) {
        this.setOrderAddressInvoice(address)
      }
    },
    addressShipping: {
      get () {
        return this.orderAddressShipping
      },
      set (address) {
        this.setOrderAddressShipping(address)
      }
    },
    paymentMethod: {
      get () {
        return this.orderPaymentMethod
      },
      set (method) {
        this.setOrderPaymentMethod(method)
      }
    },
    message: {
      get () {
        return this.orderMessage
      },
      set (message) {
        this.setOrderMessage(message)
      }
    },
    step: {
      get () {
        return this.orderStep
      },
      set (step) {
        this.setOrderStep(step)
      }
    },
    ...mapGetters('cart', ['cartTotal', 'cartShippingCost']),
    ...mapGetters('cart', [
      'orderAddressInvoice',
      'orderAddressShipping',
      'orderPaymentMethod',
      'orderMessage',
      'orderStep'
    ]),
    ...mapGetters('orders', ['paymentMethods', 'paymentMethodFriendly']),
    ...mapGetters('auth', ['isAuthenticated', 'accountEmail']),
    ...mapGetters('user', {
      userProfile: 'profile',
      addressCountries: 'addressCountries'
    }),
    ...mapGetters('legal', ['privacyPolicy'])
  },
  components: {
    'ml-bestellung-cart': BestellungWarenkorb,
    'ml-dialog-address': DialogAddress
  }
}
</script>

<style>
  .ml-agb-dialog {
    display: flex;
    min-height: 90%;
  }
</style>